import React, { useContext, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { ContextMenu } from '../../components/ContextMenu/ContextMenu';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { MultiHeaderTab } from '../../components/MultiHeaderTab/MultiHeaderTab';
import ProjectInformation from '../../components/ProjectInformation/ProjectInformation';
import Table from '../../components/Table/Table';
import {
  useGetHistoryForCSVQuery,
  useGetHistoryForProjectQuery,
} from '../../redux/api/history.endpoints';
import {
  useCreateNoteMutation,
  useGetProjectNotesQuery,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from '../../redux/api/note.endpoints';
import {
  useDeleteProjectMutation,
  useGetProjectByIdQuery,
} from '../../redux/api/project.endpoints';
import {
  uploadToastData,
  deleteToastData as deleteDocumentToastData,
  uploadImageToastData,
  deleteImageToastData,
} from '../../types/Documents';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { Note } from '../../types/Note';
import { USER_ROLES } from '../../types/User';
import { handleEndpointResult } from '../../utils/api';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { formatStringForCSV, HISTORY_CSV_METADATA } from '../../utils/csv';
import { ENTITY_NAME } from '../../utils/enums';
import { getFormattedDate } from '../../utils/table';
import ProjectDocuments from './ProjectDocuments';
import {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetAllDocumentsByProjectIdQuery,
} from '../../redux/api/document.endpoints';
import ProjectImages from './ProjectImages';
import { toast } from 'react-toastify';
import { UploadingModal } from '../../components/Modals/UploadingModal/UploadingModal';
import {
  isAdminOrSuperAdmin,
  isPMOrSPM,
  isSuperAdmin,
} from '../../auth/userUtils';
import { ProjectExpenses } from './ProjectExpenses';

const ProjectDetails = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [modalAction, setModalAction] = useState('Activate');
  const [modalEntity, setModalEntity] = useState(ENTITY_NAME.PROJECT);
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [activeTab, setActiveTab] = useState('Details');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('DESC');
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [deleteProject, deleteProjectResult] = useDeleteProjectMutation();
  const [deleteProjectNote, deleteProjectNoteResult] = useDeleteNoteMutation();
  const [selectedNote, setSelectedNote] = useState<Note | undefined>(undefined);
  const [createNote, createNoteResult] = useCreateNoteMutation();
  const [updateNote, updateNoteResult] = useUpdateNoteMutation();
  const [editingNote, setEditingNote] = useState(false);

  const [fileType, setFileType] = useState('');

  const [uploadDocument, uploadDocumentResult] = useCreateDocumentMutation();
  const [deleteDocuments, deleteDocumentResult] = useDeleteDocumentMutation();
  const [errorDocMessage, setErrorDocMessage] = useState('');

  const projectId = searchParams.get('id') || '';
  const {
    projectData,
    isSuccess,
    isError,
    refetch: refetchProjectData,
  } = useGetProjectByIdQuery(projectId, {
    selectFromResult: ({ data, isSuccess, isError }) => ({
      projectData: data ? data : undefined,
      isSuccess,
      isError,
    }),
    skip: !projectId,
  });

  const { projectNotes, refetch: refetchNotes } = useGetProjectNotesQuery(
    parseInt(projectId),
    {
      selectFromResult: ({ data }) => ({
        projectNotes: data ? data : [],
      }),
      skip: !projectId,
    },
  );

  const {
    documentsData,
    imagesData,
    documentsCount,
    refetch: refetchDocuments,
    isFetching: isFetchingDocuments,
  } = useGetAllDocumentsByProjectIdQuery(
    { id: searchParams.get('id') || '', pageNumber },
    {
      selectFromResult: ({ data, isFetching }) => ({
        documentsData: data?.documents || [],
        imagesData: data?.images || [],
        documentsCount: data?.documentsCount || 0,
        isFetching,
      }),
    },
  );

  const {
    projectHistory,
    total: totalEntries,
    refetch: refetchHistory,
    isFetching: isFetchingHistory,
  } = useGetHistoryForProjectQuery(
    {
      projectId,
      sortBy,
      sortType,
      pageSize,
      pageNumber,
      search,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        projectHistory: data?.history || [],
        total: data?.total || 0,
        isFetching,
      }),
      skip: activeTab !== 'History' || !projectId,
    },
  );

  useEffect(() => {
    switch (activeTab) {
      case 'History':
        setPageNumber(1);
        setPageSize(10);
        refetchHistory();
        refetchHistoryCSV();
        break;
      case 'Details':
        refetchProjectData();
        refetchNotes();
        break;
      case 'Documents':
        setPageNumber(1);
        setPageSize(10);
        refetchDocuments();
        break;
      case 'Images':
        refetchDocuments();
        break;
      case 'Expenses':
        //
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    setPageNumber(1);
  }, [pageSize]);

  useEffect(() => {
    if (activeTab === 'Documents') {
      refetchDocuments();
    }
  }, [pageNumber]);

  useEffect(() => {
    handleEndpointResult(
      fileType === 'document' ? uploadToastData : uploadImageToastData,
      uploadDocumentResult,
      handleCloseUploadModal,
    );
  }, [uploadDocumentResult]);

  useEffect(() => {
    handleEndpointResult(
      fileType === 'document' ? deleteDocumentToastData : deleteImageToastData,
      deleteDocumentResult,
    );
  }, [deleteDocumentResult]);

  useEffect(() => {
    if (errorDocMessage) {
      toast.error(errorDocMessage);
      setErrorDocMessage('');
    }
  }, [errorDocMessage]);

  const { CSVData, refetch: refetchHistoryCSV } = useGetHistoryForCSVQuery(
    projectId,
    {
      selectFromResult: ({ data }) => ({
        CSVData: data?.history || [],
      }),
    },
  );

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  useEffect(() => {
    if ((isSuccess || isError) && !projectData) {
      navigate(`/${isPMOrSPM(authProfile) ? 'pm' : 'admin'}/projects`);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (
      authProfile &&
      projectData &&
      !isAdminOrSuperAdmin(authProfile) &&
      authProfile.id !== projectData.project_manager.id &&
      authProfile?.id !== projectData.project_manager.seniorProjectManager?.id
    )
      navigate('/pm/projects');
  }, [projectData, authProfile]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
    }
  }, [auth]);

  const handleOpenModal = (
    action: string,
    selectedNote: Note | undefined,
    entityName: string,
  ) => {
    setModalAction(action);
    setModalEntity(entityName);
    entityName === ENTITY_NAME.NOTE && setSelectedNote(selectedNote);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleDeleteProject = () =>
    projectData &&
    deleteProject({
      id: projectData.id,
      creatorId: authProfile?.id || 0,
    });

  const handleDeleteProjectNote = () => {
    selectedNote &&
      deleteProjectNote({
        id: selectedNote.id,
        editorId: authProfile?.id || 0,
        projectId: parseInt(projectId),
      });
    setSelectedNote(undefined);
    handleCloseModal();
  };

  useEffect(() => {
    if (
      authProfile &&
      authProfile.role !== USER_ROLES.ADMIN &&
      authProfile.role !== USER_ROLES.SUPER_ADMIN &&
      authProfile.role !== USER_ROLES.SENIOR_PROJECT_MANAGER &&
      authProfile.role !== USER_ROLES.PROJECT_MANAGER
    ) {
      navigate('/');
    }
  }, [authProfile]);

  useEffect(() => {
    handleEndpointResult(
      {
        toastMsg: 'Project deleted successfully.',
        toastId: 'delete-project-success',
        toastErrorMsg: 'An error occurred while deleting the project.',
        toastErrorId: 'delete-project-error',
      },
      deleteProjectResult,
      () => navigate('/admin/projects'),
    );
  }, [deleteProjectResult]);

  useEffect(() => {
    handleEndpointResult(
      {
        toastMsg: 'Project Note deleted successfully.',
        toastId: 'delete-project-note-success',
        toastErrorMsg: 'An error occurred while deleting the project note.',
        toastErrorId: 'delete-project-note-error',
      },
      deleteProjectNoteResult,
    );
  }, [deleteProjectNoteResult]);

  const handleAddNote = (note: string) => {
    createNote({
      text: note,
      userId: authProfile?.id || 0,
      projectId: parseInt(projectId),
    });
  };

  const handleEditNote = (noteId: number, text: string) => {
    updateNote({
      id: noteId,
      projectId: parseInt(projectId),
      editorId: authProfile?.id || 0,
      text,
    });
  };

  const handleDeleteDocument = (documentIds: number[]) => {
    deleteDocuments({ documentIds });
  };

  useEffect(() => {
    handleEndpointResult(
      {
        toastMsg: 'Note added successfully!',
        toastId: 'add-note-success',
        toastErrorMsg: 'An error occurred while adding the note.',
        toastErrorId: 'add-note-error',
      },
      createNoteResult,
    );
  }, [createNoteResult]);

  useEffect(() => {
    handleEndpointResult(
      {
        toastMsg: 'Note updated successfully!',
        toastId: 'update-note-success',
        toastErrorMsg: 'An error occurred while updating the note.',
        toastErrorId: 'update-note-error',
      },
      updateNoteResult,
    );
  }, [updateNoteResult]);

  const getHeaderText = () =>
    modalEntity === ENTITY_NAME.PROJECT && projectData
      ? `${modalAction} Project: ${projectData.id}.`
      : 'Delete Project Note';

  const getSecondaryText = () =>
    modalEntity === ENTITY_NAME.PROJECT && projectData
      ? `Are you sure you want to ${modalAction.toLowerCase()} Project ${
          projectData.id
        }?`
      : 'Are you sure you want to delete this project note?';

  const handleSearch = (query: string) => {
    setPageNumber(1);
    setSearch(query);
  };

  const handleSort = (category: string) => {
    setSortBy((prev) => {
      if (prev === category) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return category;
      }
      setSortType('ASC');
      return category;
    });
  };

  const getDataForCSV = () => {
    if (CSVData) {
      const formattedCSVData = CSVData.map((data) => ({
        ...data,
        action: formatStringForCSV(data.action),
        date: getFormattedDate(data.date),
      }));
      return { data: formattedCSVData, ...HISTORY_CSV_METADATA };
    }
    return { data: [], headers: [], filename: 'null.csv' };
  };

  const columns = [
    {
      title: 'Date',
      attribute: 'date',
      sortValue: 'created_at',
      maxWidth: '4rem',
      minWidth: '4rem',
    },
    {
      title: 'Operator',
      attribute: 'user',
      sortValue: 'user',
      maxWidth: '15rem',
      mixWidth: '15rem',
    },
    {
      title: 'Action',
      attribute: 'action',
      sortValue: 'action',
      maxWidth: '30rem',
      minWidth: '10rem',
      hasTooltip: true,
    },
  ];

  const transformDataForTable = () => {
    return projectHistory.map((entry) => ({
      ...entry,
      user: entry.user ? entry.user : 'Deleted User',
      date: getFormattedDate(entry.date),
    }));
  };

  const renderActionButtons = () => {
    if (!projectData || !authProfile) return <></>;
    const canModify =
      activeTab === 'Details' &&
      (authProfile.role === USER_ROLES.SUPER_ADMIN ||
        authProfile.role === USER_ROLES.ADMIN);
    return (
      <div className="d-flex row flex-grow-slightly header-action-buttons justify-content-end">
        {canModify && (
          <>
            <button
              className="btn btn-primary col-4 col-md-5 ms-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
              onClick={() =>
                handleOpenModal('Delete', undefined, ENTITY_NAME.PROJECT)
              }
            >
              <i className="icon-Delete font-24" /> {isMobile ? '' : 'Delete'}
            </button>
          </>
        )}
        {canModify && (
          <button
            className={`btn btn-primary col-4 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex align-items-center justify-content-center ${
              false ? '' : 'ms-2'
            } px-1`}
            onClick={() => {
              localStorage.setItem(
                'renolink-return-to',
                `${location.pathname}${location.search}`,
              );
              navigate(`/admin/projects/edit?id=${projectData?.id || 0}`);
            }}
          >
            <i className="icon-Edit font-24" /> {isMobile ? '' : 'Edit'}
          </button>
        )}
        {canModify && <div className="col-1" />}
      </div>
    );
  };

  const setNoteForEdit = (note: Note) => {
    setSelectedNote(note);
    setEditingNote(true);
  };

  const userCanAddFiles = () => {
    if (
      (isPMOrSPM(authProfile) &&
        projectData?.project_manager.id === authProfile?.id) ||
      isAdminOrSuperAdmin(authProfile)
    )
      return true;
    return false;
  };

  const renderContextMenu = (noteId: number, userId: number) => {
    const contextMenuProps = {
      entityData: projectNotes,
      entityName: ENTITY_NAME.NOTE,
      currentId: noteId,
      authProfile,
      handleOpenModal,
      setNoteForEdit,
      raised: true,
    };
    return userId === authProfile?.id || isSuperAdmin(authProfile!) || 0 ? (
      <ContextMenu {...contextMenuProps} />
    ) : (
      <></>
    );
  };

  return (
    <>
      <Helmet>
        <title>Renolink - Project Details</title>
      </Helmet>
      <MultiHeaderTab
        tabs={['Details', 'Documents', 'Images', 'History', 'Expenses']}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        renderActionButtons={renderActionButtons}
      />

      {activeTab === 'Details' && (
        <div
          className={`my-3 rounded bg-white-and-shadowed d-flex scrollable ${
            isTablet ? 'py-0 px-0 w-100 flex-fill' : 'p-2'
          } h-100`}
        >
          <div
            className={`container mt-3 ${
              isTablet ? 'px-0 col-12 mx-0' : 'px-1 col-12 col-md-9'
            }`}
          >
            <main className="w-100 h-100 ">
              <ProjectInformation
                projectData={projectData}
                addNote={handleAddNote}
                editNote={handleEditNote}
                projectNotes={projectNotes}
                renderContextMenu={renderContextMenu}
                selectedNote={selectedNote}
                setSelectedNote={setSelectedNote}
                editingNote={editingNote}
                setEditingNote={setEditingNote}
                authUser={authProfile}
              />
            </main>
          </div>
        </div>
      )}
      {activeTab === 'Documents' && (
        <ProjectDocuments
          uploadDocument={uploadDocument}
          deleteDocuments={handleDeleteDocument}
          files={documentsData}
          totalFiles={documentsCount}
          setFileType={setFileType}
          setErrorDocMessage={setErrorDocMessage}
          handleOpenUploadModal={handleOpenUploadModal}
          activePage={pageNumber}
          setActivePage={setPageNumber}
          isFetching={isFetchingDocuments}
          canAddFile={userCanAddFiles()}
        />
      )}
      {activeTab === 'Images' && (
        <ProjectImages
          uploadDocument={uploadDocument}
          deleteDocuments={handleDeleteDocument}
          files={imagesData}
          setFileType={setFileType}
          setErrorDocMessage={setErrorDocMessage}
          handleOpenUploadModal={handleOpenUploadModal}
          canAddFile={userCanAddFiles()}
        />
      )}
      {activeTab === 'History' && (
        <Table
          tableName="Actions"
          tabs={[]}
          columns={columns}
          data={transformDataForTable()}
          totalData={totalEntries}
          isFetchingData={isFetchingHistory}
          mainActionButton={<></>}
          sortFunction={handleSort}
          sortBy={sortBy}
          sortType={sortType}
          itemsPerPage={pageSize}
          setItemsPerPage={setPageSize}
          activePage={pageNumber}
          setActivePage={setPageNumber}
          renderContextMenu={() => <></>}
          handleSearch={handleSearch}
          csvData={getDataForCSV()}
          fullSize
          readOnly
        />
      )}
      {activeTab === 'Expenses' && (
        <ProjectExpenses
          projectId={projectId}
          isActive={activeTab === 'Expenses'}
          setActiveTab={setActiveTab}
        />
      )}
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={
          modalEntity === ENTITY_NAME.PROJECT
            ? handleDeleteProject
            : handleDeleteProjectNote
        }
      />
      <UploadingModal
        isOpen={openUploadModal}
        closeModal={handleCloseUploadModal}
        headerText={
          fileType === 'document' ? 'Uploading Document' : 'Uploading Image'
        }
        bodyText={
          fileType === 'document'
            ? 'Your document size must be less than 30MB'
            : 'Your image size must be less than 30MB'
        }
      />
    </>
  );
};

export default ProjectDetails;
