/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  headerText: string;
  secondaryText: string;
  primaryAction: any;
  secondaryAction: any;
  disableActionButton?: boolean;
}

export const ActionConfirmationModal = ({
  isOpen,
  headerText,
  secondaryText,
  closeModal,
  primaryAction,
  secondaryAction,
  disableActionButton = false,
}: Props) => {
  const [primaryButtonText, setPrimaryButtonText] = useState('No');
  const [secondaryButtonText, setSecondaryButtonText] = useState('Yes');

  useEffect(() => {
    if (
      headerText.includes('Project Complete') ||
      headerText.includes('Change Project Status')
    ) {
      setPrimaryButtonText('Cancel');
      setSecondaryButtonText('Confirm');
    }
  }, [headerText]);

  return (
    <div className="d-flex justify-content-center">
      <Modal
        style={{
          content: {
            background: 'rgba(255, 255, 255, 0.65)',
            border: 'none',
            padding: 'none',
            position: 'absolute',
            display: 'block',
            margin: '0',
            transform: 'translate(-50%, -30%)',
            width: '90%',
            maxWidth: '660px',
            inset: 'unset',
            overflow: 'initial',
            borderRadius: '14.1562px',
          },
        }}
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <div className="modal-content" style={{ borderRadius: '14.1562px' }}>
          <div className="modal-header d-flex justify-content-center py-4 border-0">
            <div className="modal-title px-3">
              <div className="row text-center">
                <i className="icon-Alert-Icon big-icon text-danger" />
              </div>
              <div className="row text-center">
                <h4
                  className="modal-title"
                  aria-label="confirmation-modal-header-text"
                >
                  {headerText}
                </h4>
              </div>
              <div className="row text-center">
                <p aria-label="confirmation-modal-secondary-text">
                  {secondaryText}
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-0 pb-4">
            <div className="col-4 col-md-2 d-flex justify-content-center">
              <button
                disabled={disableActionButton}
                aria-label="confirmation-modal-primary-button"
                className="btn btn-primary btn-block py-1 lazuli-light-blue-border font-weight-bold"
                onClick={primaryAction}
              >
                {primaryButtonText}
              </button>
            </div>
            <div className="col-4 col-md-2 d-flex justify-content-center">
              <button
                disabled={disableActionButton}
                className="btn btn-primary btn-block py-2 bg-lazuli-blue"
                aria-label="confirmation-modal-secondary-button"
                onClick={secondaryAction}
              >
                <span>{secondaryButtonText}</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
