export const USERS_CSV_METADATA = {
  headers: [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'formattedRole',
      label: 'Role',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'phone',
      label: 'Phone',
    },
    {
      key: 'company',
      label: 'Company',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'seniorProjectManager',
      label: 'Senior Project Manager',
    },
  ],
  filename: 'Users.csv',
};

export const PROJECTS_CSV_METADATA = {
  headers: [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'customer',
      label: 'Customer',
    },
    {
      key: 'address',
      label: 'Address',
    },
    {
      key: 'market',
      label: 'Market',
    },
    {
      key: 'project_manager',
      label: 'Project Manager',
    },
    {
      key: 'order_date',
      label: 'Order Date',
    },
    {
      key: 'start_date',
      label: 'Start Date',
    },
    {
      key: 'due_date',
      label: 'Due Date',
    },
    {
      key: 'completed_at',
      label: 'Date Completed',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'contract_amount',
      label: 'Contract Amount',
    },
    {
      key: 'days_under_contract',
      label: 'Days Open / Days Allotted',
    },
    {
      key: 'progress',
      label: 'Progress',
    },
    {
      key: 'invoice_number',
      label: 'Invoice Number',
    },
    {
      key: 'po_number',
      label: 'PO Number',
    },
  ],
  filename: 'Projects.csv',
};

export const MARKETS_CSV_METADATA = {
  headers: [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'city',
      label: 'City',
    },
    {
      key: 'state',
      label: 'State',
    },
    {
      key: 'project_managers',
      label: 'Project Managers',
    },
    {
      key: 'open_projects',
      label: 'Open',
    },
    {
      key: 'open_amount',
      label: 'Open Amount',
    },
    {
      key: 'past_due',
      label: 'Past Due',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ],
  filename: 'Markets.csv',
};

export const HISTORY_CSV_METADATA = {
  headers: [
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'user',
      label: 'Operator',
    },
    {
      key: 'action',
      label: 'Action',
    },
  ],
  filename: 'History.csv',
};

export const CUSTOMER_CSV_METADATA = {
  headers: [
    {
      key: 'company_name',
      label: 'Company Name',
    },
    {
      key: 'primary_address',
      label: 'Address 1',
    },
    {
      key: 'secondary_address',
      label: 'Address 2',
    },
    {
      key: 'city',
      label: 'City',
    },
    {
      key: 'state',
      label: 'State',
    },
    {
      key: 'zip',
      label: 'Zip',
    },
    {
      key: 'email',
      label: 'Main Email',
    },
    {
      key: 'phone',
      label: 'Main Phone',
    },
    {
      key: 'openProjects',
      label: 'Open',
    },
    {
      key: 'openAmount',
      label: 'Open Amount',
    },
    {
      key: 'pastDueProjects',
      label: 'Past Due',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ],
  filename: 'Customers.csv',
};

export const SUBCONTRACTORS_CSV_METADATA = {
  headers: [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'company',
      label: 'Company Name',
    },
    {
      key: 'market',
      label: 'Primary Market',
    },
    {
      key: 'w9',
      label: 'W9',
    },
    {
      key: 'gl_insurance_expiration',
      label: 'GL Insurance Exp. Date',
    },
    {
      key: 'wc_insurance_expiration',
      label: 'WC Insurance Exp. Date',
    },
    {
      key: 'signed_agreement',
      label: 'Signed Agreement',
    },
    {
      key: 'trades',
      label: 'Trades',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ],
  filename: 'Subcontractors.csv',
};

export const formatStringForCSV = (value: string) => {
  return value.replace(/,/g, '').replace(/"/g, "'").trim();
};
