import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import {
  useGetAllMarketsForTableQuery,
  useGetMarketsCountQuery,
  useUpdateMarketActiveStatusMutation,
  useGetMarketsForCSVQuery,
} from '../../redux/api/market.endpoints';
import { USER_ROLES } from '../../types/User';
import { useNavigate } from 'react-router';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { toast } from 'react-toastify';
import { MARKETS_CSV_METADATA } from '../../utils/csv';
import { AddNewButton } from '../../components/Buttons/AddNewButton/AddNewButton';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { isMobileOnly } from 'react-device-detect';
import { Market, MarketForTable } from '../../types/Markets';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { formatAsMoney } from '../../utils/table';
import { ContextMenu } from '../../components/ContextMenu/ContextMenu';
import { ENTITY_NAME } from '../../utils/enums';

export const MarketsTable = () => {
  const Table = lazy(() => import('../../components/Table/Table'));
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState('active');
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('market.name');
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC');
  const [selectedMarket, setSelectedMarket] = useState<Market | undefined>(
    undefined,
  );
  const [modalAction, setModalAction] = useState('Activate');
  const [searchQuery, setSearchQuery] = useState('');
  const { marketsData, refetch, getAllMarketsQueryError, isFetching } =
    useGetAllMarketsForTableQuery(
      {
        pageSize: itemsPerPage,
        pageNumber: activePage,
        sortBy,
        sortType,
        tab: activeTab,
        searchQuery,
      },
      {
        selectFromResult: ({ data, error, isFetching }) => ({
          marketsData: data ? data : [],
          getAllMarketsQueryError: error,
          isFetching,
        }),
      },
    );

  const { data: marketsCount, refetch: refetchMarketsCount } =
    useGetMarketsCountQuery({ search: searchQuery });
  const { data: CSVData } = useGetMarketsForCSVQuery();
  const [updateMarketActiveStatus, updateMarketActiveStatusResult] =
    useUpdateMarketActiveStatusMutation();

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  useEffect(() => {
    refetch();
    refetchMarketsCount();
  }, [sortBy, sortType, activePage]);

  useEffect(() => {
    if (
      authProfile &&
      authProfile.role !== USER_ROLES.ADMIN &&
      authProfile.role !== USER_ROLES.SUPER_ADMIN
    ) {
      navigate('/');
    }
  }, [authProfile]);

  useEffect(() => {
    setActivePage(1);
  }, [itemsPerPage, activeTab, searchQuery]);

  useEffect(() => {
    if (typeof getAllMarketsQueryError !== 'undefined') {
      toast.error('An error occurred when searching markets.', {
        toastId: 'get-all-markets-query-error',
      });
    }
  }, [getAllMarketsQueryError]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    if (
      updateMarketActiveStatusResult &&
      updateMarketActiveStatusResult.isSuccess
    ) {
      const data: { message: string } = updateMarketActiveStatusResult.data;
      toast.success(`Market ${data.message} successfully.`, {
        toastId: 'update-market-status-success',
      });
      setOpenModal(false);
    } else if (
      updateMarketActiveStatusResult &&
      updateMarketActiveStatusResult.isError
    ) {
      const errorResult =
        updateMarketActiveStatusResult.error as FetchBaseQueryError;
      const data = errorResult.data as unknown as { error: string };
      toast.error(
        data && data.error
          ? data.error
          : "An error occurred while changing the market's active status.",
        { toastId: 'update-market-status-error' },
      );
    }
  }, [updateMarketActiveStatusResult]);

  const sortMarkets = (category: string) => {
    setSortBy((prev) => {
      if (prev === category) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return category;
      }
      setSortType('ASC');
      return category;
    });
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const getDataForCSV = () => {
    if (CSVData) {
      const dataForCSV = CSVData.map((market) => {
        return {
          ...market,
          open_amount: `$${market.open_amount}` || '$0',
          status: market.is_active ? 'Active' : 'Inactive',
        };
      });
      return { data: dataForCSV, ...MARKETS_CSV_METADATA };
    }
    return { data: [], headers: [], filename: 'null.csv' };
  };

  const tabs = [
    {
      title: `Active (${marketsCount?.active || 0})`,
      attribute: 'active',
    },
    {
      title: `Inactive (${marketsCount?.inactive || 0})`,
      attribute: 'inactive',
    },
    {
      title: `All (${marketsCount?.total || 0})`,
      attribute: 'all',
    },
  ];

  const columns = [
    { title: 'ID', attribute: 'id', sortValue: 'market.id' },
    {
      title: 'Name',
      attribute: 'name',
      sortValue: 'market.name',
      maxWidth: '10rem',
    },
    {
      title: 'City',
      attribute: 'city',
      sortValue: 'market.city',
      maxWidth: '10rem',
    },
    {
      title: 'State',
      attribute: 'state',
      sortValue: 'market.state',
      maxWidth: '10rem',
    },
    {
      title: 'Project Managers',
      attribute: 'project_managers',
      textCenter: true,
    },
    { title: 'Open', attribute: 'open_projects', textCenter: true },
    { title: 'Open Amount', attribute: 'open_amount', textCenter: true },
    { title: 'Past Due', attribute: 'past_due', textCenter: true },
  ];

  if (activeTab === 'all')
    columns.push({
      title: 'Status',
      attribute: 'is_active',
      sortValue: 'market.is_active',
    });

  const transformDataForTable = () =>
    marketsData.map(
      (market: MarketForTable) =>
        ({
          id: market.id.toString(),
          name: market.name || '',
          city: market.city || '',
          state: market.state || '',
          project_managers: market.project_managers || 0,
          open_projects: market.open_projects || 0,
          open_amount: formatAsMoney(parseInt(market.open_amount)) || '$0.00',
          past_due: market.past_due || 0,
          is_active: market.is_active ? 'Active' : 'Inactive',
        } as unknown as { [key: string]: string }),
    );

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (action: string, market: Market) => {
    setSelectedMarket(market);
    setModalAction(action);
    setOpenModal(true);
  };

  const handleUpdateActiveStatus = () =>
    selectedMarket && updateMarketActiveStatus(selectedMarket.id);

  const getHeaderText = () =>
    selectedMarket ? `${modalAction} Market: ${selectedMarket.name}.` : '';

  const getSecondaryText = () =>
    selectedMarket
      ? `Are you sure you want to ${modalAction.toLowerCase()} Market ${
          selectedMarket.name
        }?`
      : '';

  const renderContextMenu = (marketId: number) => {
    const contextMenuProps = {
      entityData: marketsData,
      entityName: ENTITY_NAME.MARKET,
      currentId: marketId,
      authProfile,
      handleOpenModal,
    };
    return <ContextMenu {...contextMenuProps} />;
  };

  const getTotalCount = () => {
    switch (activeTab) {
      case 'active':
        return marketsCount?.active || 0;
      case 'inactive':
        return marketsCount?.inactive || 0;
      case 'all':
        return marketsCount?.total || 0;
      default:
        return 0;
    }
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Table
          tableName="markets"
          mainActionButton={
            <AddNewButton label={`${isMobileOnly ? '+' : '+ New Market'}`} />
          }
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          columns={columns}
          data={transformDataForTable()}
          totalData={getTotalCount()}
          isFetchingData={isFetching}
          sortFunction={sortMarkets}
          sortBy={sortBy}
          sortType={sortType}
          activePage={activePage}
          setActivePage={setActivePage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          renderContextMenu={renderContextMenu}
          handleSearch={handleSearch}
          csvData={getDataForCSV()}
          readOnly
        />
      </Suspense>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={handleUpdateActiveStatus}
      />
    </>
  );
};
