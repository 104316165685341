import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isSuperAdmin } from '../../auth/userUtils';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { DetailsTextFields } from '../../components/DetailsTextFields/DetailsTextFields';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import {
  useDeleteUserMutation,
  useGetAuthenticatedUserProfileQuery,
  useGetUserByIdQuery,
  useUpdateUserActiveStatusMutation,
  useResetUserPasswordMutation,
} from '../../redux/api/user.endpoints';
import { USER_ROLES, resetPasswordToastData } from '../../types/User';
import { handleEndpointResult } from '../../utils/api';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { permissionsText } from '../../utils/enums';

const UserDetails = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [modalAction, setModalAction] = useState('Activate');
  const [openModal, setOpenModal] = useState(false);

  const [updateActiveStatus, updateActiveStatusResult] =
    useUpdateUserActiveStatusMutation();
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();
  const { userData } = useGetUserByIdQuery(searchParams.get('id') || '', {
    selectFromResult: ({ data }) => ({ userData: data ? data : undefined }),
  });
  const [resetUserPassword, resetUserPasswordResult] =
    useResetUserPasswordMutation();

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
    }
  }, [auth]);

  const handleOpenModal = (action: string) => {
    setModalAction(action);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteUser = () => userData && deleteUser(userData.id);

  const handleUpdateActiveStatus = () =>
    userData && updateActiveStatus(userData.id);

  useEffect(() => {
    if (
      authProfile &&
      authProfile.role !== USER_ROLES.ADMIN &&
      authProfile.role !== USER_ROLES.SUPER_ADMIN
    ) {
      navigate('/');
    }
  }, [authProfile]);

  useEffect(() => {
    if (deleteUserResult && deleteUserResult.isSuccess) {
      toast.success('User deleted successfully.', {
        toastId: 'delete-user-success',
      });
      setOpenModal(false);
      navigate('/admin/users');
    } else if (deleteUserResult && deleteUserResult.isError) {
      const errorResult = deleteUserResult.error as FetchBaseQueryError;
      const data = errorResult.data as unknown as { error: string };
      toast.error(
        data && data.error
          ? data.error
          : 'An error occurred while deleting the user.',
        { toastId: 'create-user-error' },
      );
    }
  }, [deleteUserResult]);

  useEffect(() => {
    handleEndpointResult(
      {
        toastMsg: `User ${
          userData?.is_active ? 'deactivated' : 'activated'
        } successfully.`,
        toastId: 'edit-user-status-success',
        toastErrorMsg:
          "An error occurred while changing the user's active status.",
        toastErrorId: 'edit-user-status-error',
      },
      updateActiveStatusResult,
      () => setOpenModal(false),
    );
  }, [updateActiveStatusResult]);

  useEffect(() => {
    handleEndpointResult(resetPasswordToastData, resetUserPasswordResult, () =>
      setOpenModal(false),
    );
  }, [resetUserPasswordResult]);

  const getHeaderText = () => {
    if (modalAction === 'ResetPassword') return 'Reset Password';
    return userData ? `${modalAction} User: ${userData.name}.` : '';
  };

  const getSecondaryText = () => {
    if (modalAction === 'ResetPassword')
      return `Are you sure you want to reset the password for User ${userData?.name}?`;

    return userData
      ? `Are you sure you want to ${
          modalAction === 'Delete'
            ? 'permanently delete'
            : modalAction.toLowerCase()
        } User ${userData.name}?${
          modalAction === 'Delete' ? ' This action can not be undone.' : ''
        } `
      : '';
  };

  const renderActionButtons = () => {
    if (!userData || !authProfile) return <></>;
    const isSameUser = authProfile.id === userData.id;
    const canModify =
      authProfile.role === USER_ROLES.SUPER_ADMIN ||
      (authProfile.role === USER_ROLES.ADMIN &&
        userData.role !== USER_ROLES.SUPER_ADMIN);
    return (
      <div
        className={`${
          !isMobileOnly
            ? 'd-flex row flex-grow-slightly header-action-buttons row'
            : 'row'
        } ${isSameUser ? 'justify-content-end flex-grow-slightly me-4' : ''}`}
      >
        {!isSameUser && canModify && (
          <>
            <button
              className="btn btn-primary col-md-4 col-3 ms-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
              onClick={() =>
                userData &&
                handleOpenModal(userData.is_active ? 'Deactivate' : 'Activate')
              }
            >
              <i className="icon-Deactivate font-24" />
              {!isMobileOnly && (
                <span className="">
                  {userData && userData.is_active ? 'Deactivate' : 'Activate'}
                </span>
              )}
            </button>
            <button
              className="btn btn-primary col-md-4 col-3 ms-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
              onClick={() => handleOpenModal('Delete')}
            >
              <i className="icon-Delete font-24" />
              {!isMobileOnly && <span className="">Delete</span>}
            </button>
          </>
        )}
        {canModify && (
          <button
            className={`btn btn-primary col-md-3 col-3  bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex align-items-center justify-content-center ${
              isSameUser ? (isMobile ? 'px-4 ' : '') : 'ms-2'
            } px-1`}
            onClick={() => {
              localStorage.setItem(
                'renolink-return-to',
                `${location.pathname}${location.search}`,
              );
              navigate(`/admin/users/edit?id=${userData?.id || 0}`);
            }}
          >
            <i className="icon-Edit font-24" />
            {!isMobileOnly && <span className="">Edit</span>}
          </button>
        )}
        {isSameUser && canModify && <div className="col-1" />}
      </div>
    );
  };

  const fields = [
    { label: 'First Name', value: userData?.first_name || '' },
    { label: 'Last Name', value: userData?.last_name || '' },
    { label: 'Email', value: userData?.email || '' },
    { label: 'Phone', value: userData?.phone || '' },
    { label: 'Company Name', value: userData?.company || '' },
  ];

  userData &&
    userData.position &&
    fields.push({ label: 'Position', value: userData?.position || '' });

  return (
    <>
      <Helmet>
        <title>Renolink - User Details</title>
      </Helmet>
      <HeaderTab title="Details" renderActionButtons={renderActionButtons} />
      <div className="my-3 rounded bg-white-and-shadowed d-flex p-4 h-100 scrollable">
        <div className="container px-2 my-3 col-10">
          <main>
            <div
              className={`font-semibold ${
                !isMobile ? 'd-flex row' : ''
              } text-center text-wrap-anywhere ${
                isMobileOnly ? 'font-18' : 'font-24'
              }`}
            >
              <div className={'col-12'}>
                <p className={`${!isMobile ? 'inline' : ''}`}>
                  User Information
                </p>
                {isSuperAdmin(authProfile) && (
                  <button
                    className={`${
                      !isMobile ? 'inline mb-1' : ''
                    } btn btn-primary ms-3 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font font-14`}
                    onClick={() => {
                      handleOpenModal('ResetPassword');
                    }}
                  >
                    <span>Reset Password</span>
                  </button>
                )}
              </div>
            </div>
            <div>
              <DetailsTextFields fields={fields} fieldsPerRow={3} />
              <div className="d-flex row mt-3">
                <div className="col-12 col-md-4">
                  <p className="font-semibold">Permissions</p>
                  <div className="d-flex flex-row align-items-center">
                    <div className={`${isTablet ? 'col-2' : 'col-1'}`}>
                      <input
                        type="radio"
                        className="form-check-input"
                        checked
                        disabled
                      />
                    </div>
                    <div className="col-10 lazuli-grey-text ms-2 ms-md-0">
                      {permissionsText[userData?.role || 0]}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-3 mt-md-0">
                  {(userData?.role === USER_ROLES.PROJECT_MANAGER ||
                    userData?.role === USER_ROLES.SENIOR_PROJECT_MANAGER) && (
                    <>
                      <p className="font-semibold">Market</p>
                      <p className="lazuli-grey-text">
                        {userData?.market?.name || ''}
                      </p>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-4 mt-3 mt-md-0">
                  {userData?.role === USER_ROLES.PROJECT_MANAGER && (
                    <>
                      <p className="font-semibold">Senior Project Manager</p>
                      {userData && userData.seniorProjectManager ? (
                        <p className="lazuli-grey-text">
                          {userData?.seniorProjectManager?.name || ''}
                        </p>
                      ) : (
                        <p className="lazuli-grey-text fst-italic">
                          Not Assigned
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
              {isMobileOnly && <div style={{ height: '5rem' }}> </div>}
            </div>
          </main>
        </div>
      </div>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={
          modalAction === 'Delete'
            ? handleDeleteUser
            : modalAction === 'ResetPassword'
            ? () => resetUserPassword({ email: userData?.email || '' })
            : handleUpdateActiveStatus
        }
      />
    </>
  );
};

export default UserDetails;
