import { useState } from 'react';
import RenoLinkLogo from '../../styles/svgs/renolink-white.svg';
import AdminHeaderActions from '../AdminHeader/AdminHeaderActions';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav
      className="navbar navbar-light bg-lazuli-extra-dark-blue navbar-border-bottom py-0"
      style={{ zIndex: 5 }}
    >
      <div className="container-fluid ps-0 ms-0 me-1 d-flex justify-content-between">
        <span className="d-flex align-items-center text-white">
          <button
            className="toggle-sidebar-button btn p-0"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => {
              document
                .getElementById('renolink-sidebar')
                ?.classList.toggle('hidden-sidebar');
              document
                .getElementById('sidebar-overlay')
                ?.classList.toggle('d-none');
            }}
          >
            <i className="icon-Menu-Expand font-40 mt-1 text-white" />
          </button>
          <img className="pb-2" src={RenoLinkLogo} alt="renolink-logo" />
        </span>
        <AdminHeaderActions
          comesFromNavbar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </nav>
  );
};

export default Navbar;
