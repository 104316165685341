import { Market } from './Markets';

export interface User {
  id: number;
  cognito_id: string;
  email: string;
  created_at: Date;
  updated_at: Date;
  first_name: string;
  last_name: string;
  phone: string;
  company: string;
  role: number;
  is_active: boolean;
  market: Market;
  seniorProjectManager: User | null;
  position: string;
  name: string;
  formattedRole?: string;
  is_soft_deleted?: boolean;
}

export enum USER_ROLES {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  SENIOR_PROJECT_MANAGER = 4,
  PROJECT_MANAGER = 5,
  CUSTOMER = 6,
}

export interface CreateUserDto {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  positionName: string;
  marketId: string;
  roleId: number;
  SPMId: string;
}

export interface EditUserDto {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  positionName: string;
  marketId: string;
  roleId: number;
  SPMId: string;
}

export const permissions = [
  'Project Manager',
  'Senior Project Manager',
  'Customer',
  'Admin',
  'Super Admin',
];

export interface DashboardTablePM extends User {
  openAmount: number;
  openProjects: number;
  over28Days: number;
  pastDue: number;
  hasOpenOver28Days: boolean;
  hasPastDueProjects: boolean;
  completedProjects: number;
  completedAmount: number;
  onTimePercentage: number;
}

export const createToastData = {
  toastErrorMsg: 'An error occurred while creating the user.',
  toastId: 'create-user-success',
  toastErrorId: 'create-user-error',
  toastMsg: 'User created successfully!',
};

export const editToastData = {
  toastErrorMsg: 'An error occurred while updating the user.',
  toastId: 'edit-user-success',
  toastErrorId: 'edit-user-error',
  toastMsg: 'User updated successfully!',
};

export const resetPasswordToastData = {
  toastMsg: `An email has been sent to this user with instructions to reset their password.`,
  toastId: 'reset-user-password-success',
  toastErrorMsg: "An error occurred while resetting the user's password",
  toastErrorId: 'reset-user-password-error',
};
