import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import LoginImage from '../../../components/LoginImage/LoginImage';
import { RenolinkHeader } from '../../../components/RenolinkHeader/RenolinkHeader';
import { DISPLAY_TEXT, RENOLINK_HEADER } from '../../../utils/enums';

export const ConfirmationScreen = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Renolink - Reset Email Sent</title>
      </Helmet>
      <main className="container-fluid px-0 bg-lazuli-white h-100">
        <div className="row full-height">
          <LoginImage />

          <div className="col-md-6 px-0 col-12 d-flex flex-column align-items-center  bg-lazuli-white">
            <div className="d-flex h-100 align-items-center justify-content-center flex-column col-lg-10">
              <RenolinkHeader
                displayErrorBanner={false}
                secondaryText={RENOLINK_HEADER.RESET_CONFIRMATION}
                errorBannerText={''}
              />
              <div className="h-25 my-4 px-5 d-flex col-sm-12 col-lg-8 flex-row text-center align-items-center justify-content-center text-wrap">
                <p className="lazuli-grey font-14 reset-confirmation-text">
                  {DISPLAY_TEXT.CONFIRMATION_LINK_SENT}
                </p>
              </div>
              <div className="col-sm-12 w-100 col-lg-7 h-25 my-4 px-5 d-flex flex-row text-center align-items-center justify-content-center text-wrap">
                <p className="lazuli-grey font-weight-bold">
                  Did not receive the email? Check your spam filter or{' '}
                  <button
                    className="btn btn-link px-0 font-weight-bold"
                    onClick={() => navigate('/password-recovery')}
                  >
                    try again.
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
