// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ReactElement,
  JSXElementConstructor,
} from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { Document } from '../../types/Documents';
import { fileIsValidImage } from '../../utils/validations';

interface Props {
  files: Document[];
  uploadDocument: (data: FormData) => void;
  deleteDocuments: (documentIds: number[]) => void;
  setFileType: Dispatch<SetStateAction<string>>;
  setErrorDocMessage: Dispatch<SetStateAction<string>>;
  handleOpenUploadModal: () => void;
  canAddFile: boolean;
}

const ProjectImages = ({
  files,
  uploadDocument,
  deleteDocuments,
  setFileType,
  setErrorDocMessage,
  handleOpenUploadModal,
  canAddFile,
}: Props) => {
  const [searchParams] = useSearchParams();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isSingleDeleting, setIsSingleDeleting] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState('');

  const hiddenFileInput = React.useRef(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Object is possibly 'null'.
    const fileUploaded = event.target.files[0];

    if (fileUploaded.size >= 30362843.46) {
      setErrorDocMessage('Files must be <30Mb');
      return;
    }

    if (!fileIsValidImage(fileUploaded.name)) {
      setErrorDocMessage('Invalid Format. Must be jpg, png, jpeg');
      return;
    }

    setFileType('image');
    handleOpenUploadModal();

    const documentFormData = new FormData();
    documentFormData.append('project_id', searchParams.get('id')!);
    documentFormData.append('owner_type', 'project');
    documentFormData.append('file_type', 'image');
    documentFormData.append('file', fileUploaded);
    uploadDocument(documentFormData);
  };

  const handleClick = () => {
    if (hiddenFileInput !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      hiddenFileInput.current.click();
    }
  };

  const handleSelectAll = () => {
    setDocuments((prev) =>
      prev.map((doc) => ({ ...doc, selected: !isSelectAllChecked })),
    );
    setIsSelectAllChecked((prev) => !prev);
  };

  const handleDocumentSelection = (document: Document) => {
    setDocuments((prev) =>
      prev.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, selected: !doc.selected };
        }
        return { ...doc };
      }),
    );
    isSelectAllChecked && setIsSelectAllChecked(false);
  };

  const handleDeleteSelection = () => {
    const documentIds = documents.reduce((filtered: number[], document) => {
      if (document.selected) {
        filtered.push(parseInt(document.id));
      }
      return filtered;
    }, []);
    setFileType('image');
    deleteDocuments(documentIds);
    isSelectAllChecked && setIsSelectAllChecked(false);
    setOpenDeleteModal(false);
  };

  const handleSingleDelete = (id: string) => {
    const documentIds = [parseInt(id)];
    setFileType('image');
    deleteDocuments(documentIds);
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (files) {
      setDocuments([
        ...files.filter((document) => document.file_type === 'image'),
      ]);
    }
  }, [files]);

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => ReactElement<any, string | JSXElementConstructor<any>> | null;
    disabled: boolean;
  };

  const CustomToggle = React.forwardRef(function displayCustomToggle(
    props: CustomToggleProps,
    ref: React.Ref<HTMLElement>,
  ) {
    return (
      <i
        className="icon-Dot-menu font-28"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          !props.disabled && props.onClick(e);
        }}
        role="button"
        aria-label="user-context-toggle"
        onKeyDown={(e) => e.preventDefault()}
        tabIndex={0}
      >
        {props.children}
      </i>
    );
  });

  return (
    <div
      className={`my-3 rounded bg-white-and-shadowed d-flex scrollable ${
        isTablet ? 'py-0 px-0 w-100 flex-fill' : 'pt-2 px-3 p-md-4 px-md-5'
      } h-100`}
    >
      <div
        className={`container-fluid mx-0 mt-3 ${
          isTablet ? 'px-0 col-12' : 'px-1 col-12'
        }`}
      >
        <main className={`w-100 h-100 ${isMobile ? 'px-4' : ''}`}>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex">
              <div className="form-group">
                <input
                  className="form-check-input lazuli-checkbox"
                  type="checkbox"
                  id="worker-comp-check"
                  onChange={(e) => e}
                  checked={isSelectAllChecked}
                  onClick={handleSelectAll}
                />
                <label
                  className="form-check-label vertical-align-sub font-weight-bold fake-hyperlink"
                  htmlFor="worker-comp-check"
                >
                  Select All
                </label>
              </div>
            </div>
            <div
              className={`d-flex flex-row-reverse row flex-grow-slightly ${
                isMobileOnly
                  ? 'header-action-buttons-mobile'
                  : 'header-action-buttons'
              }`}
            >
              {canAddFile && (
                <button
                  className="btn btn-primary col-3 col-md-4 mx-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
                  onClick={handleClick}
                >
                  <i className="icon-Upload font-24" />
                  {isMobileOnly ? '' : ' Upload'}
                </button>
              )}
              {canAddFile && (
                <button
                  className="btn btn-primary col-3 col-md-4 mx-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
                  disabled={!documents.some((doc) => doc.selected)}
                  onClick={() => {
                    setIsSingleDeleting(false);
                    setOpenDeleteModal(true);
                  }}
                >
                  <i className="icon-Delete font-24" />
                  {isMobileOnly ? '' : ' Delete'}
                </button>
              )}
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          </div>
          <hr className="breakline" />
          <div className="row">
            {documents.map((document) => (
              <div
                className={`${isMobileOnly ? 'col-12' : 'col-3'} px-1 my-1`}
                key={document.id}
              >
                {' '}
                <div className="card border-0 rounded">
                  <div
                    className={`overlay ${
                      document.selected ? 'image-selected' : ''
                    }`}
                  >
                    <input
                      className="form-check-input lazuli-checkbox-image me-3"
                      type="checkbox"
                      id="worker-comp-check"
                      onChange={(e) => e}
                      checked={document.selected}
                      onClick={() => handleDocumentSelection(document)}
                      style={{ position: 'absolute', margin: '10px' }}
                    />
                    <Dropdown style={{ position: 'absolute', right: '0px' }}>
                      <Dropdown.Toggle as={CustomToggle} id="dropdown-basic" />

                      <Dropdown.Menu className="border">
                        <Dropdown.Item
                          href={document.presignedUrl}
                          download={document.name}
                        >
                          Download
                        </Dropdown.Item>
                        {canAddFile && (
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedImageId(document.id);
                              setIsSingleDeleting(true);
                              setOpenDeleteModal(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="overlay-text">{document.name}</div>
                  </div>
                  <div>
                    <LazyLoadImage
                      className="img-fluid image-size"
                      wrapperClassName="w-100"
                      alt={'image' + document.id}
                      src={document.presignedUrl}
                      effect="blur"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
      <ActionConfirmationModal
        isOpen={openDeleteModal}
        closeModal={() => setOpenDeleteModal(false)}
        headerText="Delete Image"
        secondaryText={`Are you sure you want to delete the selected image${
          !isSingleDeleting ? '(s)' : ''
        }?`}
        primaryAction={() => setOpenDeleteModal(false)}
        secondaryAction={() =>
          isSingleDeleting
            ? handleSingleDelete(selectedImageId)
            : handleDeleteSelection()
        }
      />
    </div>
  );
};

export default ProjectImages;
