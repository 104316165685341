import {
  EditProjecProgresstDto,
  CreateProjectDto,
  EditProjectDto,
  getProjectCountResponse,
  getProjectsDataForDashboardResponse,
  Project,
} from '../../types/Project';
import { BaseGetForTablePayload } from '../../types/Common';
import { renolinkApi } from './renolink.api';

const projectEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    getAllProjects: build.query<Project[], BaseGetForTablePayload>({
      query: (payload) =>
        `/project?pageSize=${payload.pageSize}&pageNumber=${
          payload.pageNumber
        }&sortBy=${payload.sortBy}&sortType=${payload.sortType}&tab=${
          payload.tab
        }&search=${payload.searchQuery}&filter=${payload.filter || ''}
        &timeRange=${payload.timeRange || ''}${
          payload.projectManagerId
            ? `&projectManagerId=${payload.projectManagerId}&projectTypeFilter=${payload.projectTypeFilter}`
            : ''
        }`,
      providesTags: ['Project'],
    }),
    getProjectsCount: build.query<
      getProjectCountResponse,
      {
        search: string;
        filter: string | null;
        timeRange?: string;
        projectManagerId?: number;
        projectTypeFilter?: 'ALL' | 'SPM' | 'PM';
      }
    >({
      query: (payload) =>
        `/project/count?search=${payload.search}&filter=${
          payload.filter ? payload.filter : ''
        }&timeRange=${payload.timeRange}${
          payload.projectManagerId
            ? `&projectManagerId=${payload.projectManagerId}&projectTypeFilter=${payload.projectTypeFilter}`
            : ''
        }`,
      providesTags: ['Project'],
    }),
    getProjectById: build.query<Project, string>({
      query: (id) => `/project/byId/${id}`,
      providesTags: ['ProjectData'],
    }),
    getProjectsDataForDashboard: build.query<
      getProjectsDataForDashboardResponse,
      { status: string; timeRange?: string; projectManagerId?: number }
    >({
      query: (payload) =>
        `/project/dashboard?status=${payload.status}&timeRange=${
          payload.timeRange
        }${
          payload.projectManagerId
            ? `&projectManagerId=${payload.projectManagerId}`
            : ''
        }`,
      providesTags: ['ProjectDashboard'],
    }),
    createProject: build.mutation<Project, CreateProjectDto>({
      query: (data) => ({
        url: `/project`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'Project',
        'ProjectCSV',
        'ProjectDashboard',
        'ProjectHistory',
        'ProjectHistoryCSV',
      ],
    }),
    editProject: build.mutation<boolean, EditProjectDto>({
      query: (data) => ({
        url: '/project',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        'Project',
        'ProjectData',
        'ProjectCSV',
        'ProjectDashboard',
        'ProjectHistory',
        'ProjectHistoryCSV',
      ],
    }),
    editProjectProgress: build.mutation<boolean, EditProjecProgresstDto>({
      query: (data) => ({
        url: '/project/progress',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        'Project',
        'ProjectData',
        'ProjectCSV',
        'ProjectDashboard',
        'ProjectHistory',
        'ProjectHistoryCSV',
      ],
    }),
    deleteProject: build.mutation<boolean, { id: number; creatorId: number }>({
      query: ({ id, creatorId }) => ({
        url: `/project/soft-delete-project/${id}?editorId=${creatorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Project',
        'ProjectCSV',
        'ProjectData',
        'ProjectDashboard',
        'ProjectHistory',
        'ProjectHistoryCSV',
      ],
    }),
    getProjectsForCSV: build.query<
      Project[],
      { projectManagerId: number | undefined; tab: string }
    >({
      query: ({ projectManagerId, tab }) =>
        `/project?sortBy=id&sortType=DESC&tab=${tab}&search=${
          projectManagerId ? `&projectManagerId=${projectManagerId}` : ''
        }`,
      providesTags: ['ProjectCSV'],
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetProjectsCountQuery,
  useGetProjectByIdQuery,
  useCreateProjectMutation,
  useEditProjectMutation,
  useEditProjectProgressMutation,
  useDeleteProjectMutation,
  useGetProjectsForCSVQuery,
  useGetProjectsDataForDashboardQuery,
} = projectEndpoints;
