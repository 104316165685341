import { Note } from '../../types/Note';
import { renolinkApi } from './renolink.api';

const noteEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    createNote: build.mutation<
      Note,
      { text: string; userId: number; projectId: number }
    >({
      query: (data) => ({
        url: `/note`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ProjectNotes', 'ProjectHistory', 'ProjectHistory'],
    }),
    updateNote: build.mutation<
      Note,
      {
        id: number;
        editorId: number;
        projectId: number;
        text: string;
      }
    >({
      query: (data) => ({
        url: `/note`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['ProjectNotes', 'ProjectHistory', 'ProjectHistory'],
    }),
    getProjectNotes: build.query<Note[], number>({
      query: (projectId) => `/note?projectId=${projectId}`,
      providesTags: ['ProjectNotes'],
    }),
    deleteNote: build.mutation<
      boolean,
      { id: number; editorId: number; projectId: number }
    >({
      query: (data) => ({
        url: `/note`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['ProjectNotes', 'ProjectHistory', 'ProjectHistory'],
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useGetProjectNotesQuery,
  useDeleteNoteMutation,
} = noteEndpoints;
