import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authContext } from '../../../components/CognitoContextProvider/CognitoContextProvider';
import LoginImage from '../../../components/LoginImage/LoginImage';
import { ConfirmResetPasswordForm } from '../../../components/PasswordResetForm/ConfirmPasswordResetForm';
import { useQuery } from '../../../hooks/useQuery';

export const ConfirmResetPassword = () => {
  const { passwordReset, completePasswordChallenge, auth } =
    useContext(authContext);
  const navigate = useNavigate();
  const query = useQuery();
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [displayErrorBanner, setDisplayErrorBanner] = useState(false);
  const [isForced, setIsForced] = useState(false);
  const { logOut } = useContext(authContext);

  const handlePasswordReset = async (matches: boolean, password: string) => {
    if (matches) {
      setDisplayErrorBanner(false);
      let hasErrors = false;
      let errorMessage = '';
      if (isForced) {
        const passwordChallengeResult = await completePasswordChallenge(
          auth.data,
          password,
        );
        hasErrors = passwordChallengeResult.hasErrors;
        errorMessage = passwordChallengeResult.errorMessage;
      } else {
        const resetResult = await passwordReset(email, resetCode, password);
        hasErrors = resetResult.hasErrors;
        errorMessage = resetResult.errorMessage;
      }

      hasErrors
        ? toast.error(errorMessage, { toastId: 'password-reset-error' })
        : toast.success('Your new password has been created successfully!', {
            toastId: 'password-reset-success',
          });

      await logOut();
      !hasErrors && navigate('/');
    } else {
      setDisplayErrorBanner(true);
    }
  };

  useEffect(() => {
    const email = query.get('email')?.replace(' ', '+');
    const resetCode = query.get('resetCode');
    const forced = query.get('forcedReset');
    if (email && resetCode) {
      setEmail(email);
      setResetCode(resetCode);
    } else if (email && forced) {
      setEmail(email);
      setIsForced(true);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Renolink - Reset Password</title>
      </Helmet>
      <main className="container-fluid">
        <div className="row full-height">
          <LoginImage />
          <div
            aria-label="confirm-reset-form"
            className="col-md-6 px-3 px-md-4 px-lg-5 col-12 d-flex align-items-center justify-content-center bg-lazuli-white"
          >
            {
              <ConfirmResetPasswordForm
                handleResetPassword={handlePasswordReset}
                displayErrorBanner={displayErrorBanner}
              />
            }
          </div>
        </div>
      </main>
    </>
  );
};
