import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
import { isAdminOrSuperAdmin, isPMOrSPM } from '../../auth/userUtils';
import { DetailsTextFields } from '../../components/DetailsTextFields/DetailsTextFields';
import { Note } from '../../types/Note';
import { EditProjecProgresstDto, Project } from '../../types/Project';
import { User } from '../../types/User';
import { ActionConfirmationModal } from '../Modals/ActionConfirmationModal/ActionConfirmationModal';
import { ACTION_CONFIRMATION_MODAL, projectStatuses } from '../../utils/enums';
import { getFormattedDate } from '../../utils/table';
import { useEditProjectProgressMutation } from '../../redux/api/project.endpoints';
import { handleEndpointResult } from '../../utils/api';

interface Props {
  projectData?: Project;
  projectNotes: Note[];
  addNote: (text: string) => void;
  editNote: (id: number, text: string) => void;
  renderContextMenu: (noteId: number, userId: number) => JSX.Element;
  selectedNote: Note | undefined;
  setSelectedNote: Dispatch<SetStateAction<Note | undefined>>;
  editingNote: boolean;
  setEditingNote: Dispatch<SetStateAction<boolean>>;
  authUser: User | undefined;
}

const ProjectInformation = ({
  projectData,
  addNote,
  editNote,
  projectNotes,
  renderContextMenu,
  selectedNote,
  setSelectedNote,
  editingNote,
  setEditingNote,
  authUser,
}: Props) => {
  const [noteToggled, setNoteToggled] = useState(false);
  const [note, setNote] = useState('');
  const [progress, setProgress] = useState(projectData?.progress || '');
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [editProjectProgress, editProjectProgressResult] =
    useEditProjectProgressMutation();

  const handleEditProjectProgress = (
    editProjectDto: EditProjecProgresstDto,
  ) => {
    editProjectProgress(editProjectDto);
  };

  useEffect(() => {
    if (selectedNote) {
      setNote(selectedNote.text);
    }
  }, [selectedNote]);

  useEffect(() => {
    if (editingNote) {
      setNoteToggled(false);
    }
  }, [editingNote]);

  useEffect(() => {
    if (projectData) {
      setProgress(projectData.progress);
    }
  }, [projectData]);

  const fields = [
    {
      label: 'Market',
      value: projectData?.market?.name || '',
      fullWidth: true,
    },
    {
      label: 'Project Manager',
      value: projectData?.project_manager?.name || 'Not Assigned',
      secondaryTexts: [
        projectData?.project_manager?.email || '',
        projectData?.project_manager?.phone || '',
      ],
      fullWidth: true,
    },
    {
      label: 'Customer',
      value:
        projectData && projectData.customer
          ? projectData.customer.company_name
          : '',
    },
    { label: 'Project Status', value: projectData?.progress || '' },
    { label: 'Address', value: projectData?.address || '' },
    { label: 'City', value: projectData?.city || '' },
    { label: 'State', value: projectData?.state || '' },
    { label: 'Zip', value: projectData?.zip || '' },
    {
      label: 'Order Date',
      value: getFormattedDate(projectData?.order_date || ''),
    },
    {
      label: 'Start Date',
      value: getFormattedDate(projectData?.start_date || ''),
    },
    { label: 'Due Date', value: getFormattedDate(projectData?.due_date || '') },
    projectData?.completed_at
      ? {
          label: 'Completed Date',
          value: getFormattedDate(projectData?.completed_at || ''),
        }
      : { label: 'none', value: 'none' },
  ];

  const calculateDaysAlloted = () => {
    if (projectData) {
      const timeInDays = 1000 * 60 * 60 * 24;
      const startTime = new Date(projectData.start_date).getTime();
      const dueTime = new Date(projectData.due_date).getTime();
      return Math.floor((dueTime - startTime) / timeInDays);
    } else return 0;
  };

  const resetTextArea = () => {
    setNoteToggled(false);
    setEditingNote(false);
    setSelectedNote(undefined);
    setNote('');
  };
  const renderTextArea = () => (
    <div>
      <textarea
        className="w-100 mt-3 p-2 rounded"
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
        }}
        maxLength={2000}
      />
      <div className="d-flex justify-content-end mt-3 mb-3">
        <button
          className="btn btn-secondary lazuli-light-blue-border me-2"
          onClick={() => resetTextArea()}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary bg-lazuli-blue"
          disabled={!note.trim()}
          onClick={() => {
            selectedNote
              ? editNote(selectedNote.id, note.trim())
              : addNote(note.trim());
            resetTextArea();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );

  const canAddNote = () => {
    if (isAdminOrSuperAdmin(authUser)) return true;
    if (
      isPMOrSPM(authUser) &&
      (projectData?.project_manager.id === authUser?.id ||
        projectData?.project_manager.seniorProjectManager?.id === authUser?.id)
    )
      return true;
    return false;
  };

  const canHandleNote = (note: Note) => {
    if (isAdminOrSuperAdmin(authUser)) return true;
    if (
      isPMOrSPM(authUser) &&
      (note.user.id === authUser?.id ||
        note.user.seniorProjectManager?.id === authUser?.id)
    )
      return true;
    return false;
  };

  const formLabelStyle = {
    fontWeight: '500',
    color: '#394a64',
    fontSize: '1.1rem',
  };

  const handleOpenModal = (progress: string) => {
    setModalAction(progress);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getModalHeader = () => {
    if (modalAction === projectStatuses.COMPLETE)
      return ACTION_CONFIRMATION_MODAL.COMPLETE_PROJECT_HEADER;

    return 'Change Project Status';
  };

  const getModalSecondary = () => {
    if (modalAction === projectStatuses.COMPLETE)
      return ACTION_CONFIRMATION_MODAL.COMPLETE_PROJECT_SECONDARY;

    if (modalAction === 'invoice')
      return ACTION_CONFIRMATION_MODAL.INVOICE_PROJECT_SECONDARY;

    return `Set project status to ${modalAction}`;
  };

  const handleProgressInputField = (value: string) => {
    setProgress(value);
    const body = {
      id: projectData?.id || 0,
      creatorId: authUser?.id.toString() || '',
      progress: value,
    };
    handleEditProjectProgress(body);
  };

  const handleProgressChange = () => {
    handleProgressInputField(modalAction);
    handleCloseModal();
  };

  useEffect(() => {
    if (
      editProjectProgressResult.isSuccess ||
      editProjectProgressResult.isError
    ) {
      handleEndpointResult(
        {
          toastErrorMsg:
            "An error occurred while editing the Project's progress.",
          toastId: 'edit-project-progress-success',
          toastMsg: "Project's progress edited successfully!",
          toastErrorId: 'edit-project-progress-error',
        },
        editProjectProgressResult,
      );
    }
  }, [editProjectProgressResult]);

  return (
    <>
      <div
        className={`font-semibold d-flex row col-10 offset-1 text-center text-wrap-anywhere ${
          isMobileOnly || isTablet ? 'font-18' : 'font-24'
        }`}
      >
        <p>Project Details</p>
      </div>
      <div className={isTablet ? 'ms-5' : 'ms-3'}>
        <DetailsTextFields
          fields={fields}
          isPMOrSPM={isPMOrSPM(authUser)}
          progress={progress}
          handleOpenModal={handleOpenModal}
        />
        <div className="d-flex row mt-3">
          <div className="col-6 col-md-3">
            <>
              <p className="font-semibold lazuli-grey">Days Alloted</p>
              <p className="lazuli-grey-text">{calculateDaysAlloted()}</p>
            </>
          </div>
          <div className="col-6 col-md-3">
            <>
              <p className="font-semibold lazuli-grey">Days Open</p>
              <p className="lazuli-grey-text">
                {projectData &&
                projectData.days_open &&
                projectData.days_open > 0
                  ? projectData.days_open
                  : 0}
              </p>
            </>
          </div>
          <div className="form-group col-10 col-md-4 px-0 px-md-2 my-0 mb-md-3">
            <label
              htmlFor="font-weight-bold lazuli-grey"
              style={formLabelStyle}
              className="d-flex flex-row"
            >
              <p className="mb-0">Is this a change order?</p>
            </label>
            <div className="row">
              <div className="col-6 col-md-6 col-lg-3 ps-0 ps-md-2 py-3 d-flex flex-row">
                <div className="col-3 col-md-6 me-2 me-md-3 me-lg-2">
                  <input
                    type="radio"
                    className="form-check-input mx-3"
                    checked={projectData?.change_order}
                    disabled={projectData?.change_order}
                    readOnly
                  />
                </div>
                <div className="col-6 ms-2">Yes</div>
              </div>
              <div className="col-6 col-md-6 col-lg-3 ps-0 ps-md-2 py-3 d-flex flex-row">
                <div className="col-3 col-md-6 me-2 me-md-3 me-lg-2">
                  <input
                    type="radio"
                    className="form-check-input mx-3"
                    checked={!projectData?.change_order}
                    disabled={!projectData?.change_order}
                    readOnly
                  />
                </div>
                <div className="col-6 ms-2">No</div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex row mt-3">
          <div className="col-12 col-md-3">
            <>
              <p className="font-semibold lazuli-grey">Contract Amount</p>
              <p className="lazuli-grey-text">
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(projectData?.contract_amount || 0)}
              </p>
            </>
          </div>
          <div className="col-12 col-md-3">
            <>
              <p className="font-semibold lazuli-grey">Total Expended</p>
              <p className="lazuli-grey-text">
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(projectData?.total_expended || 0)}
                (
                {projectData?.percentage_expended
                  ? Math.floor(projectData.percentage_expended)
                  : 0}
                %)
              </p>
            </>
          </div>
          <div className="col-12 col-md-3">
            <>
              <p className="font-semibold lazuli-grey">Invoice Number</p>
              <p className="lazuli-grey-text text-truncate">
                {projectData?.invoice_number || '-'}
              </p>
            </>
          </div>
          <div className="col-12 col-md-3">
            <>
              <p className="font-semibold lazuli-grey">PO Number</p>
              <p className="lazuli-grey-text text-truncate">
                {projectData?.po_number || '-'}
              </p>
            </>
          </div>
        </div>
        <div
          className={`col-11 ${!isTablet && isDesktop ? 'col-lg-12' : ''} mt-3`}
        >
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className="font-semibold lazuli-grey">Project Notes</div>
              <div>
                {canAddNote() && (
                  <button
                    className="btn btn-secondary bg-lazuli-outline-light"
                    onClick={() => {
                      setNoteToggled(true);
                      setNote('');
                      setSelectedNote(undefined);
                    }}
                    disabled={noteToggled || editingNote}
                  >
                    + Add Note
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className={`d-block ${`admin-header-divider-notes${
              isTablet ? '-tablet' : ''
            }`} my-2`}
          />
          {noteToggled && renderTextArea()}
          {projectNotes.map((note) =>
            editingNote && selectedNote && selectedNote.id === note.id ? (
              renderTextArea()
            ) : (
              <div
                key={note.id}
                className="bg-lazuli-white lazuli-grey-text p-3 mb-3"
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <strong>{note.user?.name || 'Deleted User'}</strong>
                    &nbsp;{getFormattedDate(`${note.date}` || '')}
                  </div>
                  {canHandleNote(note) &&
                    renderContextMenu(note.id, note.user?.id || 0)}
                </div>
                <div className="multiline-text">{note.text}</div>
              </div>
            ),
          )}
          <ActionConfirmationModal
            isOpen={openModal}
            closeModal={handleCloseModal}
            headerText={getModalHeader()}
            secondaryText={getModalSecondary()}
            primaryAction={handleCloseModal}
            secondaryAction={handleProgressChange}
          />
          <div style={{ height: '2rem' }}> </div>
        </div>
        {isMobileOnly && <div style={{ height: '5rem' }}> </div>}
      </div>
    </>
  );
};

export default ProjectInformation;
