import {
  EditSubcontractorDto,
  getSubcontractorCountResponse,
  getSubcontractorsForTablePayload,
  Subcontractor,
} from '../../types/Subcontractors';
import { renolinkApi } from './renolink.api';

const subcontractorEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    getAllSubcontractors: build.query<
      Subcontractor[],
      getSubcontractorsForTablePayload
    >({
      query: (payload) =>
        `/subcontractor?pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&tab=${payload.tab}&search=${payload.searchQuery}`,
      providesTags: ['Subcontractor'],
    }),
    getSubcontractorById: build.query<Subcontractor, string>({
      query: (id) => `/subcontractor/byId/${id}`,
      providesTags: ['SubcontractorData'],
    }),
    getSubcontractorsCount: build.query<
      getSubcontractorCountResponse,
      { search: string }
    >({
      query: (payload) => `/subcontractor/count?search=${payload.search}`,
      providesTags: ['Subcontractor'],
    }),
    getSubcontractorsForCSV: build.query<Subcontractor[], void>({
      query: () => `/subcontractor?sortBy=id&sortType=DESC&tab=all&search=`,
      providesTags: ['SubcontractorCSV'],
    }),
    updateSubcontractorActiveStatus: build.mutation<
      { message: string },
      number
    >({
      query: (id) => ({
        url: `/subcontractor/update-active-status/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        'Subcontractor',
        'SubcontractorData',
        'SubcontractorCSV',
      ],
    }),
    createSubcontractor: build.mutation<Subcontractor, FormData>({
      query: (data) => ({
        url: `/subcontractor`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Subcontractor', 'SubcontractorCSV'],
    }),
    deleteSubcontractor: build.mutation<boolean, number>({
      query: (id) => ({
        url: `/subcontractor/soft-delete-subcontractor/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Subcontractor',
        'SubcontractorData',
        'SubcontractorCSV',
      ],
    }),
    editSubcontractor: build.mutation<boolean, EditSubcontractorDto>({
      query: (data) => ({
        url: `/subcontractor/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        'Subcontractor',
        'SubcontractorData',
        'AuthenticatedUserProfile',
        'SubcontractorCSV',
      ],
    }),
  }),
});

export const {
  useGetAllSubcontractorsQuery,
  useGetSubcontractorsCountQuery,
  useGetSubcontractorsForCSVQuery,
  useCreateSubcontractorMutation,
  useDeleteSubcontractorMutation,
  useEditSubcontractorMutation,
  useUpdateSubcontractorActiveStatusMutation,
  useGetSubcontractorByIdQuery,
} = subcontractorEndpoints;
