export const getFormattedDate = (stringDate: string | Date) => {
  const date = new Date(stringDate);
  const year = date.getFullYear();

  let month = (1 + date.getUTCMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getUTCDate().toString();
  day = day.length > 1 ? day : '0' + day;
  return month + '/' + day + '/' + year;
};

export const formatAsMoney = (amount: number) => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

export interface ColumnProps {
  title: string;
  attribute: string;
  hasSpecialFormat?: boolean;
  specialFormat?: (unformattedValue: number | Date) => string;
  sortValue?: string;
  tooltip?: string;
  minWidth?: string;
  maxWidth?: string;
  classes?: string;
  textCenter?: boolean;
  width?: string;
  hasTooltip?: boolean;
  centerHeader?: boolean;
}

export const getDaysForDate = (date: Date) => {
  const timeInDays = 1000 * 60 * 60 * 24;
  return Math.floor(date.getTime() / timeInDays);
};
