import { compose, configureStore } from '@reduxjs/toolkit';
import { renolinkApi } from './api/renolink.api';

const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  devTools: composeEnhancers,
  reducer: {
    [renolinkApi.reducerPath]: renolinkApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(renolinkApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
