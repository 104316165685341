import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

interface Props {
  label: string;
}

export const AddNewButton = ({ label }: Props) => {
  const navigate = useNavigate();

  return (
    <button
      className={`btn btn-primary bg-lazuli-blue ${
        isMobile ? 'px-3' : 'px-4'
      } py-2`}
      onClick={() => navigate('create')}
    >
      <span className="font-12">{label}</span>
    </button>
  );
};
