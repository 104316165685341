import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';

export const validateEmail = (email: string) => {
  return isEmail(email);
};

export const validatePassword = (password: string) => {
  return isStrongPassword(password);
};

export const fileIsValidImage = (fileName: string) => {
  const extensionLists = ['jpg', 'png', 'jpeg'];
  const fileExtensionArray = fileName.split('.');
  const fileExtensionType = fileExtensionArray.pop();
  return extensionLists.includes(fileExtensionType || '');
};
