import { Document } from '../../types/Documents';
import { renolinkApi } from './renolink.api';

const subcontractorEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    getAllDocumentsBySubcontractorId: build.query<Document[], string>({
      query: (id) => `/document/bySubcontractorId/${id}`,
      providesTags: ['DocumentsData'],
    }),

    getAllDocumentsByProjectId: build.query<
      { documents: Document[]; images: Document[]; documentsCount: number },
      { id: string; pageNumber: number }
    >({
      query: (query) =>
        `/document/byProjectId/${query.id}?pageNumber=${query.pageNumber}`,
      providesTags: ['DocumentsData'],
    }),

    createDocument: build.mutation<Document, FormData>({
      query: (data) => ({
        url: `/document`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Document', 'DocumentsData', 'SubcontractorData'],
    }),
    deleteDocument: build.mutation<boolean, { documentIds: number[] }>({
      query: (data) => ({
        url: `/document`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Document', 'DocumentsData'],
    }),
  }),
});

export const {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetAllDocumentsBySubcontractorIdQuery,
  useGetAllDocumentsByProjectIdQuery,
} = subcontractorEndpoints;
