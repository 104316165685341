import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';
import { CreateMarketDto } from '../../types/Markets';
import { ACTION_CONFIRMATION_MODAL } from '../../utils/enums';
import StateDropdown from '../Dropdowns/StateDropdown';
import { ActionConfirmationModal } from '../Modals/ActionConfirmationModal/ActionConfirmationModal';

interface Props {
  handleCreateMarket: (createMarketDto: CreateMarketDto) => void;
  isLoading: boolean;
  duplicateNameError: boolean;
  setDuplicateNameError: Dispatch<SetStateAction<boolean>>;
}

const CreateMarketForm = ({
  handleCreateMarket,
  isLoading,
  duplicateNameError,
  setDuplicateNameError,
}: Props) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const [nameError, setNameError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    const nameHasErrors = !name.trim();
    const cityHasErrors = !city.trim();
    const stateHasErrors = !state.trim();
    if (nameHasErrors || cityHasErrors || stateHasErrors) {
      setNameError(nameHasErrors);
      setCityError(cityHasErrors);
      setStateError(stateHasErrors);
      return;
    } else {
      handleCreateMarket({
        name: name.trim().toUpperCase(),
        city: city.trim(),
        state,
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleInputOnChange = (
    property: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let value = e.target.value;
    if (value.length > 25) value = value.substring(0, 25);

    if (property === 'name') {
      if (duplicateNameError) setDuplicateNameError(false);
      if (nameError) setNameError(false);
      setName(value.toUpperCase());
    } else {
      if (cityError) setCityError(false);
      setCity(value);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const isCreateDisabled =
    isLoading ||
    nameError ||
    cityError ||
    stateError ||
    !name ||
    !city ||
    !state;

  return (
    <>
      <div className="form-container" style={{ overflow: 'hidden' }}>
        <h2
          aria-label="create-market-title"
          className="text-center font-weight-bold"
        >
          Create New Market
        </h2>
        <form id="create-market-form px-5" onSubmit={handleSubmit}>
          <div className="row pb-0 pt-2 py-md-3 d-flex justify-content-center">
            <div className="form-group col-11 col-md-5 mx-2 px-4 px-md-5">
              <label
                htmlFor="font-weight-bold lazuli-grey"
                className="form-label-style"
              >
                Display Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium ${
                  nameError || duplicateNameError ? 'error' : ''
                }`}
                id="marketDisplayNameId"
                aria-describedby="MarketDisplayNameHelp"
                value={name}
                onChange={(e) => handleInputOnChange('name', e)}
                aria-label="market-display-name-input"
              />
              {nameError && (
                <p className="text-error mt-1 ms-2">
                  Display name can not be empty
                </p>
              )}
              {duplicateNameError && (
                <p className="text-error mt-1 ms-2">Duplicate market name.</p>
              )}
            </div>
          </div>
          <div className="row pb-0 pt-2 py-md-3 d-flex justify-content-center">
            <div className="form-group col-11 col-md-5 mx-2 px-4 px-md-5">
              <label
                htmlFor="font-weight-bold lazuli-grey"
                className="form-label-style"
              >
                City <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium ${
                  cityError ? 'error' : ''
                }`}
                id="marketCityId"
                aria-describedby="MarketCityHelp"
                value={city}
                onChange={(e) => handleInputOnChange('city', e)}
                aria-label="market-city-input"
              />
              {cityError && (
                <p className="text-error mt-1 ms-2">City can not be empty</p>
              )}
            </div>
            <div className="form-group col-11 col-md-5 mx-2 px-4 px-md-5 pt-2 pt-md-0">
              <StateDropdown
                label={
                  <label
                    htmlFor="font-weight-bold lazuli-grey"
                    className="form-label-style"
                  >
                    State <span className="text-danger">*</span>
                  </label>
                }
                setState={setState}
                value={state}
              />
              {stateError && (
                <p className="text-error mt-1 ms-2">State can not be empty</p>
              )}
            </div>
          </div>
        </form>
        <div className="row my-2">
          <div className="col-12 justify-content-center row mx-0">
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-block pt-1 pb-2 lazuli-light-blue-border lazuli-button-medium"
                onClick={handleOpenModal}
              >
                <span className="font-weight-bold font-18">Cancel</span>
              </button>
            </div>
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-primary btn-block py-2 bg-lazuli-blue lazuli-button-medium"
                disabled={isCreateDisabled}
                aria-label="create-market-submit-button"
                form="create-market-form"
                onClick={() => handleSubmit()}
              >
                <span className="font-weight-bold font-18">Create</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={ACTION_CONFIRMATION_MODAL.CREATE_MARKET_HEADER}
        secondaryText={ACTION_CONFIRMATION_MODAL.CREATE_MARKET_SECONDARY}
        primaryAction={handleCloseModal}
        secondaryAction={goBack}
      />
    </>
  );
};

export default CreateMarketForm;
