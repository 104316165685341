import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router';
import AdminHeader from '../../components/AdminHeader/AdminHeader';

interface Props {
  title: string;
  userFullName: string;
  section?: string;
}

const AdminPageContainer = ({ title, userFullName, section }: Props) => {
  return (
    <>
      <Helmet>
        <title>{`Renolink - ${title}`}</title>
      </Helmet>
      <main className="container-fluid bg-lazuli-white p-2 p-md-3 full-height d-flex flex-column mx-0 mx-md-2">
        <AdminHeader
          title={title}
          userFullName={userFullName}
          section={section}
        />
        <Outlet />
      </main>
    </>
  );
};

export default AdminPageContainer;
