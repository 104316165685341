import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useGetUsersCountQuery,
  useUpdateUserActiveStatusMutation,
  useGetUsersForCSVQuery,
  useGetAuthenticatedUserProfileQuery,
} from '../../redux/api/user.endpoints';
import { User, USER_ROLES } from '../../types/User';
import { useNavigate } from 'react-router';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { toast } from 'react-toastify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { formatStringForCSV, USERS_CSV_METADATA } from '../../utils/csv';
import { AddNewButton } from '../../components/Buttons/AddNewButton/AddNewButton';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { ENTITY_NAME, permissionsText } from '../../utils/enums';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { isMobileOnly } from 'react-device-detect';
import { ContextMenu } from '../../components/ContextMenu/ContextMenu';

export const UserTable = () => {
  const Table = lazy(() => import('../../components/Table/Table'));
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [disableModalButton, setDisableModalButton] = useState(false);
  const [activeTab, setActiveTab] = useState('active');
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('name');
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC');
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [modalAction, setModalAction] = useState('Activate');
  const [searchQuery, setSearchQuery] = useState('');
  const { usersData, refetch, getAllUsersQueryError, isFetching } =
    useGetAllUsersQuery(
      {
        pageSize: itemsPerPage,
        pageNumber: activePage,
        sortBy,
        sortType,
        tab: activeTab,
        searchQuery,
      },
      {
        selectFromResult: ({ data, error, isFetching }) => ({
          usersData: data ? data : [],
          getAllUsersQueryError: error,
          isFetching,
        }),
      },
    );

  const { data: usersCount, refetch: refetchUsersCount } =
    useGetUsersCountQuery({ search: searchQuery });
  const { data: CSVData } = useGetUsersForCSVQuery();
  const [updateActiveStatus, updateActiveStatusResult] =
    useUpdateUserActiveStatusMutation();
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  useEffect(() => {
    refetch();
    refetchUsersCount();
  }, [sortBy, sortType, activePage]);

  useEffect(() => {
    if (
      authProfile &&
      authProfile.role !== USER_ROLES.ADMIN &&
      authProfile.role !== USER_ROLES.SUPER_ADMIN
    ) {
      navigate('/');
    }
  }, [authProfile]);

  useEffect(() => {
    setActivePage(1);
  }, [itemsPerPage, activeTab, searchQuery]);

  useEffect(() => {
    if (typeof getAllUsersQueryError !== 'undefined') {
      toast.error('An error occurred when searching users.', {
        toastId: 'get-all-users-querry-error',
      });
    }
  }, [getAllUsersQueryError]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    if (deleteUserResult && deleteUserResult.isSuccess) {
      toast.success('User deleted successfully.', {
        toastId: 'delete-user-success',
      });
      setOpenModal(false);
      setDisableModalButton(false);
    } else if (deleteUserResult && deleteUserResult.isError) {
      const errorResult = deleteUserResult.error as FetchBaseQueryError;
      const data = errorResult.data as unknown as { error: string };
      toast.error(
        data && data.error
          ? data.error
          : 'An error occurred while deleting the user.',
        { toastId: 'create-user-error' },
      );
      setOpenModal(false);
      setDisableModalButton(false);
    }
  }, [deleteUserResult]);

  useEffect(() => {
    if (updateActiveStatusResult && updateActiveStatusResult.isSuccess) {
      const data: { message: string } = updateActiveStatusResult.data;
      toast.success(`User ${data.message} successfully.`, {
        toastId: 'update-user-success',
      });
      setOpenModal(false);
    } else if (updateActiveStatusResult && updateActiveStatusResult.isError) {
      const errorResult = updateActiveStatusResult.error as FetchBaseQueryError;
      const data = errorResult.data as unknown as { error: string };
      toast.error(
        data && data.error
          ? data.error
          : "An error occurred while changing the user's active status.",
        { toastId: 'create-user-error' },
      );
    }
  }, [updateActiveStatusResult]);

  const sortUsers = (category: string) => {
    setSortBy((prev) => {
      if (prev === category) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return category;
      }
      setSortType('ASC');
      return category;
    });
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const getDataForCSV = () => {
    if (CSVData) {
      const dataForCSV = CSVData.map((user) => {
        return {
          ...user,
          seniorProjectManager:
            user.role !== USER_ROLES.PROJECT_MANAGER
              ? 'N/A'
              : user.seniorProjectManager?.name || 'Not Assigned',
          company: formatStringForCSV(user.company),
          status: user.is_active ? 'Active' : 'Inactive',
          formattedRole: USER_ROLES[user.role].replaceAll('_', ' '),
        };
      });
      return { data: dataForCSV, ...USERS_CSV_METADATA };
    }
    return { data: [], headers: [], filename: 'null.csv' };
  };

  const tabs = [
    {
      title: `Active (${usersCount?.active || 0})`,
      attribute: 'active',
    },
    {
      title: `Inactive (${usersCount?.inactive || 0})`,
      attribute: 'inactive',
    },
    {
      title: `All Users (${usersCount?.total || 0})`,
      attribute: 'all',
    },
  ];

  const columns = [
    { title: 'ID', attribute: 'id' },
    { title: 'Name', attribute: 'name', maxWidth: '12rem' },
    {
      title: 'Role',
      attribute: 'role',
      width: '10rem',
    },
    { title: 'Email', attribute: 'email', maxWidth: '12rem' },
    { title: 'Phone', attribute: 'phone', width: '10rem' },
    { title: 'Company', attribute: 'company', maxWidth: '10rem' },
  ];

  if (activeTab === 'all')
    columns.push({
      title: 'Status',
      attribute: 'is_active',
    });

  const transformDataForTable = () =>
    usersData.map(
      (user: User) =>
        ({
          id: user.id.toString(),
          name: user.name || '',
          role: permissionsText[user.role],
          email: user.email || '',
          phone: user.phone || '',
          company: user.company,
          is_active: user.is_active ? 'Active' : 'Inactive',
        } as { [key: string]: string }),
    );

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (action: string, user: User) => {
    setSelectedUser(user);
    setModalAction(action);
    setOpenModal(true);
  };

  const handleDeleteUser = () => {
    if (selectedUser) {
      setDisableModalButton(true);
      deleteUser(selectedUser.id);
    }
  };

  const handleUpdateActiveStatus = () =>
    selectedUser && updateActiveStatus(selectedUser.id);

  const getHeaderText = () =>
    selectedUser ? `${modalAction} User: ${selectedUser.name}.` : '';

  const getSecondaryText = () =>
    selectedUser
      ? `Are you sure you want to ${
          modalAction === 'Delete'
            ? 'permanently delete'
            : modalAction.toLowerCase()
        } User ${selectedUser.name}?${
          modalAction === 'Delete' ? ' This action can not be undone.' : ''
        } `
      : '';

  const renderContextMenu = (userId: number) => {
    const contextMenuProps = {
      entityData: usersData,
      entityName: ENTITY_NAME.USER,
      currentId: userId,
      authProfile,
      handleOpenModal,
    };
    return <ContextMenu {...contextMenuProps} />;
  };

  const getTotalCount = () => {
    switch (activeTab) {
      case 'active':
        return usersCount?.active || 0;
      case 'inactive':
        return usersCount?.inactive || 0;
      case 'all':
        return usersCount?.total || 0;
      default:
        return 0;
    }
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Table
          tableName="users"
          mainActionButton={
            <AddNewButton label={`${isMobileOnly ? '+' : '+ Add New User'}`} />
          }
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          columns={columns}
          data={transformDataForTable()}
          totalData={getTotalCount()}
          isFetchingData={isFetching}
          sortFunction={sortUsers}
          sortBy={sortBy}
          sortType={sortType}
          activePage={activePage}
          setActivePage={setActivePage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          renderContextMenu={renderContextMenu}
          handleSearch={handleSearch}
          csvData={getDataForCSV()}
        />
      </Suspense>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={
          modalAction === 'Delete' ? handleDeleteUser : handleUpdateActiveStatus
        }
        disableActionButton={disableModalButton}
      />
    </>
  );
};
