import React, { useState } from 'react';
import { Expense, ExpenseObject } from '../../../types/Expense';
import { isMobileOnly } from 'react-device-detect';

const formLabelStyle = {
  fontWeight: '500',
  color: '#394a64',
  fontSize: '1.1rem',
  marginRight: '10px',
};

interface props {
  expense?: Expense | false;
  actionType: 'edit' | 'create';
  virtualTabSetter: (tab: 'table' | 'edit' | 'create') => void;
  projectId?: string;
  userId: number;
  createExpense: (obj: ExpenseObject) => void;
  editExpense: (obj: ExpenseObject) => void;
}

export const ProjectExpenseCreate = ({
  expense,
  actionType,
  virtualTabSetter,
  projectId = '0',
  userId,
  createExpense,
  editExpense,
}: props) => {
  const defaultValue: ExpenseObject = {
    type: 'material',
    total: '0',
    description: '',
    userId,
  };

  const [form, setForm] = useState<ExpenseObject>(
    expense && actionType === 'edit' ? { ...expense, userId } : defaultValue,
  );

  const handleSubmit = () => {
    switch (actionType) {
      case 'edit':
        editExpense(form);
        break;
      case 'create':
        createExpense({ ...form, projectId });
        break;
    }
    virtualTabSetter('table');
  };

  const handleCancel = () => {
    virtualTabSetter('table');
  };

  const handleFormChange = (
    field: 'total' | 'description' | 'type',
    value: string,
  ) => {
    const _form: ExpenseObject = { ...form };
    _form[field] = value;
    setForm(_form);
  };

  return (
    <>
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 pb-2 mt-3 no-margin w-100">
          <main>
            <h2
              aria-label="edit-project-title"
              className="text-center font-weight-bold"
            >
              {actionType ? 'edit' : 'create'} Expense
            </h2>
            <form>
              <div className="row py-3 d-flex justify-content-center">
                <div className="form-group col-11 col-md-7 mx-2 my-2">
                  <label
                    htmlFor="font-weight-bold lazuli-grey"
                    style={formLabelStyle}
                    className="d-flex flex-row"
                  >
                    <p className="mb-0">Expense type</p>
                    <p className="ms-1 mb-0 text-error">*</p>
                  </label>
                  <select
                    className="form-select lazuli-select"
                    name="type"
                    id="type"
                    onChange={(ev) => handleFormChange('type', ev.target.value)}
                    value={form.type}
                  >
                    <option value="material">Material</option>
                    <option value="labor">Labor</option>
                  </select>
                </div>
                <div className="form-group col-11 col-md-7 mx-2 my-2">
                  <label
                    htmlFor="font-weight-bold lazuli-grey"
                    style={formLabelStyle}
                    className="d-flex flex-row"
                  >
                    <p className="mb-0">Total</p>
                    <p className="ms-1 mb-0 text-error">*</p>
                  </label>
                  <input
                    type="number"
                    className="form-control lazuli-input-border-medium"
                    value={form.total}
                    onChange={(ev) =>
                      handleFormChange('total', ev.target.value)
                    }
                  />
                </div>
                <div className="form-group col-11 col-md-7 mx-2 my-2">
                  <label
                    htmlFor="font-weight-bold lazuli-grey"
                    style={formLabelStyle}
                    className="d-flex flex-row"
                  >
                    <p className="mb-0">Description</p>
                    <p className="ms-1 mb-0 text-error">*</p>
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control lazuli-input-border-medium"
                    value={form.description}
                    onChange={(ev) =>
                      handleFormChange('description', ev.target.value)
                    }
                  />
                </div>
                <div className="form-group col-11 col-md-12 mx-2 my-2 d-flex justify-content-evenly">
                  <button
                    type="button"
                    className="btn btn-secondary bg-lazuli-red"
                    aria-label="create-project-submit-button"
                    form="create-project-form"
                    onClick={() => handleCancel()}
                  >
                    <span
                      className={`font-weight-bold ${
                        isMobileOnly ? 'font-14' : 'font-18'
                      }`}
                    >
                      Cancel
                    </span>
                  </button>
                  <button
                    type="submit"
                    disabled={
                      !form.description || parseInt(form.total || '0') === 0
                    }
                    className="btn btn-secondary bg-lazuli-blue"
                    aria-label="create-project-submit-button"
                    form="create-project-form"
                    onClick={handleSubmit}
                  >
                    <span
                      className={`font-weight-bold ${
                        isMobileOnly ? 'font-14' : 'font-18'
                      }`}
                    >
                      {actionType === 'edit' ? 'Save' : 'Create'}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </main>
        </div>
      </div>
    </>
  );
};
