import { Expense, ExpenseObject } from '../../types/Expense';
import { renolinkApi } from './renolink.api';

interface getExpensesQueryInterface {
  projectId: string;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortType: 'ASC' | 'DESC';
  //search?: string;
}

const expenseEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    createExpense: build.mutation<ExpenseObject, ExpenseObject>({
      query: (data) => ({
        url: `/expense`,
        body: data,
        method: 'POST',
      }),
    }),
    editExpense: build.mutation<ExpenseObject, ExpenseObject>({
      query: (data) => ({
        url: `/expense`,
        body: data,
        method: 'PUT',
      }),
    }),
    deleteExpense: build.mutation<
      ExpenseObject,
      { id: number; userId: number }
    >({
      query: ({ id, userId }) => ({
        url: `/expense/${id}?userId=${userId}`,
        method: 'DELETE',
      }),
    }),
    getExpensesForProject: build.query<
      {
        expenses: Expense[];
        total: number;
      },
      getExpensesQueryInterface
    >({
      query: ({ projectId, pageNumber, pageSize, sortBy, sortType }) =>
        `/expense?projectId=${projectId}&pageSize=${pageSize}&sortBy=${sortBy}&sortType=${sortType}&pageNumber=${pageNumber}`,
      providesTags: ['ProjectExpenses'],
    }),
  }),
});

export const {
  useGetExpensesForProjectQuery,
  useCreateExpenseMutation,
  useDeleteExpenseMutation,
  useEditExpenseMutation,
} = expenseEndpoints;
