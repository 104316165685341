import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';

interface Props {
  comesFromNavbar?: boolean;
  isOpen?: boolean;
  setIsOpen?: (arg: boolean) => void;
}

const AdminHeaderActions = ({
  comesFromNavbar = false,
  isOpen = false,
  setIsOpen,
}: Props) => {
  const { logOut } = useContext(authContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logOut();
    navigate('/');
  };

  return (
    <div className="row" style={{ width: 'auto' }}>
      <div className="col-auto px-0">
        <i
          className={`icon-User-Profile-${
            isOpen ? 'Selected' : 'Default'
          } font-24`}
          style={{ color: comesFromNavbar ? 'white' : '#1E538A' }}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen && setIsOpen(!isOpen);
          }}
          onBlur={() => {
            setTimeout(() => setIsOpen && setIsOpen(false), 200);
          }}
          role="button"
          aria-label="market-context-toggle"
          onKeyDown={(e) => e.preventDefault()}
          tabIndex={0}
        />

        <button
          className={`d-${isOpen ? 'block' : 'none'} logout-button${
            isMobile ? '-mobile' : ''
          } btn font-14 text-center`}
          onClick={handleLogout}
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default AdminHeaderActions;
