import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router';
import { User } from '../../types/User';
import { ActionConfirmationModal } from '../Modals/ActionConfirmationModal/ActionConfirmationModal';
import 'react-datepicker/dist/react-datepicker.css';
import { ACTION_CONFIRMATION_MODAL } from '../../utils/enums';
import validator from 'validator';
import StateDropdown from '../Dropdowns/StateDropdown';
import { Contact, Customer, EditCustomerDto } from '../../types/Customer';
import { isMobileOnly } from 'react-device-detect';

const formLabelStyle = {
  fontWeight: '500',
  color: '#394a64',
  fontSize: '1.1rem',
};

interface Props {
  handleEditCustomer: (createCustomerDto: EditCustomerDto) => void;
  customerData: Customer | undefined;
  isLoading: boolean;
  authProfile: User;
}

export const EditCustomerForm = ({
  handleEditCustomer,
  isLoading,
  customerData,
}: Props) => {
  const navigate = useNavigate();

  const [customerId, setCustomerId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [primaryAddress, setPrimaryAddress] = useState('');
  const [secondaryAddress, setSecondaryAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactsError, setContactsError] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);

  const addNewContact = () => {
    setContacts([...contacts, ...[{ id: 0, name: '', phone: '', email: '' }]]);
  };

  const deleteContact = (index: number) => {
    const items = [...contacts];

    if (index > -1) {
      items.splice(index, 1);
    }

    setContacts(items);
    setContactsError(false);
  };

  const modifyContact = (index: number, property: string, value: string) => {
    const items = [...contacts];
    const item = { ...contacts[index] };

    if (property === 'name') item.name = value;
    if (property === 'phone') item.phone = value;
    if (property === 'email') item.email = value;

    items[index] = item;
    setContacts(items);
  };

  const contactPhoneValidation = (phone: string) => {
    const result = phone ? phone.includes('_') : false;
    return result;
  };

  const contactEmailValidation = (email: string) => {
    const result = email ? !validator.isEmail(email) : false;
    return result;
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    const companyHasErrors = !companyName.trim();
    const phoneHasErrors = phone.includes('_');
    const emailHasErrors = email ? !validator.isEmail(email) : false;
    const contactsArrayError = contacts.filter(
      (contact) =>
        contactPhoneValidation(contact.phone) ||
        contactEmailValidation(contact.email),
    );
    if (
      phoneHasErrors ||
      emailHasErrors ||
      companyHasErrors ||
      contactsArrayError.length
    ) {
      setPhoneError(phoneHasErrors);
      setEmailError(emailHasErrors);
      setCompanyError(companyHasErrors);
      setContactsError(contactsArrayError.length > 0);
    } else {
      const data = {
        id: customerId,
        companyName,
        primaryAddress,
        secondaryAddress,
        city,
        state,
        zip,
        email,
        phone,
        contacts,
        company_name: companyName,
        primary_address: primaryAddress,
        secondary_address: secondaryAddress,
      };
      handleEditCustomer(data);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (customerData) {
      setCustomerId(customerData.id);
      setCompanyName(customerData.company_name);
      setPrimaryAddress(customerData.primary_address);
      setSecondaryAddress(customerData.secondary_address);
      setCity(customerData.city);
      setState(customerData.state);
      setZip(customerData.zip);
      setEmail(customerData.email);
      setPhone(customerData.phone);
      setContacts(customerData.contacts);
    }
  }, [customerData]);

  const isEditDisabled =
    phoneError ||
    companyError ||
    contactsError ||
    isLoading ||
    !companyName ||
    !!(phone && phone[1] === '_');

  return (
    <>
      <div className="flex-grow-1 form-container form-container-customer">
        <h2
          aria-label="edit-customer-title"
          className="text-center font-weight-bold"
        >
          Edit Customer
        </h2>
        <form id="edit-customer-form px-5" onSubmit={handleSubmit}>
          <div className="row py-3 d-flex">
            <div className="form-group col-11 col-md-6 mx-2">
              <label
                htmlFor="font-weight-bold lazuli-grey mt-3"
                style={formLabelStyle}
              >
                Company Name<span className="required-tag">*</span>
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium ${
                  companyError ? 'error' : ''
                }`}
                id="CompanyNameId"
                aria-describedby="CompanyNameHelp"
                value={companyName}
                maxLength={250}
                onChange={(e) => {
                  if (companyError) setCompanyError(false);
                  setCompanyName(e.target.value);
                }}
                aria-label="company-name-input"
              />
              {companyError && (
                <p className="text-error mt-1 ms-2">
                  Company name can not be empty
                </p>
              )}
            </div>
          </div>
          <div className={`${isMobileOnly ? '' : 'row d-flex'} py-3`}>
            <div className="form-group col-11 col-md-6 mx-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Address 1
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium`}
                id="primaryAddressId"
                aria-describedby="PrimaryAddressHelp"
                value={primaryAddress}
                maxLength={250}
                onChange={(e) => {
                  const primaryAddressValue = e.target.value;
                  setPrimaryAddress(primaryAddressValue);
                }}
                aria-label="primary-address-input"
              />
            </div>
            <div
              className={`form-group col mx-2 ${isMobileOnly ? 'mt-4' : ''}`}
            >
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Address 2
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium`}
                id="secondaryAddressId"
                aria-describedby="SecondaryAddressHelp"
                value={secondaryAddress}
                maxLength={250}
                onChange={(e) => {
                  const secondaryAddressValue = e.target.value;
                  setSecondaryAddress(secondaryAddressValue);
                }}
                aria-label="secondary-address-input"
              />
            </div>
          </div>
          <div className={`${isMobileOnly ? '' : 'row d-flex'} py-3`}>
            <div className="form-group col mx-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                City
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium`}
                id="primaryAddressId"
                aria-describedby="PrimaryAddressHelp"
                value={city}
                maxLength={250}
                onChange={(e) => {
                  const cityValue = e.target.value;
                  setCity(cityValue);
                }}
                aria-label="primary-address-input"
              />
            </div>
            <div className={`form-group col ${isMobileOnly ? '' : 'row'}`}>
              <div
                className={`form-group col mx-2 ${isMobileOnly ? 'my-4' : ''}`}
              >
                <StateDropdown
                  label={
                    <label
                      htmlFor="font-weight-bold lazuli-grey"
                      className="form-label-style"
                    >
                      State
                    </label>
                  }
                  setState={setState}
                  value={state}
                />
              </div>
              <div className={'form-group col mx-2'}>
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                >
                  Zip
                </label>
                <input
                  type="text"
                  className={`form-control lazuli-input-border-medium`}
                  id="zipId"
                  aria-describedby="ZipHelp"
                  value={zip}
                  maxLength={5}
                  onChange={(e) => {
                    const zipValue = e.target.value.replace(/[^0-9]/g, '');
                    setZip(zipValue);
                  }}
                  aria-label="zip-input"
                />
              </div>
            </div>
          </div>
          <div className={`${isMobileOnly ? '' : 'row d-flex'} py-1 py-md-3`}>
            <div className="form-group col-11 col-md-6 mx-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Main Email
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium }`}
                id="EmailId"
                aria-describedby="EmailHelp"
                value={email}
                maxLength={250}
                onChange={(e) => {
                  const emailValue = e.target.value;

                  setEmail(emailValue);
                }}
                aria-label="email-input"
              />
              {emailError && (
                <p className="text-error mt-1 ms-2">Invalid Email</p>
              )}
            </div>
            <div
              className={`form-group col mx-2 ${isMobileOnly ? 'my-4' : ''}`}
            >
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Main Phone
              </label>
              <ReactInputMask
                mask="(999) 999-9999"
                type="tel"
                className={`form-control lazuli-input-border-medium ${
                  phoneError ? 'error' : ''
                }`}
                id="phoneId"
                aria-describedby="PhoneNumberHelp"
                value={phone}
                onChange={(e) => {
                  if (phoneError) setPhoneError(false);
                  let phoneValue = e.target.value;
                  if (phoneValue.length > 14)
                    phoneValue = phoneValue.substring(0, 14);
                  setPhone(phoneValue);
                }}
                aria-label="phone-number-input"
              />
              {phoneError && (
                <p className="text-error mt-1 ms-2">Invalid Phone Number</p>
              )}
            </div>
          </div>
          <div className="row py-1 py-md-3 d-flex ">
            <hr className="customer-separator mt-1 mb-3" />
            <h5 className="font-weight-bold mx-2">Contacts</h5>
            <p className="mx-2">
              Contacts must have at least one of the following: Name, Email or
              Phone.
            </p>
            {contactsError && (
              <p className="text-error mt-1 ms-2">
                Contacts have invalid fields.
              </p>
            )}
            {[...contacts]
              .sort((a, b) => (a.id === 0 ? contacts.length : a.id - b.id))
              .map((contact, index) => (
                <div className="row py-1 py-md-3 d-flex" key={index}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="font-weight-bold mx-2">{`Contact #${
                        index + 1
                      }`}</h6>
                    </div>
                    <div>
                      <button
                        className="forgot-password-btn font-weight-bold lazuli-grey text-decoration-none hover-dark-blue d-flex flex-row align-items-center justify-content-center font-14"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteContact(index);
                        }}
                      >
                        <i className="icon-Delete font-18" />
                        {!isMobileOnly && <span className="">Remove</span>}
                      </button>
                    </div>
                  </div>
                  <hr className="contact-separator mt-1 mb-3 mx-3" />
                  <div className="col-11 col-md-5 mx-2">
                    <label
                      htmlFor="font-weight-bold lazuli-grey mt-3"
                      style={formLabelStyle}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className={`form-control lazuli-input-border-medium`}
                      id={`contactName${index}Id`}
                      aria-describedby="ContactNameHelp"
                      value={contact.name}
                      maxLength={250}
                      onChange={(e) => {
                        if (contactsError) setContactsError(false);
                        modifyContact(index, 'name', e.target.value);
                      }}
                      aria-label="contact-name-input"
                    />
                  </div>
                  <div
                    className={`col-11 col-md-5 mx-2 ${
                      isMobileOnly ? 'mt-2' : ''
                    }`}
                  >
                    <label
                      htmlFor="font-weight-bold lazuli-grey mt-3"
                      style={formLabelStyle}
                    >
                      Phone
                    </label>
                    <ReactInputMask
                      mask="(999) 999-9999"
                      type="tel"
                      className={`form-control lazuli-input-border-medium ${
                        contactsError && contactPhoneValidation(contact.phone)
                          ? 'error'
                          : ''
                      }`}
                      id={`contactPhone${index}Id`}
                      aria-describedby="ContactPhoneHelp"
                      value={contact.phone}
                      maxLength={250}
                      onChange={(e) => {
                        if (contactsError) setContactsError(false);
                        let phoneValue = e.target.value;
                        if (phoneValue.length > 14)
                          phoneValue = phoneValue.substring(0, 14);
                        modifyContact(index, 'phone', phoneValue);
                      }}
                      aria-label="contact-phone-input"
                    />
                  </div>
                  <div className="col-11 col-md-5 mx-2 my-2">
                    <label
                      htmlFor="font-weight-bold lazuli-grey mt-3"
                      style={formLabelStyle}
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className={`form-control lazuli-input-border-medium ${
                        contactsError && contactEmailValidation(contact.email)
                          ? 'error'
                          : ''
                      }`}
                      id={`contactEmail${index}Id`}
                      aria-describedby="ContactEmailHelp"
                      value={contact.email}
                      maxLength={250}
                      onChange={(e) => {
                        if (contactsError) setContactsError(false);
                        modifyContact(index, 'email', e.target.value);
                      }}
                      aria-label="contact-email-input"
                    />
                  </div>
                </div>
              ))}
            <div className="mb-3 form-check">
              <button
                className="btn btn-primary bg-lazuli-outline-light lazuli-grey-text d-flex flex-row align-items-center mt-3 font-12"
                onClick={(e) => {
                  e.preventDefault();
                  addNewContact();
                }}
                type="button"
              >
                + New Contact
              </button>
            </div>
          </div>
        </form>
        <div className="row my-2">
          <div className="col-12 justify-content-center row mx-0">
            <div className="col-5 col-md-4 col-lg-2 d-flex ">
              <button
                type="button"
                className="btn btn-primary btn-block pt-1 pb-2 lazuli-light-blue-border lazuli-button-medium"
                onClick={handleOpenModal}
              >
                <span className="font-weight-bold font-18">Cancel</span>
              </button>
            </div>
            <div className="col-5 col-md-4 col-lg-2 d-flex ">
              <button
                type="submit"
                className="btn btn-primary btn-block py-2 bg-lazuli-blue lazuli-button-medium"
                disabled={isEditDisabled}
                aria-label="edit-customer-submit-button"
                form="edit-customer-form"
                onClick={() => handleSubmit()}
              >
                <span className="font-weight-bold font-18">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={ACTION_CONFIRMATION_MODAL.EDIT_CUSTOMER_HEADER}
        secondaryText={ACTION_CONFIRMATION_MODAL.EDIT_CUSTOMER_SECONDARY}
        primaryAction={handleCloseModal}
        secondaryAction={goBack}
      />
    </>
  );
};
