import { History } from '../../types/History';
import { renolinkApi } from './renolink.api';

const historyEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    getHistoryForProject: build.query<
      {
        history: History[];
        total: number;
      },
      {
        projectId: string;
        sortBy: string;
        sortType: 'ASC' | 'DESC';
        search: string;
        pageSize: number;
        pageNumber: number;
      }
    >({
      query: ({ projectId, sortBy, sortType, search, pageSize, pageNumber }) =>
        `/history?projectId=${projectId}&sortBy=${sortBy}&sortType=${sortType}&pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}`,
      providesTags: ['ProjectHistory'],
    }),
    getHistoryForCSV: build.query<
      {
        history: History[];
        total: number;
      },
      string
    >({
      query: (projectId) =>
        `/history?projectId=${projectId}&sortBy=id&sortType=DESC&tab=all&search=`,
      providesTags: ['ProjectHistoryCSV'],
    }),
  }),
});

export const { useGetHistoryForProjectQuery, useGetHistoryForCSVQuery } =
  historyEndpoints;
