import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { AddNewButton } from '../../components/Buttons/AddNewButton/AddNewButton';
import Loader from '../../components/Loader/Loader';
import {
  useDeleteSubcontractorMutation,
  useGetAllSubcontractorsQuery,
  useGetSubcontractorsCountQuery,
  useGetSubcontractorsForCSVQuery,
  useUpdateSubcontractorActiveStatusMutation,
} from '../../redux/api/subcontractor.endpoints';
import { USER_ROLES } from '../../types/User';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  deleteToastData,
  Subcontractor,
  updateStatusToastData,
} from '../../types/Subcontractors';
import { ColumnProps, getFormattedDate } from '../../utils/table';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { ContextMenu } from '../../components/ContextMenu/ContextMenu';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { ENTITY_NAME } from '../../utils/enums';
import { handleEndpointResult } from '../../utils/api';
import {
  formatStringForCSV,
  SUBCONTRACTORS_CSV_METADATA,
} from '../../utils/csv';

export const SubcontractorTable = () => {
  const Table = lazy(() => import('../../components/Table/Table'));
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('active');
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('Activate');
  const [deleteSubcontractor, deleteSubcontractorResult] =
    useDeleteSubcontractorMutation();
  const [updateActiveStatus, updateActiveStatusResult] =
    useUpdateSubcontractorActiveStatusMutation();
  const [selectedSubcontractor, setSelectedSubcontractor] = useState<
    Subcontractor | undefined
  >(undefined);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('id');
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC');
  const {
    subcontractorsData,
    refetch,
    getAllSubcontractorsQueryError,
    isFetching,
  } = useGetAllSubcontractorsQuery(
    {
      pageSize: itemsPerPage,
      pageNumber: activePage,
      sortBy,
      sortType,
      tab: activeTab,
      searchQuery,
    },
    {
      selectFromResult: ({ data, error, isFetching }) => ({
        subcontractorsData: data ? data : [],
        getAllSubcontractorsQueryError: error as FetchBaseQueryError,
        isFetching,
      }),
    },
  );
  const { data: subcontractorsCount, refetch: refetchSubcontractorsCount } =
    useGetSubcontractorsCountQuery({ search: searchQuery });
  const { data: CSVData } = useGetSubcontractorsForCSVQuery();

  const { auth } = useContext(authContext);

  const [userId, setUserId] = useState('');
  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  useEffect(() => {
    refetch();
    refetchSubcontractorsCount();
  }, [sortBy, sortType, activePage]);

  useEffect(() => {
    if (
      authProfile &&
      authProfile.role !== USER_ROLES.ADMIN &&
      authProfile.role !== USER_ROLES.SUPER_ADMIN
    ) {
      navigate('/');
    }
  }, [authProfile]);

  useEffect(() => {
    setActivePage(1);
  }, [itemsPerPage, activeTab, searchQuery]);

  useEffect(() => {
    if (getAllSubcontractorsQueryError && getAllSubcontractorsQueryError.data) {
      const errorPayload = getAllSubcontractorsQueryError.data as {
        status: number;
        error: string;
      };
      toast.error(errorPayload.error, {
        toastId: 'get-all-subcontractors-querry-error',
      });
    }
  }, [getAllSubcontractorsQueryError]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    handleEndpointResult(deleteToastData, deleteSubcontractorResult, () => {
      setOpenModal(false);
    });
  }, [deleteSubcontractorResult]);

  useEffect(() => {
    updateStatusToastData.toastMsg = `Subcontractor ${updateActiveStatusResult.data?.message} successfully.`;
    handleEndpointResult(
      updateStatusToastData,
      updateActiveStatusResult,
      () => {
        setOpenModal(false);
      },
    );
  }, [updateActiveStatusResult]);

  const sortSubcontractors = (category: string) => {
    setSortBy((prev) => {
      if (prev === category) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return category;
      }
      setSortType('ASC');
      return category;
    });
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleUpdateActiveStatus = () =>
    selectedSubcontractor && updateActiveStatus(selectedSubcontractor.id);

  const handleDeleteSubcontractor = () =>
    selectedSubcontractor && deleteSubcontractor(selectedSubcontractor.id);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (action: string, subcontractor: Subcontractor) => {
    setSelectedSubcontractor(subcontractor);
    setModalAction(action);
    setOpenModal(true);
  };

  const getDataForCSV = () => {
    if (CSVData) {
      const dataForCSV = CSVData.map((sc) => {
        return {
          ...sc,
          market: sc.market?.name || '',
          company: formatStringForCSV(sc.company),
          status: sc.is_active ? 'Active' : 'Inactive',
          w9: sc.w9 ? 'Yes' : 'No',
          signed_agreement: sc.signed_agreement ? 'Yes' : 'No',
          wc_insurance_expiration: sc.wc_insurance_expiration
            ? getFormattedDate(sc.wc_insurance_expiration)
            : '-',
          gl_insurance_expiration: sc.gl_insurance_expiration
            ? getFormattedDate(sc.gl_insurance_expiration)
            : '-',
          trades: getTrades(sc.trades),
        };
      });
      return { data: dataForCSV, ...SUBCONTRACTORS_CSV_METADATA };
    }
    return { data: [], headers: [], filename: 'null.csv' };
  };

  const getHeaderText = () =>
    selectedSubcontractor
      ? `${modalAction} Subcontractor: ${selectedSubcontractor.id}.`
      : '';

  const getSecondaryText = () =>
    selectedSubcontractor
      ? `Are you sure you want to ${modalAction.toLowerCase()} Subcontractor ${
          selectedSubcontractor.id
        }?`
      : '';

  const tabs = [
    {
      title: `Active (${subcontractorsCount?.active || 0})`,
      attribute: 'active',
    },
    {
      title: `Inactive (${subcontractorsCount?.inactive || 0})`,
      attribute: 'inactive',
    },
    {
      title: `All (${subcontractorsCount?.total || 0})`,
      attribute: 'all',
    },
  ];

  const columns: ColumnProps[] = [
    { title: 'ID', attribute: 'id', sortValue: 'id', width: '4rem' },
    {
      title: 'Company Name',
      attribute: 'companyName',
      sortValue: 'company',
      maxWidth: '6rem',
    },
    {
      title: 'Primary Market',
      attribute: 'primaryMarket',
      sortValue: 'market',
      maxWidth: '6rem',
    },
    {
      title: 'W9',
      attribute: 'w9',
      sortValue: 'not_sortable',
      textCenter: isMobileOnly,
      minWidth: '4rem',
    },
    {
      title: 'GL Insurance Exp. Date',
      attribute: 'glInsuranceExpDate',
      sortValue: 'gl_insurance_expiration',
      minWidth: isMobile ? '12rem' : '8rem',
      width: isMobile ? '' : '12rem',
      textCenter: true,
    },
    {
      title: 'WC Insurance Exp. Date',
      attribute: 'wcInsuranceExpDate',
      sortValue: 'wc_insurance_expiration',
      minWidth: isMobile ? '12rem' : '8rem',
      width: isMobile ? '' : '12rem',
      textCenter: true,
    },
    {
      title: 'Signed Agreement',
      attribute: 'signedAgreement',
      sortValue: 'not_sortable',
      textCenter: isMobileOnly,
      width: isMobile ? '10rem' : '6rem',
    },
    {
      title: 'Trades',
      attribute: 'trades',
      sortValue: 'trades',
      maxWidth: '10rem',
      textCenter: true,
    },
  ];

  const renderExpirationColumn = (date: string, hasExpired: boolean) => {
    return (
      <div className="d-flex align-items-center">
        <div className="col-3">
          <i
            className={`icon-Alert-Circle font-32 ${
              hasExpired ? 'alert-active' : 'alert-inactive'
            }`}
          />
        </div>
        <div className="col-8 justify-content-start text-start">{date}</div>
      </div>
    );
  };

  const renderContextMenu = (userId: number) => {
    const contextMenuProps = {
      entityData: subcontractorsData,
      entityName: ENTITY_NAME.SUBCONTRACTOR,
      currentId: userId,
      authProfile,
      handleOpenModal,
    };
    return <ContextMenu {...contextMenuProps} />;
  };

  const transformDataForTable = () =>
    subcontractorsData.map(
      (subcontractor: Subcontractor) =>
        ({
          id: subcontractor.id.toString(),
          companyName: subcontractor.company,
          primaryMarket: subcontractor.market?.name || '',
          w9: subcontractor.w9 ? (
            <i className="icon-Check font-26 mobile-check-hack" />
          ) : (
            <span className="font-26 text-center w9-hyphen">-</span>
          ),
          wcInsuranceExpDate: subcontractor.wc_insurance_expiration ? (
            renderExpirationColumn(
              getFormattedDate(subcontractor.wc_insurance_expiration),
              subcontractor.wc_is_expired,
            )
          ) : (
            <div className="d-flex align-items-center">
              <div className="col-3">
                <i className="icon-Alert-Circle font-32 alert-active" />
              </div>
              <div className="col-8 justify-content-start text-start font-26 text-center date-hyphen pe-5">
                -
              </div>
            </div>
          ),
          glInsuranceExpDate: subcontractor.gl_insurance_expiration ? (
            renderExpirationColumn(
              getFormattedDate(subcontractor.gl_insurance_expiration),
              subcontractor.gl_is_expired,
            )
          ) : (
            <div className="d-flex align-items-center">
              <div className="col-3">
                <i className="icon-Alert-Circle font-32 alert-active" />
              </div>
              <div className="col-8 justify-content-start text-start font-26 text-center date-hyphen pe-5">
                -
              </div>
            </div>
          ),
          signedAgreement: subcontractor.signed_agreement ? (
            <i className="icon-Check font-26 signed mobile-check-hack" />
          ) : (
            <span className="font-26 text-center px-3 hyphen">-</span>
          ),
          trades: getTrades(subcontractor.trades),
          is_active: subcontractor.is_active ? 'Active' : 'Inactive',
        } as { [key: string]: string | JSX.Element }),
    );

  const getTotalCount = () => {
    switch (activeTab) {
      case 'active':
        return subcontractorsCount?.active || 0;
      case 'inactive':
        return subcontractorsCount?.inactive || 0;
      case 'all':
        return subcontractorsCount?.total || 0;
      default:
        return 0;
    }
  };

  const getTrades = (trades: string[]) => {
    if (trades.includes('All Trades')) {
      return 'All Trades';
    }
    return trades.join(', ');
  };

  if (activeTab === 'all')
    columns.push({
      title: 'Status',
      attribute: 'is_active',
      sortValue: 'is_active',
    });

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Table
          tableName="subcontractors"
          mainActionButton={
            <AddNewButton
              label={`${isMobileOnly ? '+' : '+ Add Subcontractor'}`}
            />
          }
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          columns={columns}
          data={transformDataForTable()}
          totalData={getTotalCount()}
          isFetchingData={isFetching}
          sortFunction={sortSubcontractors}
          sortBy={sortBy}
          sortType={sortType}
          activePage={activePage}
          setActivePage={setActivePage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          renderContextMenu={renderContextMenu}
          handleSearch={handleSearch}
          csvData={getDataForCSV()}
        />
      </Suspense>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={
          modalAction === 'Delete'
            ? handleDeleteSubcontractor
            : handleUpdateActiveStatus
        }
      />
    </>
  );
};
