import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export const UploadFilesBox = ({ files, setFiles }: Props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const renderFiles = () => {
    if (files.length > 0) {
      return files.map((file) => (
        <li key={file.name}>
          {file.name} - {file.size} bytes
        </li>
      ));
    }
  };

  useEffect(() => {
    setFiles([...files, ...acceptedFiles]);
  }, [acceptedFiles]);

  return (
    <section className="container">
      <aside>
        <ul>{renderFiles()}</ul>
      </aside>
      <div
        {...getRootProps({
          className: 'dropzone d-flex flex-column justify-content-center',
        })}
      >
        <input {...getInputProps()} />
        <i className="icon-Upload font-40 text-center justify-content-center" />
        <p className="text-center justify-content-center">
          Click here or drag and drop a file to upload
        </p>
      </div>
    </section>
  );
};
