import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router';
import { Market } from '../../types/Markets';
import {
  EditSubcontractorDto,
  Subcontractor,
  Trades,
} from '../../types/Subcontractors';
import { User } from '../../types/User';
import { ActionConfirmationModal } from '../Modals/ActionConfirmationModal/ActionConfirmationModal';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { ACTION_CONFIRMATION_MODAL } from '../../utils/enums';
import { isMobileOnly } from 'react-device-detect';
import { validateEmail } from '../../utils/validations';

const formLabelStyle = {
  fontWeight: '500',
  color: '#394a64',
  fontSize: '1.1rem',
};

interface Props {
  subcontractorData: Subcontractor | undefined;
  handleEditSubcontractor: (editSubcontractorDto: EditSubcontractorDto) => void;
  markets: Market[];
  isLoading: boolean;
  authProfile: User;
}

export const EditSubcontractorForm = ({
  handleEditSubcontractor,
  markets,
  isLoading,
  subcontractorData,
}: Props) => {
  const navigate = useNavigate();
  const [subcontractorId, setSubcontractorId] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [workerExpirationDate, setWorkerExpirationDate] = useState<Date>();
  const [genExpirationDate, setGenExpirationDate] = useState<Date>();
  const [marketData, setMarketData] = useState<Market>({
    id: 0,
    name: '',
    updated_at: new Date(),
    created_at: new Date(),
    city: '',
    state: '',
    is_active: false,
  });
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [workerCompInsurance, setWorkerCompInsurance] = useState(false);
  const [genLiabilityInsurance, setGenLiabilityInsurance] = useState(false);
  const [signedSubcontractorAgreement, setSignedSubcontractorAgreement] =
    useState(false);
  const [w9, setW9] = useState(false);
  const [trades, setTrades] = useState<string[]>([]);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (subcontractorData) {
      setSubcontractorId(subcontractorData.id);
      setFirstName(subcontractorData.first_name);
      setLastName(subcontractorData.last_name);
      setEmail(subcontractorData.email);
      setPhoneNumber(subcontractorData.phone);
      setCompanyName(subcontractorData.company);
      subcontractorData.wc_insurance_expiration &&
        setWorkerExpirationDate(
          new Date(subcontractorData.wc_insurance_expiration),
        );
      subcontractorData.gl_insurance_expiration &&
        setGenExpirationDate(
          new Date(subcontractorData.gl_insurance_expiration),
        );
      setWorkerCompInsurance(subcontractorData.wc_insurance);
      setGenLiabilityInsurance(subcontractorData.gl_insurance);
      setSignedSubcontractorAgreement(subcontractorData.signed_agreement);
      setW9(subcontractorData.w9);
      setTrades(subcontractorData.trades);
      setNotes(subcontractorData.notes);
      if (subcontractorData.market) {
        setMarketData(subcontractorData.market);
      }
    }
  }, [subcontractorData]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    const phoneHasErrors = phoneNumber.includes('_');
    const firstNameHasErrors = !firstName.trim();
    const lastNameHasErrors = !lastName.trim();
    const companyHasErrors = !companyName.trim();
    const emailHasError = email ? !validateEmail(email) : false;
    if (
      phoneHasErrors ||
      firstNameHasErrors ||
      lastNameHasErrors ||
      companyHasErrors ||
      emailHasError
    ) {
      setPhoneError(phoneHasErrors);
      setFirstNameError(firstNameHasErrors);
      firstNameHasErrors && setFirstName('');
      setLastNameError(lastNameHasErrors);
      lastNameHasErrors && setLastName('');
      setCompanyError(companyHasErrors);
      setEmailError(emailHasError);
      companyHasErrors && setCompanyName('');
    } else {
      const payload = {
        id: subcontractorId,
        firstName,
        companyName,
        email,
        lastName,
        phoneNumber,
        wcInsurance: workerCompInsurance,
        wcInsuranceExpiration: workerExpirationDate,
        glInsurance: genLiabilityInsurance,
        glInsuranceExpiration: genExpirationDate,
        w9,
        signedAgreement: signedSubcontractorAgreement,
        trades,
        notes,
        market: marketData,
      };

      handleEditSubcontractor(payload);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSelectTrade = (trade: string) => {
    if (trade === 'All Trades') {
      if (trades.length === 7) {
        setTrades([]);
      } else {
        setTrades([...Trades]);
      }
    } else {
      const currTrade = trades.findIndex((t) => t === trade);
      let tempTrades = [...trades];
      if (tempTrades.includes('All Trades'))
        tempTrades = tempTrades.filter((t) => t !== 'All Trades');
      if (currTrade >= 0) {
        setTrades(tempTrades.filter((t) => t !== trade));
      } else {
        setTrades([...tempTrades, trade]);
      }
    }
  };

  const renderTrades = () =>
    Trades.map((trade) => {
      const restrictWidth =
        trade === 'Handyman/General Maintenance' && isMobileOnly;
      return (
        <div key={trade} className="col-auto mx-3 my-3">
          <input
            className="form-check-input lazuli-checkbox"
            type="checkbox"
            id={`${trade}-check`}
            checked={!!trades.find((t) => t === trade)}
            onClick={() => {
              handleSelectTrade(trade);
            }}
            onChange={(e) => e}
          />
          <label
            className="form-check-label vertical-align-sub"
            style={{ width: restrictWidth ? '75%' : '' }}
            htmlFor={`${trade}-check`}
          >
            {trade}
          </label>
        </div>
      );
    });

  const isEditDisabled =
    phoneError ||
    firstNameError ||
    lastNameError ||
    companyError ||
    emailError ||
    isLoading ||
    !companyName ||
    !firstName ||
    !lastName ||
    !phoneNumber;

  return (
    <>
      <div className="flex-grow-1 form-container">
        <h2
          aria-label="edit-subcontractor-title"
          className="text-center font-weight-bold"
        >
          Edit Subcontractor
        </h2>
        <form id="Edit-subcontractor-form px-5" onSubmit={handleSubmit}>
          <div className="row py-3 d-flex justify-content-center">
            <div className="form-group col-11 col-md-5 mx-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Company Name<span className="required-tag">*</span>
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium ${
                  companyError ? 'error' : ''
                }`}
                id="CompanyNameId"
                aria-describedby="CompanyNameHelp"
                value={companyName}
                maxLength={250}
                onChange={(e) => {
                  if (companyError) setCompanyError(false);
                  const companyValue = e.target.value;
                  setCompanyName(companyValue);
                }}
                aria-label="company-name-input"
              />
              {companyError && (
                <p className="text-error mt-1 ms-2">
                  Company name can not be empty
                </p>
              )}
            </div>
            <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Primary Market
              </label>
              <select
                className="form-select lazuli-select"
                style={{ height: '2.5rem' }}
                value={marketData.id}
                onChange={(e) => {
                  const market = markets.find(
                    (market) => market.id.toString() === e.target.value,
                  );
                  if (market) {
                    setMarketData(market);
                  }
                }}
              >
                <option value={0} hidden={true}>
                  Select a Market:
                </option>
                {markets.map((market) => (
                  <option value={market.id} key={market.id}>
                    {market.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row py-3 d-flex justify-content-center">
            <h4
              className="col-10 p-0 font-weight-bold mb-4 text-center"
              aria-label="Edit-subcontractor-header"
            >
              Primary Contact
            </h4>

            <div className="form-group col-11 col-md-5 mx-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                First Name<span className="required-tag">*</span>
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium ${
                  firstNameError ? 'error' : ''
                }`}
                id="firstNameId"
                aria-describedby="FirstNameHelp"
                value={firstName}
                maxLength={250}
                onChange={(e) => {
                  if (firstNameError) setFirstNameError(false);
                  const firstNameValue = e.target.value;
                  setFirstName(firstNameValue);
                }}
                aria-label="first-name-input"
              />
              {firstNameError && (
                <p className="text-error mt-1 ms-2">
                  First name can not be empty
                </p>
              )}
            </div>
            <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Last Name<span className="required-tag">*</span>
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium ${
                  lastNameError ? 'error' : ''
                }`}
                id="lastNameId"
                aria-describedby="LastNameHelp"
                value={lastName}
                maxLength={250}
                onChange={(e) => {
                  if (lastNameError) setLastNameError(false);
                  const lastNameValue = e.target.value;
                  setLastName(lastNameValue);
                }}
                aria-label="last-name-input"
              />
              {lastNameError && (
                <p className="text-error mt-1 ms-2">
                  Last name can not be empty
                </p>
              )}
            </div>
          </div>

          <div className="row py-1 py-md-3 d-flex justify-content-center">
            <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Phone<span className="required-tag">*</span>
              </label>
              <ReactInputMask
                mask="(999) 999-9999"
                type="tel"
                className={`form-control lazuli-input-border-medium ${
                  phoneError ? 'error' : ''
                }`}
                id="phoneId"
                aria-describedby="PhoneNumberHelp"
                value={phoneNumber}
                onChange={(e) => {
                  if (phoneError) setPhoneError(false);
                  let phoneValue = e.target.value;
                  if (phoneValue.length > 14)
                    phoneValue = phoneValue.substring(0, 14);
                  setPhoneNumber(phoneValue);
                }}
                aria-label="phone-number-input"
              />
              {phoneError && (
                <p className="text-error mt-1 ms-2">Invalid Phone Number</p>
              )}
            </div>
            <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
              >
                Email
              </label>
              <input
                type="text"
                className={`form-control lazuli-input-border-medium ${
                  emailError ? 'error' : ''
                }`}
                id="EmailId"
                aria-describedby="EmailHelp"
                value={email}
                maxLength={250}
                onChange={(e) => {
                  if (emailError) setEmailError(false);
                  const emailValue = e.target.value;
                  setEmail(emailValue);
                }}
                aria-label="email-input"
              />
              {emailError && (
                <p className="text-error mt-1 ms-2">Invalid Email</p>
              )}
            </div>
          </div>
          <div className="row py-3 d-flex justify-content-center">
            <h4
              className="col-10 p-0 font-weight-bold mb-4 text-center"
              aria-label="Edit-subcontractor-header"
            >
              Required Documents
            </h4>
            <div className="row py-1 py-md-3 d-flex justify-content-center">
              <div className="form-group col-11 col-md-5 mx-2 ps-md-0">
                <div className="mb-4">
                  <input
                    className="form-check-input lazuli-checkbox"
                    type="checkbox"
                    id="worker-comp-check"
                    checked={workerCompInsurance}
                    onClick={() => setWorkerCompInsurance((prev) => !prev)}
                    onChange={(e) => e}
                  />
                  <label
                    className="form-check-label vertical-align-sub"
                    htmlFor="worker-comp-check"
                  >
                    Worker&apos;s Comp insurance
                  </label>
                </div>
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                >
                  Worker&apos;s Comp insurance Exp. Date
                </label>
                <div className="position-relative">
                  <DatePicker
                    className="form-control lazuli-input-border-medium"
                    selected={workerExpirationDate}
                    onChange={(date: Date) => setWorkerExpirationDate(date)}
                  />
                  <i className="icon-Calendar date-input-icon" />
                </div>
              </div>

              <div className="form-group col-11 col-md-5 mx-2 ">
                <div className="mb-4 mt-4 mt-md-0">
                  <input
                    className="form-check-input lazuli-checkbox"
                    type="checkbox"
                    id="gen-liability-check"
                    checked={genLiabilityInsurance}
                    onClick={() => setGenLiabilityInsurance((prev) => !prev)}
                    onChange={(e) => e}
                  />
                  <label
                    className="form-check-label vertical-align-sub"
                    htmlFor="gen-liability-check"
                  >
                    Gen Liability Insurance
                  </label>
                </div>
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                >
                  Gen Liability Insurance Exp. Date
                </label>
                <div className="position-relative">
                  <DatePicker
                    className="form-control lazuli-input-border-medium"
                    selected={genExpirationDate}
                    onChange={(date: Date) => setGenExpirationDate(date)}
                  />
                  <i className="icon-Calendar date-input-icon" />
                </div>
              </div>
            </div>
            <div className="row py-1 py-md-3 d-flex justify-content-center">
              <div className="form-group col-11 col-md-5 mx-2 ps-md-0 my-3 my-md-0">
                <input
                  className="form-check-input lazuli-checkbox"
                  type="checkbox"
                  id="signed-agreement-check"
                  checked={signedSubcontractorAgreement}
                  onClick={() =>
                    setSignedSubcontractorAgreement((prev) => !prev)
                  }
                  onChange={(e) => e}
                />
                <label
                  className="form-check-label vertical-align-sub"
                  style={{ width: isMobileOnly ? '75%' : '' }}
                  htmlFor="signed-agreement-check"
                >
                  Signed Subcontractor Agreement
                </label>
              </div>

              <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
                <div className="form-check">
                  <input
                    className="form-check-input lazuli-checkbox"
                    type="checkbox"
                    id="w9-check"
                    checked={w9}
                    onClick={() => setW9((prev) => !prev)}
                    onChange={(e) => e}
                  />
                  <label
                    className="form-check-label vertical-align-sub"
                    htmlFor="w9-check"
                  >
                    W9
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-3 d-flex justify-content-center">
            <h4
              className="col-10 p-0 font-weight-bold text-center"
              aria-label="Edit-subcontractor-header"
            >
              Trades
            </h4>
            <div className="row py-1 py-md-3 d-flex justify-content-center">
              <div className="col-11 ms-3">
                <div className="row py-1 py-md-3">{renderTrades()}</div>
              </div>
            </div>
            <div className="row py-1 py-md-3 d-flex justify-content-center">
              <div className="col-11 ms-3 notes-container">
                <label
                  className="font-weight-bold lazuli-grey"
                  htmlFor="notes"
                  style={formLabelStyle}
                >
                  Notes
                </label>
                <textarea
                  className={`form-control lazuli-input-border-medium`}
                  id="notes"
                  aria-describedby="NotesHelp"
                  aria-label="notes-input"
                  maxLength={2000}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  style={{ minHeight: '180px' }}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="row my-2">
          <div className="col-12 justify-content-center row mx-0">
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-block pt-1 pb-2 lazuli-light-blue-border lazuli-button-medium"
                onClick={handleOpenModal}
              >
                <span className="font-weight-bold font-18">Cancel</span>
              </button>
            </div>
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-primary btn-block py-2 bg-lazuli-blue lazuli-button-medium"
                disabled={isEditDisabled}
                aria-label="Edit-subcontractor-submit-button"
                form="Edit-subcontractor-form"
                onClick={() => handleSubmit()}
              >
                <span className="font-weight-bold font-18">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={ACTION_CONFIRMATION_MODAL.EDIT_SUBCONTRACTOR_HEADER}
        secondaryText={ACTION_CONFIRMATION_MODAL.EDIT_SUBCONTRACTOR_SECONDARY}
        primaryAction={handleCloseModal}
        secondaryAction={goBack}
      />
    </>
  );
};
