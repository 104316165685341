import { Dispatch, SetStateAction } from 'react';
import { useGetUsersByRoleQuery } from '../../redux/api/user.endpoints';
import { USER_ROLES } from '../../types/User';

interface Props {
  label: React.ReactElement;
  setSPMData: Dispatch<SetStateAction<{ id: string; name: string }>>;
  value: string;
  disabled?: boolean;
  userId?: number;
}

const SPMDropdown = ({
  label,
  setSPMData,
  value,
  disabled = false,
  userId = 0,
}: Props) => {
  const { seniorProjectManagers } = useGetUsersByRoleQuery(
    USER_ROLES.SENIOR_PROJECT_MANAGER,
    {
      selectFromResult: ({ data }) => ({
        seniorProjectManagers: data ? data : [],
      }),
    },
  );

  return (
    <>
      {label}
      <select
        disabled={seniorProjectManagers.length === 0 || disabled}
        className="form-select lazuli-select"
        onChange={(e) => {
          const spmVal = e.target.value;
          const spm =
            spmVal === 'none'
              ? {
                  id: 'none',
                  name: 'Not Assigned',
                }
              : seniorProjectManagers.find(
                  (spm) => spm.id.toString() === spmVal,
                );
          setSPMData({
            id: spm?.id.toString() || '',
            name: spm?.name || '',
          });
        }}
        value={value}
      >
        <option value={''} hidden={true}>
          Select a SPM
        </option>
        <option value={'none'}>Not Assigned</option>
        {seniorProjectManagers
          .filter((spm) => spm.id !== userId)
          .map((spm) => (
            <option value={spm.id} key={spm.id}>
              {spm.name}
            </option>
          ))}
      </select>
    </>
  );
};

export default SPMDropdown;
