import React, { useContext, useEffect, useState } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { DetailsTextFields } from '../../components/DetailsTextFields/DetailsTextFields';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';

import {
  useDeleteCustomerMutation,
  useGetCustomerByIdQuery,
  useUpdateCustomerActiveStatusMutation,
} from '../../redux/api/customer.endpoints';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { deleteToastData, updateStatusToastData } from '../../types/Customer';
import { USER_ROLES } from '../../types/User';
import { handleEndpointResult } from '../../utils/api';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';

export const CustomerDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const [modalAction, setModalAction] = useState('Activate');
  const [openModal, setOpenModal] = useState(false);

  const { customerData, refetch } = useGetCustomerByIdQuery(
    searchParams.get('id') || '',
    {
      selectFromResult: ({ data }) => ({
        customerData: data ? data : undefined,
      }),
    },
  );

  const [deleteCustomer, deleteCustomerResult] = useDeleteCustomerMutation();

  const [updateActiveStatus, updateActiveStatusResult] =
    useUpdateCustomerActiveStatusMutation();

  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  const handleOpenModal = (action: string) => {
    setModalAction(action);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateActiveStatus = () =>
    customerData && updateActiveStatus(customerData.id);

  const handleDeletecustomer = () =>
    customerData && deleteCustomer(customerData.id);

  const getHeaderText = () => {
    return customerData
      ? `${modalAction} Customer: ${customerData.company_name}.`
      : '';
  };

  const getSecondaryText = () => {
    return customerData
      ? `Are you sure you want to ${modalAction.toLowerCase()} Customer ${
          customerData.company_name
        }?`
      : '';
  };

  useEffect(() => {
    handleEndpointResult(deleteToastData, deleteCustomerResult, () => {
      setOpenModal(false);
      navigate(`/admin/customers`);
    });
  }, [deleteCustomerResult]);

  useEffect(() => {
    updateStatusToastData.toastMsg = `Customer ${updateActiveStatusResult.data?.message} successfully.`;
    handleEndpointResult(
      updateStatusToastData,
      updateActiveStatusResult,
      () => {
        setOpenModal(false);
        refetch();
      },
    );
  }, [updateActiveStatusResult]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
    }
  }, [auth]);

  useEffect(() => {
    refetch();
  }, []);

  const renderActionButtons = () => {
    if (!customerData || !authProfile) return <></>;
    const canModify =
      authProfile.role === USER_ROLES.SUPER_ADMIN ||
      authProfile.role === USER_ROLES.ADMIN;
    return (
      <div className="d-flex row flex-grow-slightly header-action-buttons">
        {canModify && (
          <>
            <button
              className="btn btn-primary col-md-4 col-3 ms-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
              onClick={() =>
                customerData &&
                handleOpenModal(
                  customerData.is_active ? 'Deactivate' : 'Activate',
                )
              }
            >
              <i className="icon-Deactivate font-24" />

              {!isMobileOnly && (
                <span className="">
                  {customerData && customerData.is_active
                    ? 'Deactivate'
                    : 'Activate'}
                </span>
              )}
            </button>
            <button
              className="btn btn-primary col-md-4 col-3 ms-2 me-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14 "
              onClick={() => handleOpenModal('Delete')}
            >
              <i className="icon-Delete font-24" />
              {!isMobileOnly && <span className="">Delete</span>}
            </button>

            <button
              className={`btn btn-primary col-md-3 col-3  bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex align-items-center justify-content-center  px-1`}
              onClick={() => {
                localStorage.setItem(
                  'renolink-return-to',
                  `${location.pathname}${location.search}`,
                );
                navigate(`/admin/customers/edit?id=${customerData?.id || 0}`);
              }}
            >
              <i className="icon-Edit font-24" />
              {!isMobileOnly && <span className="">Edit</span>}
            </button>
          </>
        )}
      </div>
    );
  };

  const fields = [
    { label: 'Company Name', value: customerData?.company_name || '-' },
    { label: 'Address 1', value: customerData?.primary_address || '-' },
    { label: 'Address 2', value: customerData?.secondary_address || '-' },
    { label: 'City', value: customerData?.city || '-' },
    { label: 'State', value: customerData?.state || '-' },
    { label: 'Zip', value: customerData?.zip || '-' },
    {
      label: 'Main Email',
      value: customerData?.email || '-',
    },
    { label: 'Main Phone', value: customerData?.phone || '-' },
  ];

  return (
    <>
      <Helmet>
        <title>Renolink - Customer Details</title>
      </Helmet>
      <HeaderTab title={'Details'} renderActionButtons={renderActionButtons} />
      <div className="my-3 rounded bg-white-and-shadowed d-flex p-4 h-100 scrollable">
        <div className="container px-2 mt-3 col-12 col-lg-10">
          <main>
            <div
              className={`font-semibold d-flex row text-center text-wrap-anywhere ${
                isMobileOnly ? 'font-18' : 'font-24'
              }`}
            >
              <p>Customer Information</p>
            </div>
            <div>
              <DetailsTextFields fields={fields} />
              <div
                className={`d-block ${`admin-header-divider-notes${
                  isTablet ? '-tablet' : ''
                }`} my-2`}
              />
              <div className="mt-3">
                <p className="font-weight-bold font-16">Contacts</p>
                {customerData && customerData.contacts.length > 0 ? (
                  <div>
                    {[...customerData.contacts]
                      .sort((a, b) => a.id - b.id)
                      .map((contact, index) => {
                        const contactFields = [
                          {
                            label: 'Name',
                            value: contact.name || '-',
                          },
                          {
                            label: 'Phone',
                            value: contact.phone || '-',
                          },
                          {
                            label: 'Email',
                            value: contact.email || '-',
                          },
                        ];
                        return (
                          <div key={`contact-${index}`}>
                            <p className="font-weight-bolder font-12">
                              Contact #{index + 1}
                            </p>
                            <DetailsTextFields fields={contactFields} />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <p className="font-weight-bolder font-14">
                    No contacts added
                  </p>
                )}
              </div>
              <div
                className={`d-block ${`admin-header-divider-notes${
                  isTablet ? '-tablet' : ''
                }`} my-2`}
              />
              <div style={{ height: '5rem' }}> </div>
            </div>
          </main>
        </div>
      </div>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={() =>
          modalAction === 'Delete'
            ? handleDeletecustomer()
            : handleUpdateActiveStatus()
        }
      />
    </>
  );
};
