export interface Document {
  id: string;
  name: string;
  selected: boolean;
  subcontractor_id: string;
  owner_type: string;
  file_type: string;
  created_at: Date;
  presignedUrl: string;
}

export interface CreateDocumentDto {
  subcontractor_id: number;
  owner_type: string;
  file_type: string;
  file: File;
}

export const uploadToastData = {
  toastMsg: 'Document uploaded successfully.',
  toastErrorMsg: 'An error occurred while uploading the document.',
  toastId: 'upload-document-success',
  toastErrorId: 'upload-document-error',
};

export const deleteToastData = {
  toastMsg: 'Document(s) deleted successfully.',
  toastErrorMsg: 'An error occurred while deleting the document(s).',
  toastId: 'delete-document-success',
  toastErrorId: 'delete-document-error',
};

export const uploadImageToastData = {
  toastMsg: 'Image uploaded successfully.',
  toastErrorMsg: 'An error occurred while uploading the image.',
  toastId: 'upload-image-success',
  toastErrorId: 'upload-image-error',
};

export const deleteImageToastData = {
  toastMsg: 'Image(s) deleted successfully.',
  toastErrorMsg: 'An error occurred while deleting the image(s).',
  toastId: 'delete-image-success',
  toastErrorId: 'delete-image-error',
};
