import React, { Dispatch, useContext, useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import {
  useCreateExpenseMutation,
  useDeleteExpenseMutation,
  useEditExpenseMutation,
  useGetExpensesForProjectQuery,
} from '../../redux/api/expense.endpoints';
import { ENTITY_NAME } from '../../utils/enums';
import { ContextMenu } from '../../components/ContextMenu/ContextMenu';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { Expense } from '../../types/Expense';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { ProjectExpenseCreate } from '../../components/Expenses/ProjectExpenseCreate';

interface ProjectExpensesProps {
  projectId: string;
  isActive: boolean;
  setActiveTab?: Dispatch<string>;
}

export const ProjectExpenses = ({ projectId }: ProjectExpensesProps) => {
  const [virtualTab, setVirtualTab] = useState<'table' | 'edit' | 'create'>(
    'table',
  );
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('DESC');
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedExpense, setSelectedExpense] = useState<false | Expense>(
    false,
  );
  const { auth } = useContext(authContext);

  const [userId, setUserId] = useState('');
  //modal
  const [openModal, setOpenModal] = useState(false);
  //
  const [createExpense, createExpenseResult] = useCreateExpenseMutation();
  const [editExpense, editExpenseResult] = useEditExpenseMutation();
  const [deleteExpense, deleteExpenseResult] = useDeleteExpenseMutation();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  const { data, isFetching, refetch } = useGetExpensesForProjectQuery({
    projectId,
    pageSize,
    pageNumber: pageNumber - 1,
    sortType,
    sortBy,
  });

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
    }
  }, [auth]);

  useEffect(() => {
    if (virtualTab === 'table') {
      refetch();
    }
  }, [
    sortBy,
    sortType,
    virtualTab,
    createExpenseResult,
    editExpenseResult,
    deleteExpenseResult,
  ]);

  const columns = [
    {
      title: 'Date',
      attribute: 'created_at',
      //sortValue: 'date',
      maxWidth: '4rem',
      minWidth: '4rem',
    },
    {
      title: 'Type',
      attribute: 'type',
      sortValue: 'type',
      maxWidth: '4rem',
      mixWidth: '4rem',
    },
    {
      title: 'Total',
      attribute: 'total',
      sortValue: 'total',
      maxWidth: '4rem',
      mixWidth: '4rem',
    },
    {
      title: 'Description',
      attribute: 'description',
      sortValue: 'description',
      maxWidth: '30rem',
      minWidth: '10rem',
      hasTooltip: true,
    },
  ];

  const handleSort = (query: string) => {
    setSortBy((prev) => {
      if (prev === query) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return query;
      }
      setSortType('ASC');
      return query;
    });
  };

  const handleSearch = (query: string) => {
    console.log('search;', query);
  };

  const transformExpenseDataForTable = () => {
    return data
      ? data.expenses.map(({ created_at, total, ...el }: Expense) => ({
          ...el,
          total: Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(total ? parseFloat(total) : 0),
          created_at: new Date(created_at).toLocaleDateString('en-US', {
            month: 'numeric',
            day: '2-digit',
            year: 'numeric',
          }),
        }))
      : [];
  };

  const handleOpenModal = (action: string, expense: Expense) => {
    console.log({ action });
    setSelectedExpense(expense);
    switch (action) {
      case 'Delete':
        setOpenModal(true);
        break;
      case 'Edit':
        setVirtualTab('edit');
        break;
    }
  };

  const handleCreate = () => {
    setVirtualTab('create');
  };

  const handleDelete = () => {
    if (selectedExpense) {
      deleteExpense({
        id: selectedExpense.id,
        userId: authProfile?.id || 0,
      });
    }
    handleCloseModal();
    refetch();
  };

  const renderContextMenu = (expenseId: number) => {
    const contextMenuProps = {
      entityData: data?.expenses as Expense[],
      entityName: ENTITY_NAME.EXPENSE,
      currentId: expenseId,
      authProfile,
      handleOpenModal,
    };
    return <ContextMenu {...contextMenuProps} />;
  };

  const renderActionButton = (): JSX.Element => {
    return (
      <button
        onClick={handleCreate}
        className="btn btn-primary ms-3 bg-lazuli-outline"
      >
        Add new
      </button>
    );
  };

  return (
    <>
      {virtualTab === 'table' && (
        <Table
          tableName="expenses"
          tabs={[]}
          columns={columns}
          data={transformExpenseDataForTable()}
          totalData={data?.total || 0}
          isFetchingData={isFetching}
          mainActionButton={<></>}
          sortFunction={handleSort}
          sortBy={sortBy}
          sortType={sortType}
          itemsPerPage={pageSize}
          setItemsPerPage={setPageSize}
          setActivePage={setPageNumber}
          renderContextMenu={renderContextMenu}
          //csvData={getDataForCSV()}
          fullSize
          readOnly
          activePage={pageNumber}
          handleSearch={handleSearch}
          hideSearchBar={true}
          actionButton={renderActionButton()}
        />
      )}
      {virtualTab === 'edit' && (
        <ProjectExpenseCreate
          expense={selectedExpense}
          virtualTabSetter={setVirtualTab}
          actionType={'edit'}
          userId={authProfile?.id || 0}
          createExpense={createExpense}
          editExpense={editExpense}
        />
      )}
      {virtualTab === 'create' && (
        <ProjectExpenseCreate
          projectId={projectId}
          virtualTabSetter={setVirtualTab}
          actionType={'create'}
          userId={authProfile?.id || 0}
          createExpense={createExpense}
          editExpense={editExpense}
        />
      )}
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText="Delete expense"
        secondaryText="Are you sure you want to delete this Expense?"
        primaryAction={handleCloseModal}
        secondaryAction={handleDelete}
      />
    </>
  );
};
