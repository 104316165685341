import React, { Suspense, lazy, useEffect, useContext, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Home from './containers/Home/Home';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Loader from './components/Loader/Loader';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import UserDetails from './containers/Users/UserDetails';
import { ConfirmationScreen } from './containers/ResetPassword/ConfirmationScreen/ConfirmationScreen';
import { Auth } from 'aws-amplify';
import { PasswordRecovery } from './containers/ResetPassword/PasswordRecovery/PasswordRecovery';
import { ConfirmResetPassword } from './containers/ResetPassword/ConfirmResetPassword/ConfirmResetPassword';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CreateUser } from './containers/Users/CreateUser';
import { UserTable } from './containers/Users/UserTable';
import { EditUser } from './containers/Users/EditUser';
import { isMobile, isTablet } from 'react-device-detect';
import { User, USER_ROLES } from './types/User';
import { checkIfHasSignInSub, checkIfHasSub } from './utils/cognito-utils';
import { SubcontractorTable } from './containers/Subcontractors/SubcontractorTable';
import { authContext } from './components/CognitoContextProvider/CognitoContextProvider';
import { CreateSubcontractor } from './containers/Subcontractors/CreateSubcontractor';
import { EditSubcontractor } from './containers/Subcontractors/EditSubcontractor';
import { CreateProject } from './containers/Projects/CreateProject';
import 'react-datepicker/dist/react-datepicker.css';
import { EditProject } from './containers/Projects/EditProject';
import AdminPageContainer from './containers/AdminPageContainer/AdminPageContainer';
import CreateMarket from './containers/Markets/CreateMarket';
import EditMarket from './containers/Markets/EditMarket';
import { MarketsTable } from './containers/Markets/MarketsTable';
import Dashboard from './containers/Dashboard/Dashboard';
import CustomerTable from './containers/Customers/CustomerTable';
import { ProjectTable } from './containers/ProjectsTable/ProjectTable';
import { SubcontractorDetails } from './containers/Subcontractors/SubcontractorDetails';
import ProjectDetails from './containers/Projects/ProjectDetails';
import { CreateCustomer } from './containers/Customers/CreateCustomer';
import { EditCustomer } from './containers/Customers/EditCustomer';
import { CustomerDetails } from './containers/Customers/CustomerDetails';
import axios from 'axios';
import { backendUrl } from './utils/env';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const Login = lazy(() => import('./containers/Login/Login'));
  const [loggedProfile, setLoggedProfile] = useState<User | undefined>(
    undefined,
  );
  const [profileLoading, setProfileLoading] = useState(true);
  const [userFullName, setUserFullName] = useState('');
  const [isSuperAdminOrAdmin, setisSuperAdminOrAdmin] = useState(false);

  useEffect(() => {
    const checkRememberMe = async () => {
      const rememberMe = localStorage.getItem('rememberMe');
      if (!rememberMe) {
        const keepSession = localStorage.getItem('renolink-keep-session');
        if (keepSession) localStorage.removeItem('renolink-keep-session');
        else {
          await Auth.signOut();
          setLoggedProfile(undefined);
        }
      }
    };
    checkRememberMe();
  }, []);

  const { auth, logOut } = useContext(authContext);
  const fetchProfile = async () => {
    const cognitoId = checkIfHasSub(auth)
      ? auth.data!.attributes.sub!
      : checkIfHasSignInSub(auth)
      ? auth.data!.signInUserSession.accessToken.payload.sub
      : '';
    if (cognitoId) {
      try {
        const profile = await axios.get(
          `${backendUrl}/user/byCognitoId/${cognitoId}`,
        );
        setLoggedProfile(profile.data);
      } catch (err) {
        toast.error(
          'There was a problem logging in. Contact a Renolink administrator.',
          {
            toastId: 'get-profile-error',
          },
        );
        await logOut();
        setLoggedProfile(undefined);
        setProfileLoading(false);
        navigate('/');
      }
    }
    setProfileLoading(false);
  };

  useEffect(() => {
    if (!auth.loading && auth.data) {
      setProfileLoading(true);
      fetchProfile();
    } else if (!auth.loading && !auth.data) {
      setLoggedProfile(undefined);
      setProfileLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    if (loggedProfile) {
      setUserFullName(
        `${loggedProfile?.first_name} ${loggedProfile?.last_name}`,
      );
      const userIsAdminRole =
        loggedProfile?.role === USER_ROLES.SUPER_ADMIN ||
        loggedProfile?.role === USER_ROLES.ADMIN;
      setisSuperAdminOrAdmin(userIsAdminRole);

      if (location.pathname === '/')
        navigate(`/${userIsAdminRole ? 'admin' : 'pm'}/dashboard`);
    } else {
      setUserFullName('');
      setisSuperAdminOrAdmin(false);
    }
  }, [loggedProfile]);

  const renderSideBarMenu = () => {
    if (
      loggedProfile &&
      location.pathname !== '/' &&
      (location.pathname.includes('home') ||
        location.pathname.includes('admin') ||
        location.pathname.includes('pm'))
    )
      return <Sidebar user={loggedProfile} />;
    return null;
  };

  const renderNavbar = () => {
    if (!isMobile && !isTablet) return null;

    if (
      location.pathname !== '/' &&
      (location.pathname.includes('home') ||
        location.pathname.includes('admin') ||
        location.pathname.includes('pm'))
    )
      return <Navbar />;
    return null;
  };

  const isAdmin =
    !profileLoading &&
    !!loggedProfile &&
    (loggedProfile.role === USER_ROLES.ADMIN ||
      loggedProfile.role === USER_ROLES.SUPER_ADMIN);
  const isPM =
    !profileLoading &&
    !!loggedProfile &&
    (loggedProfile.role === USER_ROLES.PROJECT_MANAGER ||
      loggedProfile.role === USER_ROLES.SENIOR_PROJECT_MANAGER);

  return (
    <>
      {renderNavbar()}
      <div className="d-flex full-height scrollable">
        {renderSideBarMenu()}
        <Routes>
          <Route
            path="/password-recovery"
            element={
              <Suspense fallback={<Loader />}>
                <PasswordRecovery />
              </Suspense>
            }
          />
          <Route
            path="/code-sent"
            element={
              <Suspense fallback={<Loader />}>
                <ConfirmationScreen />
              </Suspense>
            }
          />
          <Route
            path="/confirm-reset"
            element={
              <Suspense fallback={<Loader />}>
                <ConfirmResetPassword />
              </Suspense>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          {profileLoading && <Route path="*" element={<Loader />} />}
          {!profileLoading && !loggedProfile && (
            <>
              <Route
                path="/"
                element={
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route path="/admin">
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
              <Route path="/pm">
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </>
          )}

          {isPM && (
            <>
              <Route path="/pm">
                <Route
                  path="dashboard"
                  element={
                    <AdminPageContainer
                      userFullName={userFullName}
                      title="Dashboard"
                    />
                  }
                >
                  <Route path="" element={<Dashboard />} />
                </Route>
                <Route
                  path="projects"
                  element={
                    <AdminPageContainer
                      userFullName={userFullName}
                      title="Projects"
                    />
                  }
                >
                  <Route path="" element={<ProjectTable />} />
                  <Route path="details" element={<ProjectDetails />} />
                </Route>
                <Route path="*" element={<Navigate to="/pm/dashboard" />} />
              </Route>
              <Route path="*" element={<Navigate to="/pm/dashboard" />} />
            </>
          )}

          {isAdmin && (
            <>
              <Route path="/admin" element={<ProtectedRoute />}>
                <Route
                  path="subcontractors"
                  element={
                    <AdminPageContainer
                      userFullName={userFullName}
                      title="Subcontractors"
                    />
                  }
                >
                  <Route path="" element={<SubcontractorTable />} />
                  <Route path="create" element={<CreateSubcontractor />} />
                  <Route path="edit" element={<EditSubcontractor />} />
                  <Route path="details" element={<SubcontractorDetails />} />
                </Route>
                <Route
                  path="projects"
                  element={
                    <AdminPageContainer
                      userFullName={userFullName}
                      title="Projects"
                      section="projects"
                    />
                  }
                >
                  <Route path="" element={<ProjectTable />} />
                  <Route path="create" element={<CreateProject />} />
                  <Route path="edit" element={<EditProject />} />
                  <Route path="details" element={<ProjectDetails />} />
                </Route>
                <Route
                  path="users"
                  element={
                    <AdminPageContainer
                      userFullName={userFullName}
                      title="Users"
                    />
                  }
                >
                  <Route path="" element={<UserTable />} />
                  <Route path="create" element={<CreateUser />} />
                  <Route path="edit" element={<EditUser />} />
                  <Route path="details" element={<UserDetails />} />
                </Route>
                <Route
                  path="markets"
                  element={
                    <AdminPageContainer
                      userFullName={userFullName}
                      title="Markets"
                    />
                  }
                >
                  <Route path="" element={<MarketsTable />} />
                  <Route path="create" element={<CreateMarket />} />
                  <Route path="edit" element={<EditMarket />} />
                </Route>
                <Route
                  path="dashboard"
                  element={
                    <AdminPageContainer
                      userFullName={userFullName}
                      title="Dashboard"
                    />
                  }
                >
                  <Route path="" element={<Dashboard />} />
                </Route>
                <Route
                  path="customers"
                  element={
                    <AdminPageContainer
                      title="Customers"
                      userFullName={userFullName}
                    />
                  }
                >
                  <Route path="" element={<CustomerTable />} />
                  <Route path="details" element={<CustomerDetails />} />
                  <Route path="create" element={<CreateCustomer />} />
                  <Route path="edit" element={<EditCustomer />} />
                </Route>
                <Route
                  path=""
                  element={
                    <Navigate
                      to={`${
                        isSuperAdminOrAdmin ? '/admin/dashboard' : '/home'
                      }`}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={`${
                        isSuperAdminOrAdmin ? '/admin/dashboard' : '/home'
                      }`}
                    />
                  }
                />
              </Route>
              <Route path="*" element={<Navigate to="/admin/dashboard" />} />
            </>
          )}
        </Routes>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
