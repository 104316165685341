interface Props {
  errorMessage: string;
}

const ErrorBanner = ({ errorMessage }: Props) => {
  return (
    <div className="login-error-banner d-flex justify-content-center align-items-center">
      <i className="icon-Alert-Icon font-60 text-danger" />
      <p className="login-error-banner-text lazuli-grey pt-3">{errorMessage}</p>
    </div>
  );
};

export default ErrorBanner;
