import { Dispatch, SetStateAction } from 'react';
import { useGetAllRawMarketsQuery } from '../../redux/api/market.endpoints';

interface Props {
  label: React.ReactElement;
  setMarketData: Dispatch<SetStateAction<{ id: string; name: string }>>;
  value: string;
  disabled?: boolean;
}

const MarketDropdown = ({
  label,
  setMarketData,
  value,
  disabled = false,
}: Props) => {
  const { markets } = useGetAllRawMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({ markets: data ? data : [] }),
  });

  return (
    <>
      {label}
      <select
        disabled={markets.length === 0 || disabled}
        className="form-select lazuli-select"
        onChange={(e) => {
          const market = markets.find(
            (market) => market.id.toString() === e.target.value,
          );
          setMarketData({
            id: market?.id.toString() || '',
            name: market?.name || '',
          });
        }}
        value={value}
      >
        <option hidden={true}>Select a Market</option>
        {markets.map((market) => (
          <option value={market.id} key={market.id}>
            {market.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default MarketDropdown;
