// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { JSXElementConstructor, ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Note } from '../../types/Note';
import { User, USER_ROLES } from '../../types/User';
import { ENTITY_NAME } from '../../utils/enums';

interface Props {
  entityData: any[];
  entityName: string;
  currentId: any;
  authProfile: User | undefined;
  handleOpenModal: (action: string, selected: any, entity: any) => void;
  setNoteForEdit?: (note: Note) => void;
  raised?: boolean;
  editModal?: boolean;
}

export const ContextMenu = ({
  entityData,
  entityName,
  currentId,
  authProfile,
  handleOpenModal,
  setNoteForEdit,
  raised,
}: Props) => {
  const navigate = useNavigate();
  const entity = entityData.find(
    (entity) => entity.id.toString() === currentId.toString(),
  );

  const showOtherOptions =
    entityName === ENTITY_NAME.USER ? entity.id !== authProfile?.id : true;

  const showDelete =
    entityName != ENTITY_NAME.MARKET || entityName != ENTITY_NAME.NOTE;

  const showEdit =
    entityName != ENTITY_NAME.NOTE && entityName != ENTITY_NAME.EXPENSE;
  const showNoteEdit = entityName === ENTITY_NAME.NOTE;
  const showExpenseEdit = entityName === ENTITY_NAME.EXPENSE;
  const showActivate =
    entityName !== ENTITY_NAME.NOTE &&
    entityName !== ENTITY_NAME.PROJECT &&
    entityName !== ENTITY_NAME.EXPENSE;

  const isAdmin =
    authProfile &&
    entity &&
    (authProfile.role === USER_ROLES.SUPER_ADMIN ||
      (authProfile.role === USER_ROLES.ADMIN &&
        entity.role !== USER_ROLES.SUPER_ADMIN));

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => ReactElement<any, string | JSXElementConstructor<any>> | null;
    disabled: boolean;
  };

  const CustomToggle = React.forwardRef(function displayCustomToggle(
    props: CustomToggleProps,
    ref: React.Ref<HTMLElement>,
  ) {
    return (
      <i
        className={`icon-Dot-menu
        font-36
        ${props.disabled ? 'disabled' : 'enabled'}`}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          !props.disabled && props.onClick(e);
        }}
        role="button"
        aria-label="user-context-toggle"
        onKeyDown={(e) => e.preventDefault()}
        tabIndex={0}
      >
        {props.children}
      </i>
    );
  });

  return authProfile &&
    entity &&
    !(
      entityName === ENTITY_NAME.USER &&
      authProfile.role === USER_ROLES.ADMIN &&
      entity.role === USER_ROLES.SUPER_ADMIN
    ) ? (
    <Dropdown
      className={`
    ${raised ? 'raised-context' : ''}`}
      autoClose={true}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        className="table-action-btn black-arrow"
        disabled={
          entityName === ENTITY_NAME.NOTE && !isAdmin
            ? entity.user.id !== authProfile.id
            : !isAdmin
        }
      />
      <Dropdown.Menu>
        {showEdit && (
          <Dropdown.Item
            className="dropdown-item font-14"
            aria-label="context-edit-entity"
            onClick={() => {
              navigate(`edit?id=${entity.id}`);
            }}
          >
            Edit
          </Dropdown.Item>
        )}
        {showNoteEdit && (
          <Dropdown.Item
            className="dropdown-item font-14"
            aria-label="context-edit-entity"
            onClick={() => setNoteForEdit && setNoteForEdit(entity)}
          >
            Edit
          </Dropdown.Item>
        )}
        {showExpenseEdit && (
          <Dropdown.Item
            className="dropdown-item font-14"
            aria-label="context-edit-entity"
            onClick={() => handleOpenModal('Edit', entity, entityName)}
          >
            Edit Expense
          </Dropdown.Item>
        )}
        {showOtherOptions && showDelete && entityName != ENTITY_NAME.MARKET && (
          <Dropdown.Item
            className="dropdown-item font-14"
            aria-label="context-delete-entity"
            onClick={() => {
              handleOpenModal('Delete', entity, entityName);
            }}
          >
            Delete
          </Dropdown.Item>
        )}
        {showOtherOptions && showActivate && (
          <Dropdown.Item
            className="dropdown-item font-14"
            onClick={() => {
              handleOpenModal(
                entity.is_active ? 'Deactivate' : 'Activate',
                entity,
                currentId,
              );
            }}
          >
            {`${entity.is_active ? 'Deactivate' : 'Activate'} `}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <div style={{ height: '3rem' }}></div>
  );
};
