import { Helmet } from 'react-helmet';
import { useContext } from 'react';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { useNavigate } from 'react-router';

const Home = () => {
  const { logOut } = useContext(authContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logOut();
    navigate('/');
  };

  return (
    <>
      <Helmet>
        <title>Renolink - Home</title>
      </Helmet>
      <main className="container-fluid d-flex justify-content-center overflow-auto">
        <div className="text-center mt-5">
          <h1>{`Welcome, you're logged in!`}</h1>
          <button
            type="button"
            className="btn btn-lg btn-danger"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </main>
    </>
  );
};

export default Home;
