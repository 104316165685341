import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { authContext } from '../CognitoContextProvider/CognitoContextProvider';

interface Props {
  children?: React.ReactElement<void>;
  allowedRoles?: Array<number>;
  userRole?: number;
}

const ProtectedRoute: React.FC<Props> = ({
  children,
  allowedRoles = [],
  userRole = 0,
}) => {
  const { getCurrentAuthenticatedUser, logOut } = useContext(authContext);
  const [isAuth, setIsAuth] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);
  const [canAccess, setCanAccess] = useState(false);

  useEffect(() => {
    getCurrentAuthenticatedUser()
      .then(() => {
        setIsAuth(true);
        if (allowedRoles.length === 0) {
          setCanAccess(true);
        } else {
          if (allowedRoles.includes(userRole)) {
            setCanAccess(true);
          }
        }
      })
      .catch(() => {
        setIsAuth(false);
      })
      .finally(() => {
        setRequestFinished(true);
      });
  }, []);

  if (requestFinished) {
    if (!isAuth || !canAccess) {
      logOut();
      return <Navigate to="/" replace />;
    } else {
      return children ? children : <Outlet />;
    }
  } else return <></>;
};

export default ProtectedRoute;
