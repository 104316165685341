import SidebarItem from './SidebarItem';
import { SIDEBAR_LINKS } from '../../utils/enums';
import { Link } from 'react-router-dom';
import RenoLinkLogo from '../../styles/svgs/renolink-white.svg';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { User } from '../../types/User';
import { useState } from 'react';
import { isAdminOrSuperAdmin } from '../../auth/userUtils';

const Sidebar = ({ user }: { user: User | undefined }) => {
  const [toggled, setToggled] = useState(false);

  return (
    <>
      <div
        id="sidebar-overlay"
        className="sidebar-overlay d-none"
        style={{ zIndex: 4 }}
        aria-hidden="true"
        onClick={() => {
          document
            .getElementById('renolink-sidebar')
            ?.classList.toggle('hidden-sidebar');
          document
            .getElementById('sidebar-overlay')
            ?.classList.toggle('d-none');
        }}
      />
      <div
        id="renolink-sidebar"
        className={`full-height ${
          isMobileOnly || isTablet
            ? 'fade-in position-absolute sidebar hidden-sidebar'
            : 'desktop-animation'
        }`}
        style={{ zIndex: 5 }}
      >
        <div
          id="sidebar-container"
          className="d-flex flex-column flex-shrink-0 p-0 text-white bg-lazuli-extra-dark-blue desktop-animation"
          style={{ width: toggled ? '80px' : '220px', height: '100%' }}
        >
          {!isMobileOnly && !isTablet && (
            <div className="d-flex flex-row align-items-center">
              <button
                className="toggle-sidebar-button btn py-0 px-0 mt-2"
                style={{ backgroundColor: 'transparent' }}
                onClick={() => {
                  setToggled(!toggled);
                }}
              >
                <i className="icon-Menu-Expand font-40 text-white" />
              </button>
              {!toggled && (
                <Link
                  to={`/${
                    isAdminOrSuperAdmin(user) ? 'admin' : 'pm'
                  }/dashboard`}
                  className="fade-in d-flex align-items-center mx-2 mt-1 text-white text-decoration-none"
                >
                  <span className="d-flex justify-content-center ms-0 me-3 my-3">
                    <img src={RenoLinkLogo} alt="renolink-logo" />
                  </span>
                </Link>
              )}
              <hr className="blue-separator" />
            </div>
          )}

          <ul className="nav nav-pills flex-column mb-auto">
            {SIDEBAR_LINKS.map((link) => {
              const renderItem =
                user && link.allowedRoles
                  ? link.allowedRoles.includes(user.role)
                  : false;
              return (
                renderItem && (
                  <SidebarItem
                    key={`sidebar-item-${link.title.toLowerCase()}`}
                    title={link.title}
                    icon={link.icon}
                    path={`/${isAdminOrSuperAdmin(user) ? 'admin' : 'pm'}/${
                      link.path
                    }`}
                    toggled={toggled}
                  />
                )
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
