import { Market } from './Markets';

export interface getSubcontractorsForTablePayload {
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortType: 'ASC' | 'DESC';
  tab: string;
  searchQuery: string;
}

export interface getSubcontractorCountResponse {
  total: number;
  active: number;
  inactive: number;
}

export interface Subcontractor {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone: string;
  company: string;
  wc_insurance: boolean;
  wc_insurance_expiration: Date;
  gl_insurance: boolean;
  gl_insurance_expiration: Date;
  signed_agreement: boolean;
  w9: boolean;
  trades: string[];
  notes: string;
  market: Market;
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
  wc_is_expired: boolean;
  gl_is_expired: boolean;
}

export interface EditSubcontractorDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  wcInsurance: boolean;
  wcInsuranceExpiration: Date | undefined;
  glInsurance: boolean;
  glInsuranceExpiration: Date | undefined;
  signedAgreement: boolean;
  w9: boolean;
  trades: string[];
  notes: string;
  market: Market;
}

export const Trades = [
  'All Trades',
  'Paint and Drywall',
  'Flooring',
  'Handyman/General Maintenance',
  'Cleaner',
  'Lawncare',
  'Other/See Notes',
];

export const createToastData = {
  toastMsg: 'Subcontractor created successfully!',
  toastErrorMsg: 'An error occurred while creating the subcontractor.',
  toastId: 'create-subcontractor-success',
  toastErrorId: 'create-subcontractor-error',
};

export const editToastData = {
  toastMsg: 'Subcontractor updated successfully!',
  toastErrorMsg: 'An error occurred while updating the subcontractor.',
  toastId: 'edit-subcontractor-success',
  toastErrorId: 'edit-subcontractor-error',
};

export const deleteToastData = {
  toastMsg: 'Subcontractor deleted successfully.',
  toastErrorMsg: 'An error occurred while deleting the subcontractor.',
  toastId: 'delete-ubcontractor-success',
  toastErrorId: 'delete-subcontractor-error',
};

export const updateStatusToastData = {
  toastMsg: '',
  toastErrorMsg: "An error occurred while changing the subcontractor's status.",
  toastId: 'update-status-subcontractor-success',
  toastErrorId: 'status-subcontractor-error',
};
