import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { handleEndpointResult } from '../../utils/api';
import { EditSubcontractorForm } from '../../components/EditSubcontractorForm/EditSubcontractorForm';
import {
  useEditSubcontractorMutation,
  useGetSubcontractorByIdQuery,
} from '../../redux/api/subcontractor.endpoints';
import { useSearchParams } from 'react-router-dom';
import { useGetAllRawMarketsQuery } from '../../redux/api/market.endpoints';
import {
  EditSubcontractorDto,
  editToastData,
} from '../../types/Subcontractors';

export const EditSubcontractor = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [editSubcontractor, editSubcontractorResult] =
    useEditSubcontractorMutation();

  const handleEditSubcontractor = (
    editSubcontractorDto: EditSubcontractorDto,
  ) => {
    editSubcontractor(editSubcontractorDto);
  };

  const { markets } = useGetAllRawMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({ markets: data ? data : [] }),
  });

  const { subcontractorData } = useGetSubcontractorByIdQuery(
    searchParams.get('id') || '',
    {
      selectFromResult: ({ data }) => ({
        subcontractorData: data ? data : undefined,
      }),
    },
  );

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile, refetch } = useGetAuthenticatedUserProfileQuery(
    userId,
    { skip: !userId },
  );

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    handleEndpointResult(editToastData, editSubcontractorResult, () => {
      navigate(-1);
    });
  }, [editSubcontractorResult]);

  return authProfile ? (
    <>
      <Helmet>
        <title>Renolink - Edit Subcontractor</title>
      </Helmet>
      <HeaderTab title="Edit Subcontractor" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            {
              <EditSubcontractorForm
                subcontractorData={subcontractorData}
                handleEditSubcontractor={handleEditSubcontractor}
                isLoading={
                  editSubcontractorResult && editSubcontractorResult.isLoading
                }
                markets={markets}
                authProfile={authProfile}
              />
            }
          </main>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
