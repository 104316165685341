import { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { getDateForHeader } from '../../utils/dashboard';
import { useSearchParams } from 'react-router-dom';
import AdminHeaderActions from './AdminHeaderActions';
import { useGetUserByIdQuery } from '../../redux/api/user.endpoints';

interface Props {
  title: string;
  userFullName: string;
  section?: string;
}

const AdminHeader = ({ title, userFullName, section }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam] = useSearchParams();

  const { userData } = useGetUserByIdQuery(
    searchParam.get('projectManagerId') ?? '1',
    {
      selectFromResult: ({ data }) => ({ userData: data ? data : undefined }),
    },
  );

  const getPmName = () => {
    if (!searchParam.get('projectManagerId')) return 'Projects';
    return `${userData?.last_name}, ${userData?.first_name}`;
  };

  const getPmMarket = () => {
    if (!searchParam.get('projectManagerId')) return '';
    return `${userData?.market?.name}`;
  };

  return (
    <>
      <div className="row d-flex justify-content-between mx-0 px-0 mx-lg-1 px-lg-1">
        <h1
          className="welcome-message col-12 col-md-auto lazuli-grey"
          style={{ fontWeight: 500 }}
        >
          {title === 'Dashboard' ? (
            <div className="row">
              <div className="col-auto px-2">{`Welcome ${
                userFullName.split(' ')[0]
              },`}</div>
              <div className="col-auto px-0 font-normal">
                {getDateForHeader()}
              </div>
            </div>
          ) : (
            <>
              {section === 'projects' ? (
                <>
                  {getPmName()}
                  <span style={{ fontWeight: '300' }}> {getPmMarket()}</span>
                </>
              ) : (
                <>{title}</>
              )}
            </>
          )}
        </h1>
        {title === 'Dashboard' ? (
          <>
            <div className="row col-12 col-md-auto">
              {title === 'Dashboard' && (
                <div className="col-auto">
                  <span className="admin-header-date">{}</span>
                </div>
              )}
              {!isMobile && !isTablet && (
                <AdminHeaderActions isOpen={isOpen} setIsOpen={setIsOpen} />
              )}
            </div>
          </>
        ) : (
          !isMobile &&
          !isTablet && (
            <AdminHeaderActions isOpen={isOpen} setIsOpen={setIsOpen} />
          )
        )}
      </div>
      <hr className="admin-header-separator mt-1 mb-3" />
    </>
  );
};

export default AdminHeader;
