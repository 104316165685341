import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import {
  useCreateUserMutation,
  useGetAuthenticatedUserProfileQuery,
} from '../../redux/api/user.endpoints';
import { createToastData, CreateUserDto, permissions } from '../../types/User';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { handleEndpointResult } from '../../utils/api';
import { UserForm } from '../../components/UserForms/UserForm';

export const CreateUser = () => {
  const navigate = useNavigate();
  const [createUser, createUserResult] = useCreateUserMutation();

  const handleCreateUser = (createUserDto: CreateUserDto) => {
    createUser(createUserDto);
  };

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile, refetch } = useGetAuthenticatedUserProfileQuery(
    userId,
    { skip: !userId },
  );

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    handleEndpointResult(createToastData, createUserResult, () => {
      navigate('/admin/users');
    });
  }, [createUserResult]);

  return authProfile ? (
    <>
      <Helmet>
        <title>Renolink - Create User</title>
      </Helmet>
      <HeaderTab title="Create New User" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            {
              <UserForm
                permissions={permissions}
                isLoading={createUserResult && createUserResult.isLoading}
                authProfile={authProfile}
                handleCreateSubmit={handleCreateUser}
              />
            }
          </main>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
