import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddNewButton } from '../../components/Buttons/AddNewButton/AddNewButton';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { ContextMenu } from '../../components/ContextMenu/ContextMenu';
import Loader from '../../components/Loader/Loader';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import {
  useDeleteCustomerMutation,
  useGetCustomersCountQuery,
  useGetCustomersForCSVQuery,
  useGetCustomersForTableQuery,
  useUpdateCustomerActiveStatusMutation,
} from '../../redux/api/customer.endpoints';
import {
  Customer,
  deleteToastData,
  updateStatusToastData,
} from '../../types/Customer';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { USER_ROLES } from '../../types/User';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { ENTITY_NAME } from '../../utils/enums';
import { formatAsMoney } from '../../utils/table';
import { handleEndpointResult } from '../../utils/api';
import { CUSTOMER_CSV_METADATA, formatStringForCSV } from '../../utils/csv';

const CustomerTable = () => {
  const Table = lazy(() => import('../../components/Table/Table'));
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('active');
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('customer.company_name');
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC');
  const [searchQuery, setSearchQuery] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('Activate');
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(undefined);

  const { customersData, refetch, getAllCustomersForTableError, isFetching } =
    useGetCustomersForTableQuery(
      {
        pageSize: itemsPerPage,
        pageNumber: activePage,
        sortBy,
        sortType,
        tab: activeTab,
        searchQuery,
      },
      {
        selectFromResult: ({ data, error, isFetching }) => ({
          customersData: data ? data : [],
          getAllCustomersForTableError: error as FetchBaseQueryError,
          isFetching,
        }),
      },
    );

  const { data: CSVData } = useGetCustomersForCSVQuery();
  const { data: customersCount, refetch: refetchCustomersCount } =
    useGetCustomersCountQuery({ search: searchQuery });

  const { auth } = useContext(authContext);

  const [userId, setUserId] = useState('');
  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });
  const [deleteCustomer, deleteCustomerResult] = useDeleteCustomerMutation();
  const [updateActiveStatus, updateActiveStatusResult] =
    useUpdateCustomerActiveStatusMutation();

  useEffect(() => {
    setActivePage(1);
  }, [itemsPerPage, activeTab, searchQuery]);

  useEffect(() => {
    refetch();
    refetchCustomersCount();
  }, [sortBy, sortType, activePage]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    if (
      authProfile &&
      authProfile.role !== USER_ROLES.ADMIN &&
      authProfile.role !== USER_ROLES.SUPER_ADMIN
    ) {
      navigate('/');
    }
  }, [authProfile]);

  useEffect(() => {
    if (getAllCustomersForTableError && getAllCustomersForTableError.data) {
      const errorPayload = getAllCustomersForTableError.data as {
        status: number;
        error: string;
      };
      toast.error(errorPayload.error, {
        toastId: 'get-all-subcontractors-querry-error',
      });
    }
  }, [getAllCustomersForTableError]);

  useEffect(() => {
    handleEndpointResult(deleteToastData, deleteCustomerResult, () => {
      setOpenModal(false);
    });
  }, [deleteCustomerResult]);

  useEffect(() => {
    updateStatusToastData.toastMsg = `Customer ${updateActiveStatusResult.data?.message} successfully.`;
    handleEndpointResult(
      updateStatusToastData,
      updateActiveStatusResult,
      () => {
        setOpenModal(false);
      },
    );
  }, [updateActiveStatusResult]);

  const tabs = [
    {
      title: `Active (${customersCount?.active || 0})`,
      attribute: 'active',
    },
    {
      title: `Inactive (${customersCount?.inactive || 0})`,
      attribute: 'inactive',
    },
    {
      title: `All (${customersCount?.total || 0})`,
      attribute: 'all',
    },
  ];

  const columns = [
    { title: 'ID', attribute: 'id', sortValue: 'customer.id' },
    {
      title: 'Name',
      attribute: 'companyName',
      maxWidth: '10rem',
      sortValue: 'customer.company_name',
    },
    {
      title: 'City',
      attribute: 'city',
      maxWidth: '10rem',
      sortValue: 'customer.city',
    },
    {
      title: 'Open',
      attribute: 'openProjects',
      textCenter: false,
      sortValue: 'openProjects',
    },
    {
      title: 'Open Amount',
      attribute: 'openAmount',
      maxWidth: '10rem',
      textCenter: false,
      sortValue: 'openAmount',
    },
    {
      title: 'Past Due',
      attribute: 'pastDueProjects',
      textCenter: false,
      sortValue: 'pastDueProjects',
    },
  ];

  if (activeTab === 'all')
    columns.push({
      title: 'Status',
      attribute: 'is_active',
      sortValue: 'customer.is_active',
    });

  const transformDataForTable = () =>
    customersData.map(
      (customer: Customer) =>
        ({
          id: customer.id,
          companyName: customer.company_name || '',
          address: customer.primary_address || '',
          city: customer.city || '',
          email: customer.email || '',
          phone: customer.phone || '',
          openProjects: customer.openProjects || '0',
          openAmount: formatAsMoney(customer?.openAmount || 0),
          pastDueProjects: customer.pastDueProjects || '0',
          is_active: customer.is_active ? 'Active' : 'Inactive',
        } as { [key: string]: string | number }),
    );

  const getTotalCount = () => {
    switch (activeTab) {
      case 'active':
        return customersCount?.active || 0;
      case 'inactive':
        return customersCount?.inactive || 0;
      case 'all':
        return customersCount?.total || 0;
      default:
        return 0;
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleUpdateActiveStatus = () =>
    selectedCustomer && updateActiveStatus(selectedCustomer.id);

  const handleDeleteCustomer = () =>
    selectedCustomer && deleteCustomer(selectedCustomer.id);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (action: string, customer: Customer) => {
    setSelectedCustomer(customer);
    setModalAction(action);
    setOpenModal(true);
  };

  const getHeaderText = () =>
    selectedCustomer
      ? `${modalAction} Customer: ${selectedCustomer.company_name}.`
      : '';

  const getSecondaryText = () =>
    selectedCustomer
      ? `Are you sure you want to ${modalAction.toLowerCase()} Customer ${
          selectedCustomer.company_name
        }?`
      : '';

  const renderContextMenu = (userId: number) => {
    const contextMenuProps = {
      entityData: customersData,
      entityName: ENTITY_NAME.CUSTOMER,
      currentId: userId,
      authProfile,
      handleOpenModal,
    };
    return <ContextMenu {...contextMenuProps} />;
  };

  const sortCustomers = (category: string) => {
    setSortBy((prev) => {
      if (prev === category) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return category;
      }
      setSortType('ASC');
      return category;
    });
  };

  const getDataForCSV = () => {
    if (CSVData) {
      const dataForCSV = CSVData.map((customer) => {
        return {
          ...customer,
          openProjects: customer?.openProjects || 0,
          openAmount: `$${customer?.openAmount || 0}`,
          pastDueProjects: customer?.pastDueProjects || 0,
          company_name: formatStringForCSV(customer?.company_name || ''),
          primary_address: formatStringForCSV(customer?.primary_address || ''),
          secondary_address: formatStringForCSV(
            customer?.secondary_address || '',
          ),
          city: formatStringForCSV(customer?.city || ''),
          status: customer?.is_active ? 'Active' : 'Inactive',
        };
      });
      return { data: dataForCSV, ...CUSTOMER_CSV_METADATA };
    }
    return { data: [], headers: [], filename: 'null.csv' };
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Table
          tableName="customers"
          mainActionButton={
            <AddNewButton label={`${isMobileOnly ? '+' : '+ Add Customer'}`} />
          }
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          columns={columns}
          data={transformDataForTable()}
          totalData={getTotalCount()}
          isFetchingData={isFetching}
          sortFunction={sortCustomers}
          sortBy={sortBy}
          sortType={sortType}
          activePage={activePage}
          setActivePage={setActivePage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          renderContextMenu={renderContextMenu}
          handleSearch={handleSearch}
          csvData={getDataForCSV()}
        />
      </Suspense>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={
          modalAction === 'Delete'
            ? handleDeleteCustomer
            : handleUpdateActiveStatus
        }
      />
    </>
  );
};

export default CustomerTable;
