import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import Pagination from '../../components/Pagination/Pagination';
import { Document } from '../../types/Documents';
import { getFormattedDate } from '../../utils/table';

interface Props {
  files: Document[];
  totalFiles: number;
  uploadDocument: (data: FormData) => void;
  deleteDocuments: (documentIds: number[]) => void;
  setFileType: Dispatch<SetStateAction<string>>;
  setErrorDocMessage: Dispatch<SetStateAction<string>>;
  handleOpenUploadModal: () => void;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
  isFetching: boolean;
  canAddFile: boolean;
}

const ProjectDocuments = ({
  files,
  totalFiles,
  uploadDocument,
  deleteDocuments,
  setFileType,
  setErrorDocMessage,
  handleOpenUploadModal,
  activePage,
  setActivePage,
  isFetching,
  canAddFile,
}: Props) => {
  const [searchParams] = useSearchParams();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    setIsSelectAllChecked(false);
  }, [activePage]);

  useEffect(() => {
    if (activePage > 1 && !isFetching && files.length === 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [files]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Object is possibly 'null'.
    const fileUploaded = event.target.files[0];

    if (fileUploaded.size >= 30362843.46) {
      setErrorDocMessage('Files must be <30Mb');
      return;
    }
    setFileType('document');
    handleOpenUploadModal();

    const documentFormData = new FormData();
    documentFormData.append('project_id', searchParams.get('id')!);
    documentFormData.append('owner_type', 'project');
    documentFormData.append('file_type', 'document');
    documentFormData.append('file', fileUploaded);
    uploadDocument(documentFormData);
  };

  const handleClick = () => {
    if (hiddenFileInput !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      hiddenFileInput.current.click();
    }
  };

  const handleSelectAll = () => {
    setDocuments((prev) =>
      prev.map((doc) => ({ ...doc, selected: !isSelectAllChecked })),
    );
    setIsSelectAllChecked((prev) => !prev);
  };

  const handleDocumentSelection = (document: Document) => {
    setDocuments((prev) =>
      prev.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, selected: !doc.selected };
        }
        return { ...doc };
      }),
    );
    isSelectAllChecked && setIsSelectAllChecked(false);
  };

  const handleDeleteSelection = () => {
    const documentIds = documents.reduce((filtered: number[], document) => {
      if (document.selected) {
        filtered.push(parseInt(document.id));
      }
      return filtered;
    }, []);
    setFileType('document');
    deleteDocuments(documentIds);
    isSelectAllChecked && setIsSelectAllChecked(false);
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (files) {
      setDocuments([...files]);
    }
  }, [files]);

  return (
    <div
      className={`my-3 rounded bg-white-and-shadowed d-flex scrollable ${
        isTablet ? 'py-0 px-0 w-100 flex-fill' : 'pt-2 px-3 p-md-4 px-md-5'
      } h-100`}
    >
      <div
        className={`container-fluid mx-0 mt-3 ${
          isTablet ? 'px-0 col-12' : 'px-1 col-12'
        }`}
      >
        <main className={`w-100 h-100 ${isMobile ? 'px-4' : ''}`}>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex">
              <div className="form-group">
                <input
                  className="form-check-input lazuli-checkbox"
                  type="checkbox"
                  id="worker-comp-check"
                  onChange={(e) => e}
                  checked={isSelectAllChecked}
                  onClick={handleSelectAll}
                />
                <label
                  className="form-check-label vertical-align-sub font-weight-bold fake-hyperlink"
                  htmlFor="worker-comp-check"
                >
                  Select All
                </label>
              </div>
            </div>
            <div
              className={`d-flex flex-row-reverse row flex-grow-slightly ${
                isMobileOnly
                  ? 'header-action-buttons-mobile'
                  : 'header-action-buttons'
              }`}
            >
              {canAddFile && (
                <button
                  className="btn btn-primary col-3 col-md-4 mx-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
                  onClick={handleClick}
                >
                  <i className="icon-Upload font-24" />
                  {isMobileOnly ? '' : ' Upload'}
                </button>
              )}
              {canAddFile && (
                <button
                  className="btn btn-primary col-3 col-md-4 mx-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
                  disabled={!documents.some((doc) => doc.selected)}
                  onClick={() => setOpenDeleteModal(true)}
                >
                  <i className="icon-Delete font-24" />
                  {isMobileOnly ? '' : ' Delete'}
                </button>
              )}
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          </div>
          <hr className="breakline" />
          <div>
            {documents.map((document) => (
              <div key={document.id}>
                <div className="row">
                  <div
                    className={`col-lg-6 col-8 d-flex justify-content-start pe-0 ${
                      isMobileOnly ? 'center' : 'start'
                    } align-items-center`}
                  >
                    {' '}
                    <input
                      className="form-check-input lazuli-checkbox me-3"
                      type="checkbox"
                      id="worker-comp-check"
                      onChange={(e) => e}
                      checked={document.selected}
                      style={{ marginTop: 0, marginBottom: '5px' }}
                      onClick={() => handleDocumentSelection(document)}
                    />
                    <a
                      className="document-url text-truncate"
                      href={document.presignedUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ maxWidth: '75%' }}
                    >
                      {document.name}
                    </a>
                  </div>
                  <div className="col-4 col-lg-6 d-flex justify-content-start align-items-center">
                    {getFormattedDate(document.created_at)}
                  </div>
                </div>
                <hr className="breakline-thin mt-2" />
              </div>
            ))}
            {isMobileOnly && <div style={{ height: '4rem' }}></div>}
          </div>
          {totalFiles > 10 && (
            <Pagination
              totalPages={Math.ceil(totalFiles / 10)}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          )}
        </main>
      </div>
      <ActionConfirmationModal
        isOpen={openDeleteModal}
        closeModal={() => setOpenDeleteModal(false)}
        headerText="Delete Document"
        secondaryText="Are you sure you want to delete the selected document(s)?"
        primaryAction={() => setOpenDeleteModal(false)}
        secondaryAction={() => handleDeleteSelection()}
      />
    </div>
  );
};

export default ProjectDocuments;
