import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router';
import { CreateUserDto, EditUserDto, User, USER_ROLES } from '../../types/User';
import { ACTION_CONFIRMATION_MODAL, permissionRoles } from '../../utils/enums';
import { validateEmail } from '../../utils/validations';
import MarketDropdown from '../Dropdowns/MarketDropdown';
import SPMDropdown from '../Dropdowns/SPMDropdown';
import { ActionConfirmationModal } from '../Modals/ActionConfirmationModal/ActionConfirmationModal';

interface Props {
  handleCreateSubmit?: (createUserDto: CreateUserDto) => void;
  handleEditSubmit?: (editUserDto: EditUserDto) => void;
  permissions: string[];
  authProfile: User;
  isLoading: boolean;
  userToEdit?: User;
}

export const UserForm = ({
  handleCreateSubmit,
  handleEditSubmit,
  permissions,
  authProfile,
  isLoading,
  userToEdit,
}: Props) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [positionName, setPositionName] = useState(' ');
  const [marketData, setMarketData] = useState({ id: '', name: '' });
  const [SPMData, setSPMData] = useState({ id: '', name: '' });
  const [selectedRole, setSelectedRole] = useState(0);
  const [phoneError, setPhoneError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (userToEdit) {
      setUserId(userToEdit.id);
      setFirstName(userToEdit.first_name);
      setLastName(userToEdit.last_name);
      setEmail(userToEdit.email);
      setPhoneNumber(userToEdit.phone);
      setCompanyName(userToEdit.company);
      setPositionName(userToEdit.position);
      setSelectedRole(userToEdit.role);
      if (userToEdit.market) {
        const { id, name } = userToEdit.market;
        setMarketData({ id: id.toString(), name });
      }
      if (userToEdit.seniorProjectManager) {
        const { id: spmId, name: spmName } = userToEdit.seniorProjectManager;
        setSPMData({ id: spmId.toString(), name: spmName });
      }
    }
  }, [userToEdit]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleRoleChange = (currentPermission: number) => {
    setSelectedRole(currentPermission);
    setMarketData({ id: '', name: '' });
    setSPMData({ id: '', name: '' });
  };

  const permissionsRow = () =>
    permissions.map((permission, index) => {
      const currentPermission = permissionRoles[permission];
      const hidePermission =
        currentPermission === USER_ROLES.SUPER_ADMIN &&
        authProfile.role !== USER_ROLES.SUPER_ADMIN;
      return hidePermission ? (
        <></>
      ) : (
        <div
          className={`col-10 col-md-6 col-lg-${
            currentPermission === USER_ROLES.SENIOR_PROJECT_MANAGER ? 3 : 2
          } ps-0 ps-md-2 py-2 d-flex flex-row`}
          key={permission}
          onClick={() => handleRoleChange(currentPermission)}
          onKeyDown={(e) =>
            e.key === ' ' && handleRoleChange(currentPermission)
          }
          tabIndex={index + 1}
          role="button"
        >
          <div
            className={`col-2 me-${
              isMobile ||
              currentPermission === USER_ROLES.SENIOR_PROJECT_MANAGER
                ? 2
                : 3
            }`}
          >
            <input
              type="radio"
              className="form-check-input mx-3"
              checked={currentPermission === selectedRole}
              onChange={() => handleRoleChange(currentPermission)}
            />
          </div>
          <div className="col-10">{permission}</div>
        </div>
      );
    });

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    const phoneHasErrors = phoneNumber.includes('_');
    const firstNameHasErrors = !firstName.trim();
    const lastNameHasErrors = !lastName.trim();
    const companyHasErrors = !companyName.trim();
    const emailHasErrors = !email.trim() || !validateEmail(email);
    if (
      phoneHasErrors ||
      firstNameHasErrors ||
      lastNameHasErrors ||
      companyHasErrors ||
      emailHasErrors
    ) {
      setPhoneError(phoneHasErrors);
      setFirstNameError(firstNameHasErrors);
      setEmailError(emailHasErrors);
      firstNameHasErrors && setFirstName('');
      setLastNameError(lastNameHasErrors);
      lastNameHasErrors && setLastName('');
      setCompanyError(companyHasErrors);
      companyHasErrors && setCompanyName('');
    } else {
      const payload = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phoneNumber,
        companyName: companyName.trim(),
        positionName: positionName.trim(),
        marketId: marketData.id,
        roleId: selectedRole,
        SPMId: SPMData.id === 'none' ? '' : SPMData.id,
      };
      if (userToEdit) {
        handleEditSubmit &&
          handleEditSubmit({ id: userToEdit?.id || 0, ...payload });
      } else {
        handleCreateSubmit &&
          handleCreateSubmit({
            email,
            ...payload,
          });
      }
    }
  };

  const isSubmitDisabled =
    phoneError ||
    firstNameError ||
    lastNameError ||
    companyError ||
    emailError ||
    isLoading ||
    selectedRole === 0 ||
    !companyName ||
    !firstName ||
    !lastName ||
    !email ||
    !phoneNumber ||
    ((selectedRole === USER_ROLES.PROJECT_MANAGER ||
      selectedRole === USER_ROLES.SENIOR_PROJECT_MANAGER) &&
      !marketData.id);

  return (
    <>
      <div className="flex-grow-1 form-container">
        <h2
          aria-label="create-user-title"
          className="text-center font-weight-bold"
        >
          {userToEdit ? 'Edit' : 'Create New'} User
        </h2>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row py-3 d-flex justify-content-center">
              <h4
                className="col-10 p-0 font-weight-bold mb-2"
                aria-label="edit-user-header"
              >
                User Information
              </h4>

              <div className="form-group col-11 col-md-5 mx-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey"
                  className="form-label-style"
                >
                  First Name<span className="required-tag">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control lazuli-input-border-medium ${
                    firstNameError ? 'error' : ''
                  }`}
                  id="firstNameId"
                  aria-describedby="FirstNameHelp"
                  value={firstName}
                  onChange={(e) => {
                    if (firstNameError) setFirstNameError(false);
                    let firstNameValue = e.target.value;
                    if (firstNameValue.length > 25)
                      firstNameValue = firstNameValue.substring(0, 25);
                    setFirstName(firstNameValue);
                  }}
                  aria-label="first-name-input"
                />
                {firstNameError && (
                  <p className="text-error mt-1 ms-2">
                    First name can not be empty
                  </p>
                )}
              </div>
              <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
                <label
                  htmlFor="font-weight-bold lazuli-grey"
                  className="form-label-style"
                >
                  Last Name<span className="required-tag">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control lazuli-input-border-medium ${
                    lastNameError ? 'error' : ''
                  }`}
                  id="lastNameId"
                  aria-describedby="LastNameHelp"
                  value={lastName}
                  onChange={(e) => {
                    if (lastNameError) setLastNameError(false);
                    let lastNameValue = e.target.value;
                    if (lastNameValue.length > 25)
                      lastNameValue = lastNameValue.substring(0, 25);
                    setLastName(lastNameValue);
                  }}
                  aria-label="last-name-input"
                />
                {lastNameError && (
                  <p className="text-error mt-1 ms-2">
                    Last name can not be empty
                  </p>
                )}
              </div>
            </div>

            <div className="row py-1 py-md-2 d-flex justify-content-center">
              <div className="form-group col-11 col-md-5 mx-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey"
                  className="form-label-style"
                >
                  Email<span className="required-tag">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control lazuli-input-border-medium ${
                    emailError ? 'error' : ''
                  }`}
                  id="EmailId"
                  aria-describedby="EmailHelp"
                  value={email}
                  onChange={(e) => {
                    if (emailError) setEmailError(false);
                    let emailValue = e.target.value;
                    if (emailValue.length > 50)
                      emailValue = emailValue.substring(0, 50);
                    setEmail(emailValue);
                  }}
                  disabled={!!userToEdit}
                  aria-label="email-input"
                />
                {emailError && (
                  <p className="text-error mt-1 ms-2">Invalid Email</p>
                )}
              </div>
              <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
                <label
                  htmlFor="font-weight-bold lazuli-grey"
                  className="form-label-style"
                >
                  Phone<span className="required-tag">*</span>
                </label>
                <ReactInputMask
                  mask="(999) 999-9999"
                  type="tel"
                  className={`form-control lazuli-input-border-medium ${
                    false ? 'error' : ''
                  }`}
                  id="phoneId"
                  aria-describedby="PhoneNumberHelp"
                  value={phoneNumber}
                  onChange={(e) => {
                    if (phoneError) setPhoneError(false);
                    let phoneValue = e.target.value;
                    if (phoneValue.length > 14)
                      phoneValue = phoneValue.substring(0, 14);
                    setPhoneNumber(phoneValue);
                  }}
                  aria-label="phone-number-input"
                />
                {phoneError && (
                  <p className="text-error mt-1 ms-2">Invalid Phone Number</p>
                )}
              </div>
            </div>

            <div className="row py-3 d-flex justify-content-center">
              <div className="form-group col-11 col-md-5 mx-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey"
                  className="form-label-style"
                >
                  Company Name<span className="required-tag">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control lazuli-input-border-medium ${
                    companyError ? 'error' : ''
                  }`}
                  id="CompanyNameId"
                  aria-describedby="CompanyNameHelp"
                  value={companyName}
                  onChange={(e) => {
                    let companyValue = e.target.value;
                    if (companyValue.length > 200)
                      companyValue = companyValue.substring(0, 200);
                    setCompanyName(companyValue);
                  }}
                  aria-label="company-name-input"
                />
                {companyError && (
                  <p className="text-error mt-1 ms-2">
                    Company name can not be empty
                  </p>
                )}
              </div>
              <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
                <label
                  htmlFor="font-weight-bold lazuli-grey"
                  className="form-label-style"
                >
                  Position
                </label>
                <input
                  type="text"
                  className={`form-control lazuli-input-border-medium ${
                    false ? 'error' : ''
                  }`}
                  id="PositionId"
                  aria-describedby="PositionNameHelp"
                  value={positionName}
                  onChange={(e) => {
                    let positionValue = e.target.value;
                    if (positionValue.length > 200)
                      positionValue = positionValue.substring(0, 200);
                    setPositionName(positionValue);
                  }}
                  aria-label="position-name-input"
                />
              </div>
            </div>

            <div className="row py-2 d-flex justify-content-center">
              <div className="form-group col-10 mx-2 px-1">
                <h4 className="font-weight-bold">
                  Permission<span className="required-tag">*</span>
                </h4>
                <div className="row">{permissionsRow()}</div>
              </div>
            </div>

            <div className="row py-3 d-flex justify-content-center">
              <div className="form-group col-11 col-md-5 mx-2">
                <MarketDropdown
                  label={
                    <h4 className="font-weight-bold mb-3">
                      Market
                      {(selectedRole === USER_ROLES.PROJECT_MANAGER ||
                        selectedRole === USER_ROLES.SENIOR_PROJECT_MANAGER) && (
                        <span className="required-tag">*</span>
                      )}
                    </h4>
                  }
                  setMarketData={setMarketData}
                  value={marketData.id}
                  disabled={
                    selectedRole !== USER_ROLES.PROJECT_MANAGER &&
                    selectedRole !== USER_ROLES.SENIOR_PROJECT_MANAGER
                  }
                />
              </div>
              <div className="form-group col-11 col-md-5 mx-2 mt-3 mt-md-0">
                <SPMDropdown
                  label={
                    <h4 className="font-weight-bold mb-3">
                      Senior Project Manager
                    </h4>
                  }
                  setSPMData={setSPMData}
                  value={SPMData.id}
                  disabled={selectedRole !== USER_ROLES.PROJECT_MANAGER}
                  userId={userId}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="row my-2">
          <div className="col-12 justify-content-center row mx-0">
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-block pt-1 pb-2 lazuli-light-blue-border lazuli-button-medium"
                onClick={handleOpenModal}
              >
                <span className="font-weight-bold font-18">Cancel</span>
              </button>
            </div>
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-primary btn-block py-2 bg-lazuli-blue lazuli-button-medium"
                aria-label="edit-user-submit-btn"
                onClick={() => handleSubmit()}
                disabled={isSubmitDisabled}
              >
                <span className="font-weight-bold font-18">
                  {userToEdit ? 'Save' : 'Create'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={ACTION_CONFIRMATION_MODAL.EDIT_USER_HEADER}
        secondaryText={ACTION_CONFIRMATION_MODAL.EDIT_USER_SECONDARY}
        primaryAction={handleCloseModal}
        secondaryAction={goBack}
      />
    </>
  );
};
