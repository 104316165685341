const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getDateForHeader = () => {
  const today = new Date();
  const dayName = days[today.getDay()];
  const monthName = months[today.getMonth()];
  const date = today.getDate();
  const year = today.getFullYear();
  return `today is ${dayName} ${monthName} ${date}, ${year} `;
};

export const ADMIN_COLUMNS = {
  SHARED: [
    {
      title: 'Project Manager',
      attribute: 'projectManager',
      sortValue: 'user.first_name',
      maxWidth: '10rem',
      width: '30%',
    },
    {
      title: 'Market',
      attribute: 'primaryMarket',
      sortValue: 'market.name',
      maxWidth: '10rem',
      width: '25%',
    },
  ],
  OPEN: [
    {
      title: 'Open Projects',
      attribute: 'openProjects',
      textCenter: true,
      maxWidth: '8rem',
    },
    {
      title: 'Open Revenue',
      attribute: 'openRevenue',
      sortValue: 'openAmount',
      textCenter: true,
    },
    {
      title: 'Past Due',
      attribute: 'pastDue',
      textCenter: true,
    },
    {
      title: 'Over 28 Days',
      attribute: 'over28Days',
      textCenter: true,
      maxWidth: '10rem',
    },
  ],
  COMPLETED: [
    {
      title: 'Completed Projects',
      attribute: 'completedProjects',
      textCenter: true,
      maxWidth: '10rem',
    },
    {
      title: 'Completed Revenue',
      attribute: 'completedRevenue',
      sortValue: 'completedAmount',
    },
    {
      title: 'On Time',
      attribute: 'onTimePercentage',
      sortValue: 'onTimePercentage',
    },
  ],
};

export const PM_COLUMNS = {
  OPEN: [
    {
      title: 'ID',
      attribute: 'id',
      sortValue: 'project.id',
    },
    {
      title: 'Customer',
      attribute: 'customer',
      sortValue: 'customer.company_name',
      maxWidth: '3rem',
    },
    {
      title: 'Project Address',
      attribute: 'address',
      sortValue: 'not_sortable',
      maxWidth: '3rem',
    },
    {
      title: 'Project Manager',
      attribute: 'projectManager',
      sortValue: 'project_manager',
      maxWidth: '3rem',
    },
    {
      title: 'Start Date',
      attribute: 'startDate',
      sortValue: 'project.start_date',
    },
    {
      title: 'Due Date',
      attribute: 'dueDate',
      sortValue: 'project.due_date',
    },
    {
      title: 'Contract Amount',
      attribute: 'contractAmount',
      sortValue: 'project.contract_amount',
      maxWidth: '3rem',
      classes: 'text-center',
    },
    {
      title: 'Invoice Number',
      attribute: 'invoice_number',
      sortValue: 'project.invoice_number',
      classes: 'text-center',
      maxWidth: '10rem',
    },
    {
      title: 'DO / DA',
      attribute: 'datesProgression',
      sortValue: 'days_under_contract',
      tooltip: 'Days Open / Days Allotted',
      hasTooltip: true,
    },
    {
      title: 'Progress',
      attribute: 'progress',
      sortValue: 'project.progress',
    },
  ],
};

// 3,600,000 ms equals to 1 hour
export const DASHBOARD_REFRESH_INTERVAL = 3600000;
