import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendUrl } from '../../utils/env';

export const renolinkApi = createApi({
  reducerPath: 'renolinkApi',
  baseQuery: fetchBaseQuery({ baseUrl: backendUrl }),
  tagTypes: [
    'User',
    'UserData',
    'Subcontractor',
    'SubcontractorData',
    'SubcontractorCSV',
    'AuthenticatedUserProfile',
    'UserCSV',
    'UserProjectManagers',
    'UserDashboard',
    'Project',
    'ProjectData',
    'ProjectCSV',
    'ProjectDashboard',
    'Customer',
    'CustomersRaw',
    'CustomersData',
    'CustomerData',
    'CustomerCSV',
    'Market',
    'MarketData',
    'MarketCSV',
    'Document',
    'DocumentsData',
    'ProjectHistory',
    'ProjectExpenses',
    'ProjectHistoryCSV',
    'ProjectNotes',
  ],
  endpoints: () => ({}),
});
