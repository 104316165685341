import React from 'react';
import ErrorBanner from '../ErrorBanner/ErrorBanner';
import RenolinkLogo from '../../styles/svgs/renolink.svg';

interface Props {
  secondaryText: string;
  errorBannerText: string;
  displayErrorBanner: boolean;
}

export const RenolinkHeader = ({
  displayErrorBanner,
  secondaryText,
  errorBannerText,
}: Props) => {
  return (
    <>
      <div className="row py-3 mx-lg-5">
        <div className="text-center mb-4" aria-label="renolink-logo">
          <img
            src={RenolinkLogo}
            alt="renolink-logo"
            width="250px"
            height="50px"
          />
        </div>
        <h2 className="text-center lazuli-grey font-weight-bold">
          {' '}
          {secondaryText}
        </h2>
      </div>
      {displayErrorBanner && (
        <div className="py-0 py-md-4 px-lg-4 mx-lg-4">
          <ErrorBanner errorMessage={errorBannerText} />
        </div>
      )}
    </>
  );
};
