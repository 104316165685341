import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import TableTabs from '../../components/Table/TableTabs';
import DashboardCard from '../../components/Dashboard/DashboardCard';
import {
  useGetAllProjectsQuery,
  useGetProjectsDataForDashboardQuery,
} from '../../redux/api/project.endpoints';
import {
  useGetAuthenticatedUserProfileQuery,
  useGetUsersForDashboardQuery,
} from '../../redux/api/user.endpoints';
import {
  formatAsMoney,
  getDaysForDate,
  getFormattedDate,
} from '../../utils/table';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { isAdminOrSuperAdmin, isPMOrSPM, isSPM } from '../../auth/userUtils';
import {
  ADMIN_COLUMNS,
  DASHBOARD_REFRESH_INTERVAL,
  PM_COLUMNS,
} from '../../utils/dashboard';
import { projectStatusValues } from '../../utils/enums';
import { Project } from '../../types/Project';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const Dashboard = () => {
  const DEFAULT_ADMIN_SORT = 'user.first_name';
  const DEFAULT_PM_SORT = 'project.id';
  const DashboardTable = lazy(
    () => import('../../components/Dashboard/DashboardTable'),
  );
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('open');
  const [sortBy, setSortBy] = useState('user.last_name');
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC');
  const [timeRange, setTimeRange] = useState('');
  const [userId, setUserId] = useState('');
  const [hasPMPermission, setHasPMPermission] = useState(false);
  let refreshInterval: NodeJS.Timer;

  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  const { data: dashboardStats, refetch: refetchStats } =
    useGetProjectsDataForDashboardQuery({
      status: activeTab,
      timeRange,
      projectManagerId:
        authProfile && isPMOrSPM(authProfile) ? authProfile.id : undefined,
    });

  const {
    projectManagersData,
    refetch,
    isFetching: fetchingPMs,
  } = useGetUsersForDashboardQuery(
    {
      sortBy: sortBy.includes('project') ? DEFAULT_ADMIN_SORT : sortBy,
      sortType,
      timeRange,
    },
    {
      skip: authProfile && isPMOrSPM(authProfile),
      selectFromResult: ({ data, isFetching }) => ({
        projectManagersData: data ? data : [],
        isFetching,
      }),
    },
  );

  const {
    projectsData,
    refetch: refetchProjects,
    isFetching: fetchingProjects,
  } = useGetAllProjectsQuery(
    {
      pageSize: 0,
      pageNumber: 1,
      sortBy: sortBy.includes('user') ? DEFAULT_PM_SORT : sortBy,
      sortType,
      tab: activeTab === 'completed' ? 'dashboard' : 'open',
      searchQuery: '',
      filter: activeTab,
      timeRange,
      projectManagerId:
        authProfile && isPMOrSPM(authProfile) ? authProfile.id : undefined,
    },
    {
      skip: authProfile && isAdminOrSuperAdmin(authProfile),
      selectFromResult: ({ data, error, isFetching }) => ({
        projectsData: data ? data : [],
        getAllProjectsQueryError: error,
        isFetching,
      }),
    },
  );

  const { auth } = useContext(authContext);

  useEffect(() => {
    refreshInterval = setInterval(() => {
      refetchStats();
      hasPMPermission ? refetchProjects() : refetch();
    }, DASHBOARD_REFRESH_INTERVAL);
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
    }
  }, [auth]);

  useEffect(() => {
    if (
      authProfile &&
      !isAdminOrSuperAdmin(authProfile) &&
      !isPMOrSPM(authProfile)
    ) {
      navigate('/');
    }

    if (authProfile && isPMOrSPM(authProfile)) {
      setHasPMPermission(true);
      setSortBy('project.id');
      setSortType('DESC');
    }
  }, [authProfile]);

  useEffect(() => {
    hasPMPermission ? refetchProjects() : refetch();
  }, [sortBy, sortType, timeRange, activeTab]);

  useEffect(() => {
    setTimeRange(activeTab === 'completed' ? 'mtd' : '');
  }, [activeTab]);

  const isOpen = activeTab === 'open';

  const tabs = [
    {
      title: `Open Projects`,
      attribute: 'open',
    },
    {
      title: 'Completed Projects',
      attribute: 'completed',
    },
  ];

  const getColumns = () => {
    if (fetchingPMs || fetchingProjects) return [];
    if (hasPMPermission) {
      return isSPM(authProfile)
        ? PM_COLUMNS.OPEN
        : PM_COLUMNS.OPEN.filter((col) => col.attribute !== 'projectManager');
    } else {
      return isOpen
        ? [...ADMIN_COLUMNS.SHARED, ...ADMIN_COLUMNS.OPEN]
        : [...ADMIN_COLUMNS.SHARED, ...ADMIN_COLUMNS.COMPLETED];
    }
  };

  const getTooltipDODA = (project: Project) => {
    const startDays = getDaysForDate(new Date(project.start_date));
    const endDays = getDaysForDate(new Date(project.due_date));

    return `${project.days_open || 0}/${endDays - startDays}`;
  };

  const transformDataForTable = () => {
    if (hasPMPermission) {
      return projectsData.map(
        (project: Project) =>
          ({
            id: project.id.toString(),
            customer: project.customer?.company_name || '',
            address: project.address || '',
            projectManager: project.project_manager?.name || '',
            startDate: getFormattedDate(project.start_date),
            dueDate: getFormattedDate(project.due_date),
            contractAmount: formatAsMoney(project.contract_amount),
            datesProgression: (
              <ProgressBar progress={project.days_under_contract} />
            ),
            progress: (
              <ProgressBar progress={projectStatusValues[project.progress]} />
            ),
            invoice_number: project.invoice_number,
            dateProgressTooltip: getTooltipDODA(project),
          } as { [key: string]: string | JSX.Element }),
      );
    } else {
      return projectManagersData.map((pm) => {
        return {
          projectManager: `${pm.first_name[0]}. ${pm.last_name}`,
          projectManagerId: pm.id.toString() || '',
          primaryMarket: pm.market ? pm.market.name : '',
          openProjects: pm.openProjects.toString(),
          openRevenue: formatAsMoney(pm.openAmount),
          pastDue: pm.pastDue.toString(),
          over28Days: pm.over28Days.toString(),
          completedProjects: pm.completedProjects.toString(),
          completedRevenue: formatAsMoney(pm.completedAmount),
          onTimePercentage: `${pm.onTimePercentage || 0}%`,
        } as { [key: string]: string | JSX.Element };
      });
    }
  };

  const sortTableData = (category: string) => {
    setSortBy((prev) => {
      if (prev === category) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return category;
      }
      setSortType('ASC');
      return category;
    });
  };

  const timeRanges = ['mtd', 'qtd', 'ytd'];

  //Add previous years, starting from 2022 onwards
  const yearsToAdd = new Date().getUTCFullYear() - 2022;
  for (let i = 0; i < yearsToAdd; i++) {
    timeRanges.push(`${2022 + i}`);
  }

  timeRanges.push('all');

  const timeRangeLabels: { [key: string]: string } = {
    mtd: 'MTD (Month to Date)',
    qtd: 'QTD (Quarter to Date)',
    ytd: 'YTD (Year to Date)',
    all: 'Show All',
  };

  const renderTimeRangeDropdown = () =>
    !isOpen ? (
      <select
        value={timeRange}
        onChange={(e) => setTimeRange(e.target.value)}
        className="form-select lazuli-select"
      >
        {timeRanges.map((range) => (
          <option key={range} value={range}>
            {timeRangeLabels[range] || range}
          </option>
        ))}
      </select>
    ) : (
      <></>
    );

  return (
    <>
      <TableTabs
        mainActionButton={renderTimeRangeDropdown()}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Suspense
        fallback={
          <div style={{ minHeight: '130vh' }}>
            <Loader />
          </div>
        }
      >
        <div className="row mt-3 mx-1 d-flex justify-content-between">
          <DashboardCard
            title="Projects"
            bodyText={dashboardStats?.projects || 0}
            clickable
            onClick={() => {
              navigate(
                `/${
                  hasPMPermission ? 'pm' : 'admin'
                }/projects?filter=${activeTab}${
                  timeRange ? `&timeRange=${timeRange}` : ''
                }`,
              );
            }}
          />
          <DashboardCard
            title="Revenue"
            bodyText={`$${dashboardStats?.revenue || '0.00'}`}
          />
          {isOpen ? (
            <DashboardCard
              title="Past Due Projects"
              bodyText={dashboardStats?.pastDueProjects || 0}
              clickable
              onClick={() => {
                navigate(
                  `/${
                    hasPMPermission ? 'pm' : 'admin'
                  }/projects?filter=pastDue`,
                );
              }}
            />
          ) : (
            <DashboardCard title="GPM" bodyText={''} placeholder />
          )}
          <DashboardCard
            title={isOpen ? 'Over 28 Days Open' : 'On Time'}
            bodyText={
              isOpen
                ? dashboardStats?.openOver28Days || 0
                : `${dashboardStats?.onTimePercentage || 0}%`
            }
            clickable
            onClick={() => {
              navigate(
                `/${hasPMPermission ? 'pm' : 'admin'}/projects?filter=${
                  isOpen ? 'openOver28Days' : 'completedOnTime'
                }${timeRange ? `&timeRange=${timeRange}` : ''}`,
              );
            }}
          />
        </div>
        <DashboardTable
          isOpenSelected={activeTab === 'open'}
          isPMOrSPM={hasPMPermission}
          columns={getColumns()}
          sortBy={sortBy}
          sortType={sortType}
          sortFunction={sortTableData}
          data={transformDataForTable()}
          isLoading={fetchingProjects || fetchingPMs}
          activeTab={activeTab}
        />
      </Suspense>
    </>
  );
};

export default Dashboard;
