import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { UserForm } from '../../components/UserForms/UserForm';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import {
  useEditUserMutation,
  useGetAuthenticatedUserProfileQuery,
  useGetUserByIdQuery,
} from '../../redux/api/user.endpoints';
import {
  editToastData,
  EditUserDto,
  permissions,
  USER_ROLES,
} from '../../types/User';
import { handleEndpointResult } from '../../utils/api';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';

export const EditUser = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [editUser, editResult] = useEditUserMutation();

  const { userData } = useGetUserByIdQuery(searchParams.get('id') || '', {
    selectFromResult: ({ data }) => ({ userData: data ? data : undefined }),
  });

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile, refetch } = useGetAuthenticatedUserProfileQuery(
    userId,
    {
      skip: !userId,
    },
  );

  useEffect(() => {
    if (authProfile && userData) {
      if (
        authProfile.role !== USER_ROLES.ADMIN &&
        authProfile.role !== USER_ROLES.SUPER_ADMIN
      )
        navigate('/');
      else if (
        authProfile.role === USER_ROLES.ADMIN &&
        userData.role === USER_ROLES.SUPER_ADMIN
      )
        navigate('/admin/users');
    }
  }, [authProfile, userData]);

  const handleEditUser = (editUserDto: EditUserDto) => {
    editUser({
      ...editUserDto,
      SPMId: editUserDto.SPMId === 'none' ? '' : editUserDto.SPMId,
    });
  };

  useEffect(() => {
    if (!searchParams.get('id')) {
      navigate(-1);
    }
  }, [userData]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    if (editResult.isSuccess || editResult.isError) {
      let route = localStorage.getItem('renolink-return-to');
      if (route) localStorage.removeItem('renolink-return-to');
      else route = '/admin/users';
      handleEndpointResult(editToastData, editResult);
      if (editResult.isSuccess) navigate(route);
      else {
        localStorage.setItem('renolink-return-to', route);
      }
    }
  }, [editResult]);

  return authProfile ? (
    <>
      <Helmet>
        <title>Renolink - Edit User</title>
      </Helmet>
      <HeaderTab title="Edit User" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            {
              <UserForm
                permissions={permissions}
                authProfile={authProfile}
                isLoading={editResult && editResult.isLoading}
                handleEditSubmit={handleEditUser}
                userToEdit={userData}
              />
            }
          </main>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
