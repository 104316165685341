import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { authContext } from '../../../components/CognitoContextProvider/CognitoContextProvider';
import LoginImage from '../../../components/LoginImage/LoginImage';
import { RenolinkHeader } from '../../../components/RenolinkHeader/RenolinkHeader';
import {
  DISPLAY_TEXT,
  ERROR_BANNER,
  RENOLINK_HEADER,
} from '../../../utils/enums';
import { validateEmail } from '../../../utils/validations';

export const PasswordRecovery = () => {
  const { passwordRecovery } = useContext(authContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [displayErrorBanner, setDisplayErrorBanner] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email) {
      setDisplayErrorBanner(false);
      await passwordRecovery(email);
      navigate('/code-sent');
    } else {
      setDisplayErrorBanner(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Renolink - Password Recovery</title>
      </Helmet>
      <main className="container-fluid">
        <div className="row full-height">
          <LoginImage />
          <div className="col-md-6 px-3 px-md-4 px-lg-0 col-12 d-flex align-items-center justify-content-center bg-lazuli-white">
            <form onSubmit={handleSubmit}>
              <RenolinkHeader
                displayErrorBanner={displayErrorBanner}
                secondaryText={RENOLINK_HEADER.PASSWORD_RECOVERY}
                errorBannerText={ERROR_BANNER.PASSWORD_RECOVERY}
              />
              <div className="col-12 mb-4 mb-md-5 mt-3 px-4 px-md-5 d-flex flex-row text-center align-items-center justify-content-center">
                <p className="lazuli-grey forgot-password-description-txt">
                  {DISPLAY_TEXT.RECOVERY_EMAIL_MESSAGE}
                </p>
              </div>
              <div className="d-flex flex-column pb-5">
                <div className="form-group py-1 py-md-5 my-1 my-md-3 px-lg-4 mx-lg-4">
                  <label
                    htmlFor="email font-weight-bold lazuli-grey "
                    className="login-form-label-style"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className={`form-control lazuli-input ${
                      displayErrorBanner ? 'error' : ''
                    }`}
                    id="loginEmailInput"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    aria-label="forgot-password-email-input"
                  />
                </div>
              </div>
              <div className="py-4 px-lg-4 mx-lg-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block bg-lazuli-blue py-2 py-md-3 shadow-box"
                  style={{ fontWeight: '500' }}
                  disabled={!validateEmail(email)}
                  aria-label="forgot-password-submit-button"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};
