/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Modal from 'react-modal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  headerText: string;
  bodyText: string;
}

export const UploadingModal = ({
  isOpen,
  headerText,
  bodyText,
  closeModal,
}: Props) => {
  return (
    <div className="d-flex justify-content-center">
      <Modal
        style={{
          content: {
            background: 'rgba(255, 255, 255, 0.65)',
            border: 'none',
            padding: 'none',
            position: 'absolute',
            display: 'block',
            margin: '0',
            transform: 'translate(-50%, -30%)',
            width: '90%',
            maxWidth: '660px',
            inset: 'unset',
            overflow: 'initial',
            borderRadius: '14.1562px',
          },
        }}
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <div className="modal-content" style={{ borderRadius: '14.1562px' }}>
          <div className="modal-header d-flex justify-content-center py-4 border-0">
            <div className="modal-title px-3">
              <div className="row text-center">
                <h4
                  className="modal-title"
                  aria-label="confirmation-modal-header-text"
                >
                  {headerText}
                </h4>
              </div>
              <div className="row text-center">
                <p aria-label="confirmation-modal-secondary-text">{bodyText}</p>
              </div>
              <div className="row text-center">
                <div
                  className="spinner-border text-primary my-3"
                  style={{ width: '6rem', height: '6rem', margin: 'auto' }}
                  role="status"
                />
                <p>Uploading file...</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
