import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { ProjectForm } from '../../components/ProjectForm/ProjectForm';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import { useGetAllRawMarketsQuery } from '../../redux/api/market.endpoints';
import {
  useEditProjectMutation,
  useGetProjectByIdQuery,
} from '../../redux/api/project.endpoints';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import {
  useGetAuthenticatedUserProfileQuery,
  useGetProjectManagersQuery,
} from '../../redux/api/user.endpoints';
import { useGetAllCustomersQuery } from '../../redux/api/customer.endpoints';
import { useQuery } from '../../hooks/useQuery';
import { handleEndpointResult } from '../../utils/api';
import { EditProjectDto } from '../../types/Project';

export const EditProject = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const id = query.get('id');
  const { data } = useGetProjectByIdQuery(id || '');

  const { markets } = useGetAllRawMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({ markets: data ? data : [] }),
  });

  const { projectManagers } = useGetProjectManagersQuery(undefined, {
    selectFromResult: ({ data }) => ({ projectManagers: data ? data : [] }),
  });

  const { data: customers } = useGetAllCustomersQuery();
  const [editProject, editProjectResult] = useEditProjectMutation();

  const handleEditProject = (editProjectDto: EditProjectDto) => {
    editProject(editProjectDto);
  };

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile, refetch } = useGetAuthenticatedUserProfileQuery(
    userId,
    { skip: !userId },
  );

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    if (editProjectResult.isSuccess || editProjectResult.isError) {
      let route = localStorage.getItem('renolink-return-to');
      if (route) localStorage.removeItem('renolink-return-to');
      else route = '/admin/projects';
      handleEndpointResult(
        {
          toastErrorMsg: 'An error occurred while editing the Project.',
          toastId: 'edit-project-success',
          toastMsg: 'Project edited successfully!',
          toastErrorId: 'edit-project-error',
        },
        editProjectResult,
      );
      if (editProjectResult.isSuccess) navigate(route);
      else {
        localStorage.setItem('renolink-return-to', route);
      }
    }
  }, [editProjectResult]);

  return authProfile ? (
    <>
      <Helmet>
        <title>Renolink - Edit Project</title>
      </Helmet>
      <HeaderTab title="Edit Project" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 pb-2 mt-3 no-margin w-100">
          <main>
            {
              <ProjectForm
                handleEditSubmit={handleEditProject}
                markets={markets}
                projectManagers={projectManagers}
                customers={customers}
                isLoading={editProjectResult && editProjectResult.isLoading}
                projectToEdit={data}
                userId={authProfile?.id || 0}
              />
            }
          </main>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
