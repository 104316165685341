import { Dispatch, SetStateAction } from 'react';

interface Props {
  mainActionButton: JSX.Element;
  tabs: Array<{ title: string; attribute: string }>;
  activeTab?: string;
  setActiveTab?: Dispatch<SetStateAction<string>>;
}

const TableTabs = ({
  mainActionButton,
  tabs,
  activeTab,
  setActiveTab,
}: Props) => {
  return tabs.length > 0 ? (
    <nav className="navbar navbar-expand bg-white-and-shadowed p-0 table-tabs">
      <div className="container-fluid ps-0">
        <div className="collapse navbar-collapse d-flex justify-content-between">
          <ul className="navbar-nav me-auto col-8">
            {tabs.map((tab) => {
              return (
                <button
                  key={`${tab.attribute}`}
                  className={`col-auto p-0 px-1 p-md-2 nav-item cursor-pointer btn ${
                    activeTab === tab.attribute
                      ? 'active-table-tab'
                      : 'table-tab'
                  }`}
                  onClick={() => {
                    setActiveTab && setActiveTab(tab.attribute);
                  }}
                >
                  <p
                    className={`nav-link table-tab-font font-weight-bold my-2 my-md-0 px-1`}
                  >
                    {tab.title}
                  </p>
                </button>
              );
            })}
          </ul>
          <div className="col-4 col-md-auto button d-flex flex-row-reverse me-md-4">
            {mainActionButton}
          </div>
        </div>
      </div>
    </nav>
  ) : (
    <></>
  );
};

export default TableTabs;
