import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Market } from '../../types/Markets';
import { User } from '../../types/User';
import { ACTION_CONFIRMATION_MODAL, projectStatuses } from '../../utils/enums';
import { ActionConfirmationModal } from '../Modals/ActionConfirmationModal/ActionConfirmationModal';
import { CreateProjectDto, EditProjectDto, Project } from '../../types/Project';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import { getFormattedDate } from '../../utils/table';
import { Customer } from '../../types/Customer';
import StateDropdown from '../Dropdowns/StateDropdown';

const formLabelStyle = {
  fontWeight: '500',
  color: '#394a64',
  fontSize: '1.1rem',
};

interface Props {
  handleCreateSubmit?: (createProjectDto: CreateProjectDto) => void;
  handleEditSubmit?: (editProjectDto: EditProjectDto) => void;
  markets: Market[];
  projectManagers: User[];
  customers: Customer[] | undefined;
  isLoading: boolean;
  userId: number;
  projectToEdit?: Project;
}

export const ProjectForm = ({
  handleCreateSubmit,
  handleEditSubmit,
  markets,
  projectManagers,
  customers,
  isLoading,
  userId,
  projectToEdit,
}: Props) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('cancel');
  const [address, setAddress] = useState(
    projectToEdit ? projectToEdit.address : '',
  );
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [marketData, setMarketData] = useState({ id: '', name: '' });
  const [customerData, setCustomerData] = useState({ id: '', name: '' });
  const [managerData, setManagerData] = useState({ id: '', name: '' });
  const [contractAmount, setContractAmount] = useState('');
  const [changeOrder, setChangeOrder] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [minDueDate, setMinDueDate] = useState(
    new Date(new Date(startDate).setDate(startDate.getDate() + 1)),
  );
  const [dueDate, setDueDate] = useState(minDueDate);
  const [orderDate, setOrderDate] = useState(new Date());
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [poNumber, setPONumber] = useState('');
  const [progress, setProgress] = useState(
    projectToEdit ? projectToEdit.progress : projectStatuses.NOT_STARTED,
  );
  const [zipError, setZipError] = useState(false);

  useEffect(() => {
    if (projectToEdit) {
      setAddress(projectToEdit.address);
      setCity(projectToEdit.city);
      setState(projectToEdit.state);
      setZip(projectToEdit.zip);
      projectToEdit.market &&
        setMarketData({
          id: `${projectToEdit.market.id}`,
          name: projectToEdit.market.name,
        });
      projectToEdit.customer &&
        setCustomerData({
          id: `${projectToEdit.customer.id}`,
          name: projectToEdit.customer.company_name,
        });
      projectToEdit.project_manager &&
        setManagerData({
          id: `${projectToEdit.project_manager.id}`,
          name: projectToEdit.project_manager.name,
        });
      setContractAmount(`${projectToEdit.contract_amount}`);
      const newStartDate = new Date(getFormattedDate(projectToEdit.start_date));
      setStartDate(newStartDate);
      setOrderDate(new Date(getFormattedDate(projectToEdit.order_date)));
      setDueDate(new Date(getFormattedDate(projectToEdit.due_date)));
      const newMinDueDate = new Date(newStartDate);
      newMinDueDate.setDate(newStartDate.getDate() + 1);
      setMinDueDate(newMinDueDate);
      setProgress(projectToEdit.progress);
      setChangeOrder(projectToEdit.change_order);
      setPONumber(projectToEdit.po_number || '');
      setInvoiceNumber(projectToEdit.invoice_number || '');
    }
  }, [projectToEdit]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();

    const zipHasError = !zip.trim() || zip.trim().length < 5;
    if (zipHasError) {
      setZipError(zipHasError);
    } else {
      const payload = {
        address,
        city,
        state,
        zip,
        marketId: marketData.id,
        customer_id: customerData.id,
        customer_name: customerData.name,
        managerId: managerData.id,
        contractAmount,
        changeOrder,
        startDate: getFormattedDate(startDate),
        dueDate: getFormattedDate(dueDate),
        orderDate: getFormattedDate(orderDate),
        progress,
        creatorId: userId,
        po_number: poNumber,
        invoice_number: invoiceNumber,
      };

      if (projectToEdit) {
        handleEditSubmit &&
          handleEditSubmit({ id: projectToEdit?.id || 0, ...payload });
      } else {
        handleCreateSubmit && handleCreateSubmit(payload);
      }
    }
  };

  const getModalHeader = () => {
    if (modalAction === 'complete')
      return ACTION_CONFIRMATION_MODAL.COMPLETE_PROJECT_HEADER;

    if (modalAction === 'invoice')
      return ACTION_CONFIRMATION_MODAL.INVOICE_PROJECT_HEADER;

    if (modalAction === 'cancel' && projectToEdit)
      return ACTION_CONFIRMATION_MODAL.EDIT_PROJECT_HEADER;

    return ACTION_CONFIRMATION_MODAL.CREATE_PROJECT_HEADER;
  };

  const getModalSecondary = () => {
    if (modalAction === 'complete')
      return ACTION_CONFIRMATION_MODAL.COMPLETE_PROJECT_SECONDARY;

    if (modalAction === 'invoice')
      return ACTION_CONFIRMATION_MODAL.INVOICE_PROJECT_SECONDARY;

    if (modalAction === 'cancel' && projectToEdit)
      return ACTION_CONFIRMATION_MODAL.EDIT_PROJECT_SECONDARY;

    return ACTION_CONFIRMATION_MODAL.CREATE_PROJECT_SECONDARY;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (action: string) => {
    setModalAction(action);
    setOpenModal(true);
  };

  const goBack = () => {
    let route = localStorage.getItem('renolink-return-to');
    if (route) localStorage.removeItem('renolink-return-to');
    else route = '/admin/projects';
    navigate(route);
  };

  const handleProgressChange = () => {
    if (modalAction === 'complete') setProgress(projectStatuses.COMPLETE);
    else if (modalAction === 'invoice') setProgress(projectStatuses.INVOICED);
    handleCloseModal();
  };

  const getDaysAllotted = () => {
    const msBetweenDates =
      dueDate && startDate ? dueDate.getTime() - startDate.getTime() : 0;

    //convert ms to days                 hour   min  sec   ms
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
    return Math.round(daysBetweenDates);
  };

  const getProgressSelectValues = () => {
    let key: keyof typeof projectStatuses;
    const progressValues = [];
    for (key in projectStatuses) {
      progressValues.push(projectStatuses[key]);
    }
    return progressValues;
  };

  const handleStartDateChange = (date: Date) => {
    if (date) {
      setStartDate(date);
      const newMinDueDate = new Date(date);
      newMinDueDate.setDate(date.getDate() + 1);
      setMinDueDate(newMinDueDate);

      if (date >= dueDate) {
        const newDueDate = new Date(date);
        newDueDate.setDate(date.getDate() + 1);
        setDueDate(newDueDate);
      }
    }
  };

  const handleOrderDateChange = (date: Date) => {
    if (date) {
      setOrderDate(date);
      if (startDate.getTime() < date.getTime()) {
        setStartDate(date);
        const newDueDate = new Date();
        newDueDate.setDate(date.getDate() + 1);
        setMinDueDate(newDueDate);
        if (date >= dueDate) setDueDate(newDueDate);
      }
    }
  };

  const isSubmitDisabled =
    isLoading ||
    zipError ||
    !address ||
    !customerData.id ||
    !marketData.id ||
    !managerData.id ||
    !dueDate ||
    !startDate ||
    !orderDate ||
    !state ||
    !city ||
    !zip ||
    !contractAmount;

  return (
    <>
      <div className="flex-grow-1 form-container pt-1">
        <h2
          aria-label="edit-project-title"
          className="text-center font-weight-bold"
        >
          {projectToEdit ? 'Edit' : 'Create'} Project
        </h2>
        <form id="create-project-form px-5" onSubmit={handleSubmit}>
          <div className="row py-3 d-flex justify-content-center">
            <div className="form-group col-11 col-md-5 mx-2 my-2">
              <label
                htmlFor="font-weight-bold lazuli-grey"
                style={formLabelStyle}
                className="d-flex flex-row"
              >
                <p className="mb-0">Market</p>
                <p className="ms-1 mb-0 text-error">*</p>
              </label>
              <select
                className={`form-select lazuli-select ${
                  !marketData.id ? 'lazuli-grey' : ''
                }`}
                value={marketData.id}
                onChange={(e) => {
                  const market = markets.find(
                    (market) => market.id.toString() === e.target.value,
                  );
                  setMarketData({
                    id: market?.id.toString() || '',
                    name: market?.name || '',
                  });
                }}
              >
                <option value={''} disabled={true} hidden={true}>
                  Select a Market:
                </option>
                {markets.map((market) => (
                  <option value={market.id} key={market.id}>
                    {market.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-11 col-md-5 mx-2 mt-0 mb-3 my-md-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
                className="d-flex flex-row"
              >
                <p className="mb-0">Project Manager</p>
                <p className="ms-1 mb-0 text-error">*</p>
              </label>
              <select
                className={`form-select lazuli-select ${
                  !managerData.id ? 'lazuli-grey' : ''
                }`}
                value={managerData.id}
                onChange={(e) => {
                  const manager = projectManagers.find(
                    (manager) => manager.id.toString() === e.target.value,
                  );
                  setManagerData({
                    id: manager?.id.toString() || '',
                    name: manager?.name || '',
                  });
                }}
              >
                <option value={''} hidden={true}>
                  Select a Project Manager:
                </option>
                {projectManagers.map((manager) => (
                  <option value={manager.id} key={manager.id}>
                    {manager.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-11 col-md-5 mx-2 my-0 my-md-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
                className="d-flex flex-row"
              >
                <p className="mb-0">Customer</p>
                <p className="ms-1 mb-0 text-error">*</p>
              </label>
              <select
                className={`form-select lazuli-select ${
                  !customerData.id ? 'lazuli-grey' : ''
                }`}
                value={customerData.id}
                onChange={(e) => {
                  const customer = customers?.find(
                    (customer) => customer.id.toString() === e.target.value,
                  );
                  setCustomerData({
                    id: customer?.id.toString() || '',
                    name: customer?.company_name || '',
                  });
                  setAddress(
                    customer?.primary_address?.substring(0, 100) || '',
                  );
                  setCity(customer?.city || '');
                  setState(customer?.state || '');
                  setZip(customer?.zip || '');
                }}
              >
                <option value={''} hidden={true}>
                  Select a Customer:
                </option>
                {customers?.map((customer) => (
                  <option value={customer.id} key={customer.id}>
                    {customer.company_name}
                  </option>
                ))}
              </select>
            </div>
            {projectToEdit ? (
              <div className="form-group col-11 col-md-5 mx-2 mt-3 mb-0 my-md-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Project Status</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>
                <select
                  className="form-select lazuli-select"
                  value={progress}
                  onChange={(e) => {
                    const progressValue = e.target.value;
                    if (progressValue === projectStatuses.COMPLETE) {
                      handleOpenModal('complete');
                    } else if (progressValue === projectStatuses.INVOICED) {
                      handleOpenModal('invoice');
                    } else {
                      setProgress(progressValue);
                    }
                  }}
                >
                  <option value={''} disabled={true} hidden={true}>
                    Select Project Progress:
                  </option>
                  {getProgressSelectValues().map((progress) => (
                    <option
                      disabled={
                        projectToEdit &&
                        projectToEdit.progress !== projectStatuses.COMPLETE &&
                        progress === projectStatuses.INVOICED
                      }
                      value={progress}
                      key={progress}
                    >
                      {progress}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="col-0 col-md-5 mx-md-2" />
            )}
          </div>
          <div className="row d-flex justify-content-center">
            <div className="form-group col-11 col-md-5 mb-3 mb-md-0 mx-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
                className="d-flex flex-row"
              >
                <p className="mb-0">Address</p>
                <p className="ms-1 mb-0 text-error">*</p>
              </label>
              <input
                type="text"
                className="form-control lazuli-input-border-medium"
                id="addressId"
                aria-describedby="AddressHelp"
                value={address}
                placeholder="Address"
                onChange={(e) => {
                  let addressValue = e.target.value;
                  if (addressValue.length > 100)
                    addressValue = addressValue.substring(0, 100);
                  setAddress(addressValue);
                }}
                aria-label="address-input"
              />
            </div>
            <div className="row col-12 col-md-5 mx-2">
              <div className="form-group col-12 col-md-5 my-0 mb-md-3">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">City</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>
                <input
                  type="text"
                  className="form-control lazuli-input-border-medium"
                  id="cityId"
                  aria-describedby="CityHelp"
                  value={city}
                  placeholder="City"
                  onChange={(e) => {
                    let cityValue = e.target.value;
                    if (cityValue.length > 25)
                      cityValue = cityValue.substring(0, 25);
                    setCity(cityValue);
                  }}
                  aria-label="last-name-input"
                />
              </div>
              <div className="form-group col-8 col-md-5 mt-3 mt-md-0 mb-3 pe-0 pe-md-2">
                <StateDropdown
                  label={
                    <label
                      htmlFor="font-weight-bold lazuli-grey "
                      style={formLabelStyle}
                      className="d-flex flex-row"
                    >
                      <p className="mb-0">State</p>
                      <p className="ms-1 mb-0 text-error">*</p>
                    </label>
                  }
                  setState={setState}
                  value={state}
                />
              </div>
              <div className="form-group col-4 col-md-2 mt-3 mt-md-0 mb-3 ps-md-2 pe-3 pe-md-0">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Zip</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>
                <input
                  type="text"
                  className={`form-control lazuli-input-border-medium number-input-no-arrows ${
                    zipError ? 'error' : ''
                  }`}
                  id="zipId"
                  aria-describedby="ZipHelp"
                  value={zip}
                  placeholder={'00000'}
                  onChange={(e) => {
                    if (zipError) setZipError(false);
                    let zipValue = e.target.value.replace(/[^0-9]/g, '');
                    if (zipValue.length > 5)
                      zipValue = zipValue.substring(0, 5);
                    setZip(zipValue);
                  }}
                  aria-label="zip-input"
                />
                {zipError && (
                  <p className="text-error mt-1 ms-2">
                    {!zip ? 'Zip can not be empty' : 'Invalid Zip Code'}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="row col-10 px-0">
              {/* datepickers */}
              <div className="form-group col-12 col-md-4 ps-0 pe-0 pe-md-2 my-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Order Date</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>
                <div className="position-relative">
                  <DatePicker
                    selected={orderDate}
                    onChange={handleOrderDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="form-control lazuli-input-border-medium be-0 col-12"
                  />
                  <i className="icon-Calendar date-input-icon" />
                </div>
              </div>
              <div className="form-group col-12 col-md-4 ps-0 ps-md-2 pe-0 pe-md-2 my-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Start Date</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>
                <div className="position-relative">
                  <DatePicker
                    selected={startDate}
                    minDate={orderDate}
                    onChange={handleStartDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="form-control lazuli-input-border-medium"
                  />
                  <i className="icon-Calendar date-input-icon" />
                </div>
              </div>
              <div className="form-group col-12 col-md-4 pe-0 ps-0 ps-md-2 my-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Due Date</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>

                <div className="position-relative">
                  <DatePicker
                    selected={dueDate}
                    onChange={(date: Date) => setDueDate(date)}
                    minDate={minDueDate}
                    dateFormat="MM/dd/yyyy"
                    className="form-control lazuli-input-border-medium"
                  />
                  <i className="icon-Calendar date-input-icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="row py-0 pt-md-3 d-flex justify-content-center">
            <div className="row col-10 col-md-10 px-0 px-md-2">
              <div className="form-group col-12 col-md-4 ps-0 mt-0 pe-0 pe-md-2">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Days Allotted</p>
                </label>
                <input
                  className="form-control lazuli-input-border-medium"
                  type="text"
                  disabled={true}
                  value={getDaysAllotted()}
                />
              </div>
              <div className="form-group col-12 col-md-4 px-0 px-md-2 my-3 my-md-0">
                <label
                  htmlFor="font-weight-bold lazuli-grey "
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Contract Amount</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>
                <CurrencyInput
                  className="form-control lazuli-input-border-medium"
                  prefix="$"
                  placeholder="$0.00"
                  allowNegativeValue={false}
                  decimalsLimit={2}
                  decimalScale={2}
                  maxLength={11}
                  onValueChange={(value) => {
                    const newValue = value || '';
                    const valueParts = newValue.split('.');
                    if (valueParts[0].length > 8)
                      valueParts[0] = valueParts[0].substring(0, 8);
                    setContractAmount(valueParts.join('.'));
                  }}
                  value={contractAmount}
                />
              </div>

              <div className="form-group col-10 col-md-4 px-0 px-md-2 my-0 mb-md-3">
                <label
                  htmlFor="font-weight-bold lazuli-grey"
                  style={formLabelStyle}
                  className="d-flex flex-row"
                >
                  <p className="mb-0">Is this a change order?</p>
                  <p className="ms-1 mb-0 text-error">*</p>
                </label>
                <div className="row">
                  <div
                    className="col-6 col-md-6 col-lg-3 ps-0 ps-md-2 py-3 d-flex flex-row"
                    onClick={() => setChangeOrder(true)}
                    onKeyDown={(e) => e.key === ' ' && setChangeOrder(true)}
                    tabIndex={0}
                    role="button"
                  >
                    <div className="col-3 col-md-6 me-2 me-md-3 me-lg-2">
                      <input
                        type="radio"
                        className="form-check-input mx-3"
                        checked={changeOrder}
                        onChange={() => setChangeOrder(true)}
                      />
                    </div>
                    <div className="col-6 ms-2">Yes</div>
                  </div>
                  <div
                    className="col-6 col-md-6 col-lg-3 ps-0 ps-md-2 py-3 d-flex flex-row"
                    onClick={() => setChangeOrder(false)}
                    onKeyDown={(e) => e.key === ' ' && setChangeOrder(false)}
                    tabIndex={0}
                    role="button"
                  >
                    <div className="col-3 col-md-6 me-2 me-md-3 me-lg-2">
                      <input
                        type="radio"
                        className="form-check-input mx-3"
                        checked={!changeOrder}
                        onChange={() => setChangeOrder(false)}
                      />
                    </div>
                    <div className="col-6 ms-2">No</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-3 d-flex justify-content-center">
            <div className="form-group col-11 col-md-5 mx-2 my-2">
              <label
                htmlFor="font-weight-bold lazuli-grey"
                style={formLabelStyle}
                className="d-flex flex-row"
              >
                <p className="mb-0">PO Number</p>
              </label>
              <input
                className="form-control lazuli-input-border-medium"
                type="text"
                value={poNumber}
                maxLength={50}
                onChange={(e) => setPONumber(e.target.value || '')}
              />
            </div>
            <div className="form-group col-11 col-md-5 mx-2 mt-0 mb-3 my-md-2">
              <label
                htmlFor="font-weight-bold lazuli-grey "
                style={formLabelStyle}
                className="d-flex flex-row"
              >
                <p className="mb-0">Invoice Number</p>
              </label>
              <input
                className="form-control lazuli-input-border-medium"
                type="text"
                value={invoiceNumber}
                maxLength={50}
                onChange={(e) => setInvoiceNumber(e.target.value || '')}
              />
            </div>
          </div>
        </form>
        <div className="row my-2">
          <div className="col-12 justify-content-center row mx-0">
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center lazuli-light-blue-border lazuli-button-medium"
                onClick={() => handleOpenModal('cancel')}
              >
                <span
                  className={`font-weight-bold ${
                    isMobileOnly ? 'font-14' : 'font-18'
                  }`}
                >
                  Cancel
                </span>
              </button>
            </div>
            <div className="col-5 col-md-4 col-lg-2 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-primary btn-block d-flex justify-content-center align-items-center bg-lazuli-blue lazuli-button-medium"
                disabled={isSubmitDisabled}
                aria-label="create-project-submit-button"
                form="create-project-form"
                onClick={() => handleSubmit()}
              >
                <span
                  className={`font-weight-bold ${
                    isMobileOnly ? 'font-14' : 'font-18'
                  }`}
                >
                  {projectToEdit ? 'Save' : 'Create'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getModalHeader()}
        secondaryText={getModalSecondary()}
        primaryAction={handleCloseModal}
        secondaryAction={
          modalAction === 'complete' || modalAction === 'invoice'
            ? handleProgressChange
            : goBack
        }
      />
    </>
  );
};
