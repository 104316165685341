import { isDesktop, isMobile, isTablet } from 'react-device-detect';

interface Props {
  title: string;
  bodyText: string | number;
  clickable?: boolean;
  onClick?: () => void;
  placeholder?: boolean;
}

const DashboardCard = ({
  title,
  bodyText,
  clickable = false,
  onClick,
  placeholder,
}: Props) => {
  const styles =
    isDesktop || isTablet
      ? { width: !isMobile && !isTablet ? '24%' : '49%', flex: '0 0 auto' }
      : {};

  return (
    <div
      className={`col-12 col-md-auto py-2 px-3 my-1 ${
        isTablet ? '' : 'my-lg-0'
      } bg-white-and-shadowed dashboard-widgets ${
        clickable ? 'cursor-pointer' : ''
      } ${bodyText === '' ? 'dashboard-card-disable' : ''}`}
      style={styles}
      onClick={onClick}
      role="button"
      onKeyDown={(e) => e.preventDefault()}
      tabIndex={0}
    >
      <div>
        <p className="dashboard-card-title">{title}</p>
        <hr className="admin-header-separator mt-1 mb-1 mb-md-3" />
      </div>
      <div className="mt-0 mt-md-4 row d-flex justify-content-between">
        <p
          className={`col-auto dashboard-card-body-text ${
            placeholder ? 'lazuli-grey-text' : ''
          }`}
        >
          {bodyText}
        </p>
        {clickable && (
          <div className="col-auto pt-0 pt-md-2">
            <i
              className="icon-Upper-Right-Arrow dashboard-card-icon"
              style={{ color: '#394A64' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
