import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import CreateMarketForm from '../../components/MarketForms/CreateMarketForm';
import { useCreateMarketMutation } from '../../redux/api/market.endpoints';
import { CreateMarketDto } from '../../types/Markets';

const CreateMarket = () => {
  const navigate = useNavigate();
  const [createMarket, createMarketResult] = useCreateMarketMutation();
  const [duplicateNameError, setDuplicateNameError] = useState(false);

  useEffect(() => {
    if (createMarketResult && createMarketResult.isSuccess) {
      setDuplicateNameError(false);
      toast.success('Market created successfully!', {
        toastId: 'create-market-success',
      });
      navigate('/admin/markets');
    } else if (createMarketResult && createMarketResult.isError) {
      const errorResult = createMarketResult.error as FetchBaseQueryError;
      const data = errorResult.data as unknown as { error: string };
      toast.error(
        data && data.error
          ? data.error
          : 'An error occurred while creating the market.',
        { toastId: 'create-market-error' },
      );
      if (
        data &&
        data.error &&
        data.error.includes('A market with this name already exists.')
      ) {
        setDuplicateNameError(true);
      }
    }
  }, [createMarketResult]);

  const handleCreateMarket = (createMarketDto: CreateMarketDto) => {
    createMarket(createMarketDto);
  };

  return (
    <>
      <Helmet>
        <title>Renolink - Create Market</title>
      </Helmet>
      <HeaderTab title="Create New Market" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            <CreateMarketForm
              handleCreateMarket={handleCreateMarket}
              isLoading={createMarketResult && createMarketResult.isLoading}
              duplicateNameError={duplicateNameError}
              setDuplicateNameError={setDuplicateNameError}
            />
          </main>
        </div>
      </div>
    </>
  );
};

export default CreateMarket;
