import React from 'react';

interface Props {
  progress: number;
}

const ProgressBar = ({ progress }: Props) => {
  const getProgressColor = () => {
    if (progress > 100) {
      return 'bg-lazuli-red';
    } else if (progress === 100) {
      return 'bg-lazuli-green';
    }

    return 'bg-lazuli-light-blue';
  };

  return (
    <div className="text-center d-flex flex-column justify-content-center me-4">
      <p className="font-semibold" style={{ marginBottom: '0' }}>
        {progress}%
      </p>
      <div className="justify-content-center d-flex">
        <div className="progress" style={{ width: '4rem', maxWidth: '4rem' }}>
          <div
            className={`progress-bar ${getProgressColor()}`}
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
