import { Dispatch, SetStateAction } from 'react';

interface Props {
  totalPages: number;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
}

const Pagination = ({ totalPages, activePage, setActivePage }: Props) => {
  const onChangePage = (newPage: number) => setActivePage(newPage);

  const onClickPrevious = () => setActivePage(activePage - 1);

  const onClickNext = () => setActivePage(activePage + 1);

  const renderPages = () => {
    const pagesElements = [];
    let outOfRange = false;
    for (let i = 1; i <= totalPages; i++) {
      if (i <= 1 || i >= totalPages - 1 || Math.abs(i - activePage) <= 1) {
        outOfRange = false;
        pagesElements.push(
          <li
            id={`table-pagination-page-${i}`}
            key={`table-pagination-page-${i}`}
            className={`page-item ${activePage === i ? 'active' : ''}`}
          >
            <button className="page-link" onClick={() => onChangePage(i)}>
              {i}
            </button>
          </li>,
        );
      } else {
        if (!outOfRange) {
          pagesElements.push(
            <li
              id={`table-pagination-page-ellipsis-${i}`}
              key={`table-pagination-page-ellipsis-${i}`}
              className={`page-item`}
            >
              <button className="page-link">...</button>
            </li>,
          );
        }
        outOfRange = true;
      }
    }
    return pagesElements;
  };

  return (
    <nav className="mobile-fixed-pagination">
      <ul className="pagination justify-content-center mb-1">
        <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
          <button
            className="page-link font-weight-bold text-black"
            onClick={onClickPrevious}
          >{`<`}</button>
        </li>
        {renderPages()}
        <li
          className={`page-item ${activePage === totalPages ? 'disabled' : ''}`}
        >
          <button
            className="page-link font-weight-bold text-black"
            onClick={onClickNext}
          >{`>`}</button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
