import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { ProjectForm } from '../../components/ProjectForm/ProjectForm';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import { useGetAllRawMarketsQuery } from '../../redux/api/market.endpoints';
import { useCreateProjectMutation } from '../../redux/api/project.endpoints';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import {
  useGetAuthenticatedUserProfileQuery,
  useGetProjectManagersQuery,
} from '../../redux/api/user.endpoints';
import { useGetAllCustomersQuery } from '../../redux/api/customer.endpoints';
import { handleEndpointResult } from '../../utils/api';
import { CreateProjectDto } from '../../types/Project';

export const CreateProject = () => {
  const navigate = useNavigate();
  const { markets } = useGetAllRawMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({ markets: data ? data : [] }),
  });

  const { projectManagers } = useGetProjectManagersQuery(undefined, {
    selectFromResult: ({ data }) => ({ projectManagers: data ? data : [] }),
  });

  const { data: customers } = useGetAllCustomersQuery();

  const [createProject, createProjectResult] = useCreateProjectMutation();

  const handleCreateProject = (createProjectDto: CreateProjectDto) => {
    createProject(createProjectDto);
  };

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile, refetch } = useGetAuthenticatedUserProfileQuery(
    userId,
    { skip: !userId },
  );

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    handleEndpointResult(
      {
        toastErrorMsg: 'An error occurred while creating the Project.',
        toastId: 'create-project-success',
        toastMsg: 'Project created successfully!',
        toastErrorId: 'create-project-error',
      },
      createProjectResult,
      () => navigate('/admin/projects'),
    );
  }, [createProjectResult]);

  return authProfile ? (
    <>
      <Helmet>
        <title>Renolink - Create Project</title>
      </Helmet>
      <HeaderTab title="Create New Project" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            {
              <ProjectForm
                handleCreateSubmit={handleCreateProject}
                markets={markets}
                projectManagers={projectManagers}
                customers={customers}
                isLoading={createProjectResult && createProjectResult.isLoading}
                userId={authProfile?.id || 0}
              />
            }
          </main>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
