import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { handleEndpointResult } from '../../utils/api';
import { useSearchParams } from 'react-router-dom';
import { EditCustomerForm } from '../../components/EditCustomerForm/EditCustomerForm';
import {
  useEditCustomerMutation,
  useGetCustomerByIdQuery,
} from '../../redux/api/customer.endpoints';
import { EditCustomerDto, editToastData } from '../../types/Customer';

export const EditCustomer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [editCustomer, editCustomerResult] = useEditCustomerMutation();

  const handleEditCustomer = (editCustomerDto: EditCustomerDto) => {
    editCustomer(editCustomerDto);
  };

  const { customerData, refetch: refetchCustomer } = useGetCustomerByIdQuery(
    searchParams.get('id') || '',
    {
      selectFromResult: ({ data }) => ({
        customerData: data ? data : undefined,
      }),
    },
  );

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile, refetch } = useGetAuthenticatedUserProfileQuery(
    userId,
    { skip: !userId },
  );

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    if (editCustomerResult.isSuccess || editCustomerResult.isError) {
      let route = localStorage.getItem('renolink-return-to');
      if (route) localStorage.removeItem('renolink-return-to');
      else route = '/admin/customers';

      handleEndpointResult(editToastData, editCustomerResult);
      if (editCustomerResult.isSuccess) navigate(route);
      else {
        localStorage.setItem('renolink-return-to', route);
      }
    }
  }, [editCustomerResult]);

  useEffect(() => {
    refetchCustomer();
  }, []);

  return authProfile ? (
    <>
      <Helmet>
        <title>Renolink - Edit Customer</title>
      </Helmet>
      <HeaderTab title="Edit Customer" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            {
              <EditCustomerForm
                customerData={customerData}
                handleEditCustomer={handleEditCustomer}
                isLoading={editCustomerResult && editCustomerResult.isLoading}
                authProfile={authProfile}
              />
            }
          </main>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
