import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SortDataButton } from '../Buttons/SortData/SortDataButton';
import { CSVLink } from 'react-csv';
import Pagination from '../Pagination/Pagination';
import TableTabs from './TableTabs';
import { useNavigate } from 'react-router';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { ColumnProps } from '../../utils/table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/*eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  tableName: string;
  tabs: Array<{ title: string; attribute: string }>;
  activeTab?: string;
  setActiveTab?: Dispatch<SetStateAction<string>>;
  columns: Array<ColumnProps>;
  data: Array<{ [key: string]: string | JSX.Element | any }>;
  totalData: number;
  isFetchingData: boolean;
  mainActionButton: JSX.Element;
  sortFunction: (category: string) => void;
  sortBy: string;
  sortType: 'ASC' | 'DESC';
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
  itemsPerPage: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  renderContextMenu: (arg: number) => JSX.Element;
  handleSearch: (query: string) => void;
  csvData?: {
    data: Array<any>;
    headers: Array<{ key: string; label: string }>;
    filename: string;
  };
  fullSize?: boolean;
  readOnly?: boolean;
  isPMOrSPMRoutes?: boolean;
  isSPMView?: boolean;
  hideHeader?: boolean;
  hideSearchBar?: boolean;
  actionButton?: JSX.Element;
  projectTypeFilter?: string;
  setProjectTypeFilter?: (val: 'ALL' | 'SPM' | 'PM') => void;
}
/*eslint-enable */

const Table = ({
  tableName,
  tabs,
  activeTab,
  setActiveTab,
  columns,
  data,
  totalData,
  isFetchingData,
  mainActionButton,
  sortFunction,
  sortBy,
  sortType,
  itemsPerPage,
  setItemsPerPage,
  activePage,
  setActivePage,
  renderContextMenu,
  handleSearch,
  csvData,
  fullSize,
  readOnly,
  isPMOrSPMRoutes,
  actionButton,
  hideHeader = false,
  hideSearchBar = false,
  projectTypeFilter,
  setProjectTypeFilter,
  isSPMView,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (activePage > 1 && !isFetchingData && data.length === 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [data]);

  const renderTableHeaders = () => {
    return (
      <tr>
        {columns.map((column) => {
          return (
            <th
              scope="col text-center"
              key={`table-column-${column.attribute}`}
              className="py-0 pb-md-2"
              style={{ minWidth: column.minWidth ? column.minWidth : '' }}
            >
              <SortDataButton
                handleClick={sortFunction}
                title={column.title}
                toggled={
                  sortBy ===
                  (column.sortValue ? column.sortValue : column.attribute)
                }
                sortType={sortType}
                sortProperty={
                  column.sortValue ? column.sortValue : column.attribute
                }
                tooltip={column.tooltip}
                centerHeader={column.textCenter && tableName !== 'Actions'}
              />
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return data.map((item, index) => {
      return (
        <tr
          key={`table-data-${index}-${item.id}`}
          style={{
            height: '10px !important',
            lineHeight: tableName === 'Actions' ? '3rem' : '',
          }}
          className={`mx-2 ${readOnly ? '' : 'cursor-pointer'}`}
        >
          {columns.map((column) => {
            const columnData = (
              <td
                key={`table-data-${index}-${column.attribute}`}
                style={{
                  paddingTop: '0',
                  paddingBottom: '0',
                  minWidth: column.minWidth || '',
                  maxWidth: column.maxWidth || '',
                  textAlign: column.textCenter ? 'center' : 'left',
                  color: item[column.attribute] === 'Active' ? '#2897ff' : '',
                  verticalAlign: 'middle',
                  width: column.width || '',
                  textDecoration:
                    isMobile && column.attribute === 'datesProgression'
                      ? 'underline'
                      : '',
                }}
                className={`text-truncate ${column.classes || ''}`}
                //eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role={readOnly ? undefined : 'button'}
                tabIndex={0}
                onClick={
                  readOnly ||
                  (isMobile && column.attribute === 'datesProgression')
                    ? undefined
                    : () =>
                        navigate(
                          `/${
                            isPMOrSPMRoutes ? 'pm' : 'admin'
                          }/${tableName}/details?id=${item.id}`,
                        )
                }
              >
                {column.attribute === 'id' ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ height: '3rem' }}
                  >
                    {item[column.attribute]}
                  </div>
                ) : (
                  <>{item[column.attribute]}</>
                )}
              </td>
            );

            return column.hasTooltip ? (
              <OverlayTrigger
                placement="top"
                trigger={['click', 'hover']}
                overlay={(props) =>
                  item.dateProgressTooltip ||
                  (item[column.attribute] &&
                    item[column.attribute].length > 50) ? (
                    <Tooltip
                      id={`tooltip-${tableName}-${index}`}
                      className={`table-tooltip ${
                        !item.dateProgressTooltip ? 'wide-tooltip' : ''
                      }`}
                      aria-multiline={false}
                      {...props}
                    >
                      {item.dateProgressTooltip
                        ? item.dateProgressTooltip
                        : item[column.attribute]}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                {columnData}
              </OverlayTrigger>
            ) : (
              columnData
            );
          })}
          {!isPMOrSPMRoutes && (
            <td
              style={{ paddingTop: '0', paddingBottom: '0' }}
              className="action-menu"
            >
              {renderContextMenu(item.id)}
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <>
      <TableTabs
        mainActionButton={mainActionButton}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div
        className={`mt-3 ${
          fullSize ? 'w-100 h-100' : ''
        } rounded bg-white-and-shadowed p-2 px-3 p-md-4 px-md-5`}
        style={{ overflowY: 'auto' }}
      >
        {hideHeader || (
          <div>
            <div
              className={`${`actions-bar-${tableName}${
                isTablet ? '-tablet' : ''
              }`} row d-flex ${
                hideSearchBar
                  ? 'justify-content-end'
                  : 'justify-content-between'
              }  px-3 px-md-0`}
            >
              {hideSearchBar || (
                <div className="col-4 px-0">
                  <div className="input-group bg-lazuli-white">
                    <button
                      className="input-group-text search-button p-0 ps-1"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSearch(searchQuery);
                      }}
                    >
                      <i
                        className="icon-Search font-24"
                        style={{ color: '#B3B3B3' }}
                      />
                    </button>
                    <input
                      className="form-control table-searchbar"
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => {
                        e.preventDefault();
                        setSearchQuery(e.target.value);
                      }}
                      onKeyUp={(e) =>
                        e.key === 'Enter' && handleSearch(searchQuery)
                      }
                    />
                    {searchQuery && (
                      <button
                        className="input-group-text clear-search-button p-0 ps-1"
                        onClick={(e) => {
                          e.preventDefault();
                          setSearchQuery('');
                          handleSearch('');
                        }}
                      >
                        <i
                          className="icon-X-Close font-24"
                          style={{ color: '#B3B3B3' }}
                        />
                      </button>
                    )}
                  </div>
                </div>
              )}

              <div className="col-auto">
                <div className="d-flex">
                  {isSPMView && projectTypeFilter && setProjectTypeFilter && (
                    <select
                      className="table-select mx-2"
                      value={projectTypeFilter}
                      onChange={(e) =>
                        setProjectTypeFilter(
                          e.target.value as 'ALL' | 'SPM' | 'PM',
                        )
                      }
                    >
                      <option value="ALL">
                        Assigned and supervised projects
                      </option>
                      <option value="SPM">Assigned only</option>
                      <option value="PM">Supervised only</option>
                    </select>
                  )}
                  <select
                    className="table-select"
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                  >
                    <option value={10}>Show 10 {tableName}</option>
                    <option value={50}>Show 50 {tableName}</option>
                    <option value={100}>Show 100 {tableName}</option>
                    <option value={0}>Show all {tableName}</option>
                  </select>
                  {actionButton && actionButton}
                  {csvData && (
                    <div>
                      <CSVLink
                        data={csvData?.data}
                        headers={csvData?.headers}
                        filename={csvData?.filename}
                        id="export-csv-link"
                      />
                      <button
                        disabled={csvData?.data.length === 0}
                        className="btn btn-primary ms-3 bg-lazuli-outline"
                        onClick={(e) => {
                          e.preventDefault();
                          const csvLink =
                            document.getElementById('export-csv-link');
                          csvLink?.click();
                        }}
                      >
                        Export CSV
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`d-block ${`admin-header-divider-${tableName}${
            isTablet ? '-tablet' : ''
          }`} mt-3 mb-2`}
        />
        {data.length > 0 ? (
          <table className="table table-hover table-mobile-margin-hack">
            <thead>{renderTableHeaders()}</thead>
            <tbody style={{ borderTop: 'none' }}>
              <>
                {renderTableData()}
                {isMobileOnly && (
                  <tr className="lazuli-space-medium">
                    <span></span>
                  </tr>
                )}
              </>
            </tbody>
          </table>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center w-100 mt-5"
            style={{ color: '#1e538a' }}
          >
            <h5>{`No ${tableName} were found.`}</h5>
          </div>
        )}
        {itemsPerPage > 0 && Math.ceil(totalData / itemsPerPage) > 1 && (
          <Pagination
            totalPages={Math.ceil(totalData / itemsPerPage)}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
      </div>
    </>
  );
};

export default Table;
