import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { AddNewButton } from '../../components/Buttons/AddNewButton/AddNewButton';
import Loader from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import {
  useDeleteProjectMutation,
  useGetAllProjectsQuery,
  useGetProjectsCountQuery,
  useGetProjectsForCSVQuery,
} from '../../redux/api/project.endpoints';
import { Project } from '../../types/Project';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { PROJECTS_CSV_METADATA } from '../../utils/csv';
import {
  formatAsMoney,
  getDaysForDate,
  getFormattedDate,
} from '../../utils/table';
import { useSearchParams } from 'react-router-dom';
import { ENTITY_NAME, projectStatusValues } from '../../utils/enums';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import {
  useGetAuthenticatedUserProfileQuery,
  useGetUserByIdQuery,
} from '../../redux/api/user.endpoints';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { handleEndpointResult } from '../../utils/api';
import { isAdminOrSuperAdmin, isPMOrSPM, isSPM } from '../../auth/userUtils';
import { Helmet } from 'react-helmet';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { ContextMenu } from '../../components/ContextMenu/ContextMenu';

export const ProjectTable = () => {
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const timeRange = searchParams.get('timeRange');
  const projectManagerId = parseInt(
    searchParams.get('projectManagerId') || '0',
  );
  const Table = lazy(() => import('../../components/Table/Table'));
  const [activeTab, setActiveTab] = useState('open');
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(
    filter === 'pastDue' || filter === 'openOver28Days' ? 'id' : 'project.id',
  );
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('DESC');
  const [selectedProject, setSelectedProject] = useState<Project | undefined>(
    undefined,
  );
  const [modalAction, setModalAction] = useState('Delete');
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [projectTypeFilter, setProjectTypeFilter] = useState<
    'ALL' | 'SPM' | 'PM'
  >('ALL');
  const [deleteProject, deleteProjectResult] = useDeleteProjectMutation();

  const [userId, setUserId] = useState('');
  const { auth } = useContext(authContext);
  const { data: authProfile, refetch: refetchProfile } =
    useGetAuthenticatedUserProfileQuery(userId, {
      skip: !userId,
    });

  const { data: projectManagerUser } = useGetUserByIdQuery(
    projectManagerId + '',
  );

  const { projectsData, refetch, getAllProjectsQueryError, isFetching } =
    useGetAllProjectsQuery(
      {
        pageSize: itemsPerPage,
        pageNumber: activePage,
        sortBy,
        sortType,
        tab: activeTab,
        searchQuery,
        filter,
        timeRange,
        projectManagerId:
          authProfile && isPMOrSPM(authProfile)
            ? authProfile.id
            : projectManagerId > 0
            ? projectManagerId
            : undefined,
        projectTypeFilter,
      },
      {
        selectFromResult: ({ data, error, isFetching }) => ({
          projectsData: data ? data : [],
          getAllProjectsQueryError: error,
          isFetching,
        }),
        skip: !authProfile,
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: CSVData } = useGetProjectsForCSVQuery({
    projectManagerId:
      authProfile && isPMOrSPM(authProfile) ? authProfile.id : undefined,
    tab: activeTab,
  });

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetchProfile();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetchProfile();
    }
  }, [auth]);

  useEffect(() => {
    if (filter === 'completed' || filter === 'completedOnTime') {
      setActiveTab('completed');
    }
  }, []);

  const { data: projectsCount, refetch: refetchProjectsCount } =
    useGetProjectsCountQuery(
      {
        search: searchQuery,
        filter,
        timeRange: timeRange || '',
        projectManagerId:
          authProfile && isPMOrSPM(authProfile)
            ? authProfile.id
            : projectManagerId > 0
            ? projectManagerId
            : undefined,
        projectTypeFilter,
      },
      {
        skip: !authProfile,
      },
    );

  useEffect(() => {
    refetch();
    refetchProjectsCount();
  }, [sortBy, sortType, activePage]);

  useEffect(() => {
    setActivePage(1);
  }, [itemsPerPage, activeTab]);

  useEffect(() => {
    if (typeof getAllProjectsQueryError !== 'undefined') {
      toast.error('An error occurred when searching projects.', {
        toastId: 'get-all-projects-querry-error',
      });
    }
  }, [getAllProjectsQueryError]);

  const sortProjects = (category: string) => {
    setSortBy((prev) => {
      if (prev === category) {
        setSortType((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
        return category;
      }
      setSortType('ASC');
      return category;
    });
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };
  const tabs = [
    {
      title: `Open (${projectsCount ? projectsCount.open : 0})`,
      attribute: 'open',
    },
    {
      title: `Completed (${projectsCount ? projectsCount.completed : 0})`,
      attribute: 'completed',
    },
    {
      title: `Invoiced (${projectsCount ? projectsCount.invoiced : 0})`,
      attribute: 'invoiced',
    },
  ];

  const columns = [
    {
      title: 'Customer',
      attribute: 'customer',
      sortValue: 'customer.company_name',
      maxWidth: '3rem',
    },
    {
      title: 'Project Address',
      attribute: 'address',
      sortValue: 'project.address',
      maxWidth: '10rem',
    },
    {
      title: 'Start Date',
      attribute: 'startDate',
      sortValue: 'project.start_date',
    },
    {
      title: 'Due Date',
      attribute: 'dueDate',
      sortValue: 'project.due_date',
    },
    {
      title: 'Contract Amount',
      attribute: 'contractAmount',
      sortValue: 'project.contract_amount',
      maxWidth: '3rem',
      classes: 'text-center',
    },
    {
      title: 'Invoice Number',
      attribute: 'invoice_number',
      sortValue: 'project.invoice_number',
      classes: 'text-center',
      maxWidth: '10rem',
    },
    {
      title: 'Days Open',
      attribute: 'days_open',
      sortValue: 'not_sortable',
      classes: 'text-center',
      minWidth: '5rem',
    },
    {
      title: 'DO / DA',
      attribute: 'datesProgression',
      sortValue: 'days_under_contract',
      tooltip: 'Days Open / Days Allotted',
      minWidth: isMobile ? '8rem' : '8rem',
      textCenter: true,
      hasTooltip: true,
    },
    {
      title: 'Progress',
      attribute: 'progress',
      sortValue: 'not_sortable',
      minWidth: isMobile ? '8rem' : '4rem',
    },
    {
      title: 'Expenses',
      sortValue: 'not_sortable',
      attribute: 'expense_text',
      minWidth: isMobile ? '8rem' : '4rem',
    },
  ];

  const getTooltipDODA = (project: Project) => {
    const startDays = getDaysForDate(new Date(project.start_date));
    const endDays = getDaysForDate(new Date(project.due_date));

    return `${project.days_open || 0}/${endDays - startDays}`;
  };

  const transformDataForTable = () =>
    projectsData.map(
      (project: Project) =>
        ({
          id: project.id.toString(),
          expense_text: (
            <ProgressBar
              progress={Math.floor(project.percentage_expended || 0)}
            />
          ),
          customer: project.customer?.company_name || '',
          address: project.address || '',
          market: project.market?.name || '',
          projectManager: project.project_manager?.name || '',
          orderDate: getFormattedDate(project.order_date),
          startDate: getFormattedDate(project.start_date),
          dueDate: getFormattedDate(project.due_date),
          contractAmount: formatAsMoney(project.contract_amount),
          days_open: project.days_open,
          datesProgression: (
            <ProgressBar progress={project.days_under_contract} />
          ),
          progress: (
            <ProgressBar
              progress={
                project.progress ? projectStatusValues[project.progress] : 0
              }
            />
          ),
          invoice_number: project.invoice_number,
          dateProgressTooltip: getTooltipDODA(project),
        } as { [key: string]: string | number | JSX.Element }),
    );

  const getDataForCSV = () => {
    if (CSVData) {
      const dataForCSV = CSVData.map((project) => {
        return {
          ...project,
          customer: project.customer?.company_name || '',
          market: project.market?.name || '',
          project_manager: project.project_manager?.name || '',
          contract_amount: `$${project.contract_amount}`,
          progress: `${
            project.progress ? projectStatusValues[project.progress] : 0
          }%`,
          days_under_contract: `${project.days_under_contract}%`,
        };
      });
      return {
        data: dataForCSV,
        ...PROJECTS_CSV_METADATA,
        filename: `Projects (${activeTab}).csv`,
      };
    }
    return { data: [], headers: [], filename: 'null.csv' };
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (action: string, project: Project) => {
    setSelectedProject(project);
    setModalAction(action);
    setOpenModal(true);
  };

  const handleDeleteProject = () => {
    selectedProject &&
      deleteProject({
        id: selectedProject.id,
        creatorId: authProfile?.id || 0,
      });
    handleCloseModal();
  };

  useEffect(() => {
    handleEndpointResult(
      {
        toastErrorMsg: 'An error occurred while deleting the Project.',
        toastId: 'edit-project-success',
        toastMsg: 'Project deleted successfully!',
        toastErrorId: 'edit-project-error',
      },
      deleteProjectResult,
    );
  }, [deleteProjectResult]);

  const getHeaderText = () =>
    selectedProject ? `${modalAction} Project.` : '';

  const getSecondaryText = () =>
    selectedProject
      ? `Are you sure you want to ${modalAction.toLowerCase()} this Project?`
      : '';

  const renderContextMenu = (projectId: number) => {
    const contextMenuProps = {
      entityData: projectsData,
      entityName: ENTITY_NAME.PROJECT,
      currentId: projectId,
      authProfile,
      handleOpenModal,
    };
    return <ContextMenu {...contextMenuProps} />;
  };

  const getTotalCount = () => {
    switch (activeTab) {
      case 'open':
        return projectsCount?.open || 0;
      case 'completed':
        return projectsCount?.completed || 0;
      case 'invoiced':
        return projectsCount?.invoiced || 0;
      default:
        return 0;
    }
  };

  return (
    <>
      <Helmet>
        <title>Renolink - Projects</title>
      </Helmet>
      <Suspense fallback={<Loader />}>
        <Table
          tableName="projects"
          mainActionButton={
            isAdminOrSuperAdmin(authProfile) ? (
              <AddNewButton label={`${isMobileOnly ? '+' : '+ New Project'}`} />
            ) : (
              <></>
            )
          }
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          columns={columns}
          data={transformDataForTable()}
          totalData={getTotalCount()}
          isFetchingData={isFetching}
          sortFunction={sortProjects}
          sortBy={sortBy}
          sortType={sortType}
          activePage={activePage}
          setActivePage={setActivePage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          renderContextMenu={renderContextMenu}
          handleSearch={handleSearch}
          csvData={getDataForCSV()}
          isPMOrSPMRoutes={isPMOrSPM(authProfile)}
          isSPMView={projectManagerId ? isSPM(projectManagerUser) : false}
          projectTypeFilter={projectTypeFilter}
          setProjectTypeFilter={setProjectTypeFilter}
        />
      </Suspense>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={
          modalAction === 'Delete'
            ? handleDeleteProject
            : () => console.log('Other')
        }
      />
    </>
  );
};
