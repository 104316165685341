import {
  BaseGetActiveInactiveCountResponse,
  BaseGetForTablePayload,
} from '../../types/Common';
import {
  CreateUserDto,
  DashboardTablePM,
  EditUserDto,
  User,
  USER_ROLES,
} from '../../types/User';
import { renolinkApi } from './renolink.api';

const userEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<User[], BaseGetForTablePayload>({
      query: (payload) =>
        `/user?pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&tab=${payload.tab}&search=${payload.searchQuery}`,
      providesTags: ['User'],
    }),
    getUsersCount: build.query<
      BaseGetActiveInactiveCountResponse,
      { search: string }
    >({
      query: (payload) => `/user/count?search=${payload.search}`,
      providesTags: ['User'],
    }),
    getUserById: build.query<User, string>({
      query: (id) => `/user/byId/${id}`,
      providesTags: ['UserData'],
    }),
    getAuthenticatedUserProfile: build.query<User, string>({
      query: (id) => `/user/byCognitoId/${id}`,
    }),
    getUsersForDashboard: build.query<
      DashboardTablePM[],
      { sortBy: string; sortType: string; timeRange: string }
    >({
      query: (payload) =>
        `/user/dashboard-table?sortBy=${payload.sortBy}&sortType=${payload.sortType}&timeRange=${payload.timeRange}`,
      providesTags: ['UserDashboard'],
    }),
    updateUserActiveStatus: build.mutation<{ message: string }, number>({
      query: (id) => ({
        url: `/user/update-active-status/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: [
        'User',
        'UserCSV',
        'UserData',
        'UserProjectManagers',
        'ProjectDashboard',
      ],
    }),
    createUser: build.mutation<User, CreateUserDto>({
      query: (data) => ({
        url: `/user`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        'User',
        'UserCSV',
        'UserProjectManagers',
        'ProjectDashboard',
      ],
    }),
    deleteUser: build.mutation<boolean, number>({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'User',
        'UserCSV',
        'UserData',
        'UserProjectManagers',
        'ProjectDashboard',
      ],
    }),
    editUser: build.mutation<boolean, EditUserDto>({
      query: (data) => ({
        url: `/user`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        'User',
        'UserData',
        'UserCSV',
        'AuthenticatedUserProfile',
        'UserProjectManagers',
        'ProjectDashboard',
      ],
    }),
    getUsersForCSV: build.query<User[], void>({
      query: () => `/user?sortBy=id&sortType=DESC&tab=all&search=`,
      providesTags: ['UserCSV'],
    }),
    getProjectManagers: build.query<User[], void>({
      query: () =>
        `/user/byRole?roles=${USER_ROLES.PROJECT_MANAGER}&roles=${USER_ROLES.SENIOR_PROJECT_MANAGER}`,
      providesTags: ['UserProjectManagers'],
    }),
    getUsersByRole: build.query<User[], number>({
      query: (roleId) => `/user/byRole?roles=${roleId}`,
      providesTags: ['UserProjectManagers'],
    }),
    resetUserPassword: build.mutation<void, { email: string }>({
      query: (data) => ({
        url: `/user/admin-reset-password`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User', 'UserData'],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUsersCountQuery,
  useGetUserByIdQuery,
  useGetAuthenticatedUserProfileQuery,
  useGetUsersForCSVQuery,
  useGetProjectManagersQuery,
  useGetUsersByRoleQuery,
  useGetUsersForDashboardQuery,
  useUpdateUserActiveStatusMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useResetUserPasswordMutation,
} = userEndpoints;
