import {
  BaseGetActiveInactiveCountResponse,
  BaseGetForTablePayload,
} from '../../types/Common';
import {
  CreateMarketDto,
  EditMarketDto,
  Market,
  MarketForTable,
} from '../../types/Markets';
import { renolinkApi } from './renolink.api';

const marketEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    getAllMarketsForTable: build.query<
      MarketForTable[],
      BaseGetForTablePayload
    >({
      query: (payload) =>
        `/market?pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&tab=${payload.tab}&search=${payload.searchQuery}`,
      providesTags: ['Market'],
    }),
    getAllRawMarkets: build.query<Market[], undefined>({
      query: () => `/market/raw`,
      providesTags: ['Market'],
    }),
    getMarketsCount: build.query<
      BaseGetActiveInactiveCountResponse,
      { search: string }
    >({
      query: (payload) => `/market/count?search=${payload.search}`,
      providesTags: ['Market'],
    }),
    getMarketById: build.query<Market, string>({
      query: (id) => `/market/byId/${id}`,
      providesTags: ['MarketData'],
    }),
    getMarketsForCSV: build.query<MarketForTable[], void>({
      query: () => `/market?sortBy=market.id&sortType=DESC&tab=all&search=`,
      providesTags: ['MarketCSV'],
    }),
    createMarket: build.mutation<Market, CreateMarketDto>({
      query: (data) => ({
        url: `/market`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Market', 'MarketCSV'],
    }),
    editMarket: build.mutation<boolean, EditMarketDto>({
      query: (data) => ({
        url: `/market`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Market', 'MarketCSV', 'MarketData'],
    }),
    updateMarketActiveStatus: build.mutation<{ message: string }, number>({
      query: (id) => ({
        url: `/market/update-active-status/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Market', 'MarketCSV', 'MarketData'],
    }),
    deleteMarket: build.mutation<boolean, number>({
      query: (id) => ({
        url: `/market/soft-delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Market', 'MarketCSV', 'MarketData'],
    }),
  }),
});

export const {
  useGetAllMarketsForTableQuery,
  useGetAllRawMarketsQuery,
  useGetMarketsCountQuery,
  useGetMarketByIdQuery,
  useGetMarketsForCSVQuery,
  useCreateMarketMutation,
  useEditMarketMutation,
  useUpdateMarketActiveStatusMutation,
  useDeleteMarketMutation,
} = marketEndpoints;
