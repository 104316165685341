import React from 'react';
import WoodCuttingImg from '../../styles/img/wood-cutting.png';
import WoodCuttingImgMed from '../../styles/img/wood-cutting-med.png';
import WoodCuttingImgSmall from '../../styles/img/wood-cutting-sm.png';
import { isTablet, isMobileOnly } from 'react-device-detect';

const LoginImage = () => (
  <div
    className={`d-flex d-md-inline-block col-md-6 col-12 px-0 mb-3 mb-md-0 ${
      !isMobileOnly ? 'h-100' : ''
    }`}
    aria-label="banner-image"
  >
    <img
      src={
        isMobileOnly
          ? WoodCuttingImgSmall
          : isTablet
          ? WoodCuttingImgMed
          : WoodCuttingImg
      }
      alt="wood-cutting-banner"
      className="w-100 h-100"
    />
  </div>
);

export default LoginImage;
