import { toast } from 'react-toastify';

import { Auth } from 'aws-amplify';
import { IAuth } from '../types/CognitoAuth';
import { NavigateFunction } from 'react-router';
import { User, USER_ROLES } from '../types/User';

export const getCurrentUser = async () => {
  return await Auth.currentAuthenticatedUser();
};

export const checkSessionStillActive = async (
  auth: IAuth,
  setAuthData: (data: IAuth | null) => void,
  navigate: NavigateFunction,
) => {
  try {
    if (auth && auth.data) {
      await Auth.currentSession();
      return true;
    }
    return false;
  } catch (err) {
    toast.error('Your session has expired, please log in again.', {
      toastId: 'session-expired',
    });
    setAuthData(null);
    await Auth.signOut();
    navigate('/');
    return false;
  }
};

export const isAdminOrSuperAdmin = (user: User | undefined) =>
  user &&
  (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SUPER_ADMIN);

export const isSuperAdmin = (user: User | undefined) =>
  user && user.role === USER_ROLES.SUPER_ADMIN;

export const isPMOrSPM = (user: User | undefined) =>
  user &&
  (user.role === USER_ROLES.PROJECT_MANAGER ||
    user.role === USER_ROLES.SENIOR_PROJECT_MANAGER);

export const isSPM = (user: User | undefined) =>
  user && user.role === USER_ROLES.SENIOR_PROJECT_MANAGER;
