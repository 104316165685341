import { NavLink } from 'react-router-dom';
import { isMobileOnly, isTablet } from 'react-device-detect';

interface Props {
  title: string;
  icon: string;
  path: string;
  toggled: boolean;
}

const navlinkStyle = 'nav-link text-white d-flex align-items-center';

const SidebarItem = ({ title, icon, path, toggled }: Props) => {
  return (
    <li className="sidebar-nav-item nav-item my-1">
      <NavLink
        to={path}
        className={({ isActive }) =>
          `sidebar py-1 px-2 ${
            isActive ? `active ${navlinkStyle}` : navlinkStyle
          }`
        }
        onClick={() => {
          if (isMobileOnly || isTablet) {
            document
              .getElementById('renolink-sidebar')
              ?.classList.toggle('hidden-sidebar');
            document
              .getElementById('sidebar-overlay')
              ?.classList.toggle('d-none');
          }
        }}
      >
        <i className={`${icon} font-28 me-3`} />
        {!toggled && <span className="fade-in font-12">{title}</span>}
      </NavLink>
    </li>
  );
};

export default SidebarItem;
