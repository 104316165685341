import { USER_ROLES } from '../types/User';

export const ERROR_BANNER = {
  LOGIN: 'There was a problem, your email or password is incorrect.',
  RESET_PASSWORD: "There was a problem, your passwords don't match.",
  PASSWORD_RECOVERY: 'There was a problem with your email.',
};

export const RENOLINK_HEADER = {
  MAIN_TEXT: 'Project Management Portal',
  RESET_PASSWORD: 'Create new password',
  RESET_CONFIRMATION: 'Check your email',
  PASSWORD_RECOVERY: 'Forgot your password?',
};

export const DISPLAY_TEXT = {
  NOT_FOUND: 'Not Found.',
  NOT_FOUND_SECONDARY: 'The route you tried to enter is not currently defined.',
  CONFIRMATION_LINK_SENT:
    'We have sent password reset instructions to your email.',
  RECOVERY_EMAIL_MESSAGE:
    'Please enter your email linked to the account to reset password.',
};

export const SIDEBAR_LINKS = [
  {
    title: 'Dashboard',
    icon: 'icon-Dashboard',
    path: 'dashboard',
    allowedRoles: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.PROJECT_MANAGER,
      USER_ROLES.SENIOR_PROJECT_MANAGER,
    ],
  },
  {
    title: 'Projects',
    icon: 'icon-Projects',
    path: 'projects',
    allowedRoles: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.PROJECT_MANAGER,
      USER_ROLES.SENIOR_PROJECT_MANAGER,
    ],
  },
  {
    title: 'Markets',
    icon: 'icon-Market',
    path: 'markets',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
  },
  {
    title: 'Customers',
    icon: 'icon-Customers',
    path: 'customers',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
  },
  {
    title: 'Subcontractors',
    icon: 'icon-Contractors',
    path: 'subcontractors',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
  },
  {
    title: 'Users',
    icon: 'icon-Users',
    path: 'users',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
  },
];

export const ACTION_CONFIRMATION_MODAL = {
  CREATE_USER_HEADER: 'Cancel Create New User',
  CREATE_USER_SECONDARY: 'Are you sure you want to cancel creating a new user?',
  EDIT_USER_HEADER: 'Cancel Edit User',
  EDIT_USER_SECONDARY: 'Are you sure you want to cancel edit user?',
  CREATE_SUBCONTRACTOR_HEADER: 'Cancel Create New Subcontractor',
  CREATE_SUBCONTRACTOR_SECONDARY:
    'Are you sure you want to cancel creating a new subcontractor?',
  EDIT_SUBCONTRACTOR_HEADER: 'Cancel Edit Subcontractor',
  EDIT_SUBCONTRACTOR_SECONDARY:
    'Are you sure you want to cancel edit subcontractor?',
  CREATE_PROJECT_HEADER: 'Cancel Create New Project',
  CREATE_PROJECT_SECONDARY:
    'Are you sure you want to cancel creating a new project?',
  EDIT_PROJECT_HEADER: 'Cancel Edit Project',
  EDIT_PROJECT_SECONDARY: 'Are you sure you want to cancel edit project?',
  COMPLETE_PROJECT_HEADER: 'Confirm Project Complete',
  COMPLETE_PROJECT_SECONDARY:
    'Confirm all expenses have been submitted and customer has signed off on the completion of this project.',
  INVOICE_PROJECT_HEADER: 'Invoice Project',
  INVOICE_PROJECT_SECONDARY:
    'Are you sure you want to set this project as invoiced?',
  CREATE_MARKET_HEADER: 'Cancel Create New Market',
  CREATE_MARKET_SECONDARY:
    'Are you sure you want to cancel creating a new market?',
  EDIT_MARKET_HEADER: 'Cancel Edit Market',
  EDIT_MARKET_SECONDARY: 'Are you sure you want to cancel edit market?',
  CREATE_CUSTOMER_HEADER: 'Cancel Create Customer',
  CREATE_CUSTOMER_SECONDARY: 'Are you sure you want to cancel create customer?',
  EDIT_CUSTOMER_HEADER: 'Cancel Edit Customer',
  EDIT_CUSTOMER_SECONDARY: 'Are you sure you want to cancel edit customer?',
};

export const permissionsText: { [key: number]: string } = {
  1: 'Super Admin',
  2: 'Admin',
  4: 'Senior Project Manager',
  5: 'Project Manager',
  6: 'Customer',
};

export const permissionRoles: { [key: string]: number } = {
  'Super Admin': 1,
  Admin: 2,
  'Senior Project Manager': 4,
  'Project Manager': 5,
  Customer: 6,
};

export const ENTITY_NAME = {
  USER: 'User',
  SUBCONTRACTOR: 'Subcontractor',
  PROJECT: 'Project',
  MARKET: 'Market',
  CUSTOMER: 'Customer',
  NOTE: 'Note',
  EXPENSE: 'Expense',
};
export const projectStatuses = {
  NOT_STARTED: 'Not Yet Started',
  WORK_BEGUN: 'Work Has Begun',
  PROGRESS_25: '25% Complete',
  PROGRESS_50: '50% Complete',
  PROGRESS_75: '75% Complete',
  COMPLETE: 'Complete',
  INVOICED: 'Invoiced',
};

export const projectStatusValues: { [key: string]: number } = {
  'Not Yet Started': 0,
  'Work Has Begun': 0,
  '25% Complete': 25,
  '50% Complete': 50,
  '75% Complete': 75,
  Complete: 100,
  Invoiced: 100,
};

export const stateCodeValues = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
