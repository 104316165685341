import { Amplify } from 'aws-amplify';
import {
  cognitoClientId,
  cognitoPoolId,
  cognitoRegion,
  cognitoDomain,
  federatedIdentityPoolId,
} from '../utils/env';

Amplify.configure({
  identityPoolId: federatedIdentityPoolId,
  userPoolId: cognitoPoolId,
  userPoolWebClientId: cognitoClientId,
  region: cognitoRegion,
  oauth: {
    domain: cognitoDomain,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    responseType: 'token',
  },
});
