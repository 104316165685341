import { Dispatch, SetStateAction } from 'react';
import { isMobileOnly } from 'react-device-detect';

interface Props {
  tabs: string[];
  renderActionButtons?: () => JSX.Element;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export const MultiHeaderTab = ({
  tabs,
  renderActionButtons,
  activeTab,
  setActiveTab,
}: Props) => {
  return (
    <nav className="navbar navbar-expand bg-white-and-shadowed p-0 table-tabs">
      <div className="container-fluid ps-0">
        <div className="collapse navbar-collapse d-flex justify-content-between w-100">
          {isMobileOnly ? (
            <div className="form-group col-6 col-md-5 mx-2 my-3">
              <select
                className="form-select lazuli-select"
                value={activeTab}
                onChange={(e) => {
                  e.preventDefault();
                  setActiveTab(e.target.value);
                }}
              >
                {tabs.map((tab) => (
                  <option value={tab} key={tab}>
                    {tab}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <ul className="navbar-nav me-auto">
              {tabs.map((tab) => (
                <button
                  key={`${tab}`}
                  className={`col-auto p-0 px-1 p-md-2 nav-item cursor-pointer btn ${
                    activeTab === tab ? 'active-table-tab' : 'table-tab'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  <p className="nav-link table-tab-font font-weight-bold px-1">
                    {tab}
                  </p>
                </button>
              ))}
            </ul>
          )}
          {renderActionButtons && renderActionButtons()}
        </div>
      </div>
    </nav>
  );
};
