import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { CreateSubcontractorForm } from '../../components/CreateSubcontractorForm/CreateSubcontractorForm';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import { useCreateSubcontractorMutation } from '../../redux/api/subcontractor.endpoints';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { handleEndpointResult } from '../../utils/api';
import { useGetAllRawMarketsQuery } from '../../redux/api/market.endpoints';
import { createToastData } from '../../types/Subcontractors';

export const CreateSubcontractor = () => {
  const navigate = useNavigate();
  const [createSubcontractor, createSubcontractorResult] =
    useCreateSubcontractorMutation();

  const handleCreateSubcontractor = (createSubcontractorDto: FormData) => {
    createSubcontractor(createSubcontractorDto);
  };

  const { markets } = useGetAllRawMarketsQuery(undefined, {
    selectFromResult: ({ data }) => ({ markets: data ? data : [] }),
  });

  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const { data: authProfile, refetch } = useGetAuthenticatedUserProfileQuery(
    userId,
    { skip: !userId },
  );

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
      refetch();
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
      refetch();
    }
  }, [auth]);

  useEffect(() => {
    handleEndpointResult(createToastData, createSubcontractorResult, () => {
      navigate('/admin/subcontractors');
    });
  }, [createSubcontractorResult]);

  return authProfile ? (
    <>
      <Helmet>
        <title>Renolink - Create Subcontractor</title>
      </Helmet>
      <HeaderTab title="Create New Subcontractor" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            {
              <CreateSubcontractorForm
                handleCreateSubcontractor={handleCreateSubcontractor}
                isLoading={
                  createSubcontractorResult &&
                  createSubcontractorResult.isLoading
                }
                markets={markets}
                authProfile={authProfile}
              />
            }
          </main>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
