export interface Customer {
  id: number;
  company_name: string;
  primary_address: string;
  secondary_address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  is_active: boolean;
  is_soft_deleted: boolean;
  created_at: Date;
  updated_at: Date;
  openProjects?: number;
  openAmount?: number;
  pastDueProjects?: number;
  contacts: Contact[];
}

export interface getCustomerForTablePayload {
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortType: 'ASC' | 'DESC';
  tab: string;
  searchQuery: string;
}

export const editToastData = {
  toastMsg: 'Customer updated successfully!',
  toastErrorMsg: 'An error occurred while updating the customer.',
  toastId: 'edit-customer-success',
  toastErrorId: 'edit-customer-error',
};

export interface getCustomerCountResponse {
  total: number;
  active: number;
  inactive: number;
}

export const deleteToastData = {
  toastMsg: 'Customer deleted successfully.',
  toastErrorMsg: 'An error occurred while deleting the customer.',
  toastId: 'delete-customer-success',
  toastErrorId: 'delete-customer-error',
};

export const createToastCustomer = {
  toastMsg: 'Customer created successfully!',
  toastErrorMsg: 'An error occurred while creating the customer.',
  toastId: 'create-customer-success',
  toastErrorId: 'create-customer-error',
};

export const updateStatusToastData = {
  toastMsg: '',
  toastErrorMsg: "An error occurred while changing the customers's status.",
  toastId: 'update-status-customer-success',
  toastErrorId: 'status-customer-error',
};

export interface Contact {
  id: number;
  name: string;
  phone: string;
  email: string;
}

export interface CustomerRequest {
  companyName: string;
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  contacts: Contact[];
}

export interface EditCustomerDto {
  id: number;
  companyName: string;
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  contacts: Contact[];
}
