import React, { useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider';
import { DetailsTextFields } from '../../components/DetailsTextFields/DetailsTextFields';
import { ActionConfirmationModal } from '../../components/Modals/ActionConfirmationModal/ActionConfirmationModal';
import { UploadingModal } from '../../components/Modals/UploadingModal/UploadingModal';
import { MultiHeaderTab } from '../../components/MultiHeaderTab/MultiHeaderTab';
import {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetAllDocumentsBySubcontractorIdQuery,
} from '../../redux/api/document.endpoints';
import {
  useDeleteSubcontractorMutation,
  useGetSubcontractorByIdQuery,
  useUpdateSubcontractorActiveStatusMutation,
} from '../../redux/api/subcontractor.endpoints';
import { useGetAuthenticatedUserProfileQuery } from '../../redux/api/user.endpoints';
import {
  Document,
  uploadToastData,
  deleteToastData as deleteDocumentToastData,
} from '../../types/Documents';
import {
  deleteToastData,
  Trades,
  updateStatusToastData,
} from '../../types/Subcontractors';
import { USER_ROLES } from '../../types/User';
import { handleEndpointResult } from '../../utils/api';
import { checkIfHasSignInSub, checkIfHasSub } from '../../utils/cognito-utils';
import { getFormattedDate } from '../../utils/table';

export const SubcontractorDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { auth } = useContext(authContext);
  const [userId, setUserId] = useState('');
  const [modalAction, setModalAction] = useState('Activate');
  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [activeTab, setActiveTab] = useState('Details');
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const { subcontractorData, refetch: refetchSubcontractorData } =
    useGetSubcontractorByIdQuery(searchParams.get('id') || '', {
      selectFromResult: ({ data }) => ({
        subcontractorData: data ? data : undefined,
      }),
    });

  const { documentsData, refetch } = useGetAllDocumentsBySubcontractorIdQuery(
    searchParams.get('id') || '',
    {
      selectFromResult: ({ data }) => ({
        documentsData: data ? data : undefined,
      }),
    },
  );

  const [deleteSubcontractor, deleteSubcontractorResult] =
    useDeleteSubcontractorMutation();

  const [updateActiveStatus, updateActiveStatusResult] =
    useUpdateSubcontractorActiveStatusMutation();

  const [uploadDocument, uploadDocumentResult] = useCreateDocumentMutation();
  const [deleteDocuments, deleteDocumentResult] = useDeleteDocumentMutation();

  const { data: authProfile } = useGetAuthenticatedUserProfileQuery(userId, {
    skip: !userId,
  });

  const handleOpenModal = (action: string) => {
    setModalAction(action);
    setOpenModal(true);
  };

  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Object is possibly 'null'.
    const fileUploaded = event.target.files[0];

    handleOpenUploadModal();

    const documentFormData = new FormData();
    documentFormData.append('subcontractor_id', searchParams.get('id')!);
    documentFormData.append('owner_type', 'subcontractor');
    documentFormData.append('file_type', 'document');
    documentFormData.append('file', fileUploaded);

    uploadDocument(documentFormData);
  };

  const handleClick = () => {
    if (hiddenFileInput !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      hiddenFileInput.current.click();
    }
  };

  const handleDeleteDocument = () => {
    const documentIds = documents.reduce((filtered: number[], document) => {
      if (document.selected) {
        filtered.push(parseInt(document.id));
      }
      return filtered;
    }, []);
    deleteDocuments({ documentIds });
  };

  const handleUpdateActiveStatus = () =>
    subcontractorData && updateActiveStatus(subcontractorData.id);

  const handleDeleteSubcontractor = () =>
    subcontractorData && deleteSubcontractor(subcontractorData.id);

  const getHeaderText = () => {
    if (modalAction.includes('Document')) {
      return `Delete Document`;
    }
    return subcontractorData
      ? `${modalAction} Subcontractor: ${subcontractorData.id}.`
      : '';
  };

  const getSecondaryText = () => {
    if (modalAction.includes('Document')) {
      return `Are you sure you want to delete the selected document(s)?`;
    }
    return subcontractorData
      ? `Are you sure you want to ${modalAction.toLowerCase()} Subcontractor ${
          subcontractorData.id
        }?`
      : '';
  };

  const handleSelectAll = () => {
    setDocuments((prev) =>
      prev.map((doc) => ({ ...doc, selected: !isSelectAllChecked })),
    );
    setIsSelectAllChecked((prev) => !prev);
  };

  const handleDocumentSelection = (document: Document) => {
    setDocuments((prev) =>
      prev.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, selected: !doc.selected };
        }
        return { ...doc };
      }),
    );
    isSelectAllChecked && setIsSelectAllChecked(false);
  };

  useEffect(() => {
    if (documentsData) {
      setDocuments([...documentsData]);
    }
  }, [documentsData]);

  useEffect(() => {
    handleEndpointResult(deleteToastData, deleteSubcontractorResult, () => {
      setOpenModal(false);
      navigate(`/admin/subcontractors`);
    });
  }, [deleteSubcontractorResult]);

  useEffect(() => {
    handleEndpointResult(uploadToastData, uploadDocumentResult, () => {
      refetch();
      handleCloseUploadModal();
    });
  }, [uploadDocumentResult]);

  useEffect(() => {
    handleEndpointResult(deleteDocumentToastData, deleteDocumentResult, () => {
      setOpenModal(false);
    });
  }, [deleteDocumentResult]);

  useEffect(() => {
    updateStatusToastData.toastMsg = `Subcontractor ${updateActiveStatusResult.data?.message} successfully.`;
    handleEndpointResult(
      updateStatusToastData,
      updateActiveStatusResult,
      () => {
        setOpenModal(false);
        refetchSubcontractorData();
      },
    );
  }, [updateActiveStatusResult]);

  useEffect(() => {
    if (checkIfHasSub(auth)) {
      setUserId(auth.data!.attributes.sub!);
    } else if (checkIfHasSignInSub(auth)) {
      setUserId(auth.data!.signInUserSession.accessToken.payload.sub || '');
    }
  }, [auth]);

  const renderTrades = (subcontractorTrades: string[]) =>
    Trades.map((trade) => {
      const restrictWidth =
        trade === 'Handyman/General Maintenance' && isMobileOnly;
      return (
        <div key={trade} className="col-12 col-md-auto my-3 me-4">
          <input
            className="form-check-input lazuli-checkbox disabled"
            type="checkbox"
            id={`${trade}-check`}
            checked={!!subcontractorTrades.find((t) => t === trade)}
          />
          <label
            className="form-check-label vertical-align-sub"
            style={{ width: restrictWidth ? '75%' : '' }}
            htmlFor={`${trade}-check`}
          >
            {trade}
          </label>
        </div>
      );
    });

  const renderActionButtons = () => {
    if (!subcontractorData || !authProfile) return <></>;
    const canModify =
      authProfile.role === USER_ROLES.SUPER_ADMIN ||
      authProfile.role === USER_ROLES.ADMIN;
    return (
      <div
        className={`${
          !isMobileOnly ? 'd-flex row header-action-buttons' : 'row'
        }`}
      >
        {canModify && (
          <>
            <button
              className="btn btn-primary col-md-4 col-3 ms-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
              onClick={() =>
                subcontractorData &&
                handleOpenModal(
                  subcontractorData.is_active ? 'Deactivate' : 'Activate',
                )
              }
            >
              <i className="icon-Deactivate font-24" />

              {!isMobileOnly && (
                <span className="">
                  {subcontractorData && subcontractorData.is_active
                    ? 'Deactivate'
                    : 'Activate'}
                </span>
              )}
            </button>
            <button
              className="btn btn-primary col-md-4 col-3 ms-2 me-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
              onClick={() => handleOpenModal('Delete')}
            >
              <i className="icon-Delete font-24" />
              {!isMobileOnly && <span className="">Delete</span>}
            </button>

            <button
              className={`btn btn-primary col-md-3 col-3  bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex align-items-center justify-content-center  px-1`}
              onClick={() =>
                navigate(
                  `/admin/subcontractors/edit?id=${subcontractorData?.id || 0}`,
                )
              }
            >
              <i className="icon-Edit font-24" />
              {!isMobileOnly && <span className="">Edit</span>}
            </button>
          </>
        )}
      </div>
    );
  };

  const fields = [
    { label: 'First Name', value: subcontractorData?.first_name || '' },
    { label: 'Last Name', value: subcontractorData?.last_name || '' },
    { label: 'Phone', value: subcontractorData?.phone || '' },
    {
      label: 'Email',
      value:
        subcontractorData && subcontractorData.email
          ? subcontractorData.email
          : '-',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Renolink - Subcontractor Details</title>
      </Helmet>
      <MultiHeaderTab
        tabs={['Details', 'Documents']}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        renderActionButtons={
          activeTab === 'Details' ? renderActionButtons : undefined
        }
      />
      <div
        className={`my-3 rounded bg-white-and-shadowed d-flex pt-2 px-3 h-100 p-md-4 px-md-5 scrollable ${
          activeTab === 'Details' ? 'justify-content-center' : ''
        }`}
      >
        {activeTab === 'Details' && (
          <div className="px-2 mt-3 col-12 col-lg-10">
            <main>
              <div
                className={`font-semibold d-flex row text-center text-wrap-anywhere ${
                  isMobileOnly ? 'font-18' : 'font-24'
                }`}
              >
                <p>Subcontractor Information</p>
              </div>
              <div className="ms-3">
                <div className="d-flex row">
                  <div className="col-6 col-md-3 mb-1 mb-md-0">
                    <p className="font-semibold lazuli-grey">Company Name</p>
                    <p
                      aria-label="detail-text-value"
                      className="lazuli-grey-text text-truncate"
                    >
                      {subcontractorData ? `${subcontractorData.company}` : ''}
                    </p>
                  </div>
                  <div className="col-6 col-md-3 mb-1 mb-md-0">
                    <p className="font-semibold lazuli-grey">Primary Market</p>
                    <p
                      aria-label="detail-text-value"
                      className="lazuli-grey-text text-truncate"
                    >
                      {subcontractorData && subcontractorData.market
                        ? `${subcontractorData.market.name}`
                        : '-'}
                    </p>
                  </div>
                </div>

                <p className="font-semibold font-16 mt-4 mt-md-4 mb-4 text-center">
                  Primary Contact
                </p>
                <DetailsTextFields fields={fields} />
                <div className="d-flex row mt-3">
                  <p className="font-semibold font-16 mt-4 mt-md-4 mb-4 text-center">
                    Required Documents
                  </p>
                  <div className="col-12 col-md-6 mb-1 mb-md-0">
                    <input
                      className="form-check-input lazuli-checkbox disabled"
                      type="checkbox"
                      id="worker-comp-check"
                      checked={
                        subcontractorData && subcontractorData.wc_insurance
                      }
                    />
                    <label
                      className="form-check-label vertical-align-sub no-click lazuli-grey font-weight-bold"
                      htmlFor="worker-comp-check"
                      style={{ display: 'inline' }}
                    >
                      Worker&apos;s Comp Insurance
                    </label>

                    <p className="mt-3 ">
                      {' '}
                      Worker&apos;s Comp Insurance Exp. Date
                    </p>
                    <p>
                      {subcontractorData &&
                      subcontractorData.wc_insurance_expiration
                        ? getFormattedDate(
                            subcontractorData.wc_insurance_expiration,
                          )
                        : '-'}
                    </p>
                  </div>

                  <div className="col-12 col-md-6 mb-1 mb-md-0">
                    <input
                      className="form-check-input lazuli-checkbox disabled"
                      type="checkbox"
                      id="gen-liability-check"
                      checked={
                        subcontractorData && subcontractorData.gl_insurance
                      }
                    />
                    <label
                      className="form-check-label vertical-align-sub no-click lazuli-grey font-weight-bold"
                      htmlFor="gen-liability-check"
                    >
                      Gen Liability Insurance
                    </label>
                    <p className="mt-3"> Gen Liability Insurance Exp. Date</p>
                    <p>
                      {subcontractorData &&
                      subcontractorData.gl_insurance_expiration
                        ? getFormattedDate(
                            subcontractorData.gl_insurance_expiration,
                          )
                        : '-'}
                    </p>
                  </div>
                  <div className="col-12 col-md-6 mb-1 mb-md-0 mt-3">
                    <input
                      className="form-check-input lazuli-checkbox disabled"
                      type="checkbox"
                      id="signed-agreement-check"
                      checked={
                        subcontractorData && subcontractorData.signed_agreement
                      }
                    />
                    <label
                      className="form-check-label vertical-align-sub no-click lazuli-grey font-weight-bold"
                      htmlFor="signed-agreement-check"
                      style={{ display: 'inline' }}
                    >
                      Signed Subcontractor Agreement
                    </label>
                  </div>
                  <div className="col-12 col-md-6 mb-1 mb-md-0 mt-3">
                    <input
                      className="form-check-input lazuli-checkbox disabled"
                      type="checkbox"
                      id="w9-check"
                      checked={subcontractorData && subcontractorData.w9}
                    />
                    <label
                      className="form-check-label vertical-align-sub no-click lazuli-grey font-weight-bold"
                      htmlFor="w9-check"
                    >
                      W9
                    </label>
                  </div>
                  <p className="font-semibold font-16 mt-4 mt-md-4 mb-4 text-center">
                    Trades
                  </p>
                  {subcontractorData && renderTrades(subcontractorData.trades)}
                  <p className="font-semibold font-12 mt-4 mt-md-4 mb-1">
                    Notes
                  </p>
                  <p>
                    {subcontractorData && subcontractorData.notes
                      ? subcontractorData.notes
                      : '-'}
                  </p>
                </div>
                {isMobileOnly && <div style={{ height: '5rem' }}> </div>}
              </div>
            </main>
          </div>
        )}

        {activeTab === 'Documents' && (
          <div style={{ width: '-webkit-fill-available' }}>
            <div className="container-fluid ps-0">
              <div className="collapse navbar-collapse d-flex justify-content-between">
                <div className="col-6 col-md-auto">
                  <div className="form-group">
                    <input
                      className="form-check-input lazuli-checkbox"
                      type="checkbox"
                      id="worker-comp-check"
                      checked={isSelectAllChecked}
                      onChange={(e) => e}
                      onClick={handleSelectAll}
                    />
                    <label
                      className="form-check-label vertical-align-sub font-weight-bold fake-hyperlink"
                      htmlFor="worker-comp-check"
                    >
                      Select All
                    </label>
                  </div>
                </div>
                <div
                  className={`d-flex flex-row-reverse row flex-grow-slightly header-action-buttons`}
                >
                  <>
                    <button
                      disabled={!documents.some((doc) => doc.selected)}
                      className="btn btn-primary col-3 col-md-4 mx-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
                      onClick={() => handleOpenModal('Delete-Document')}
                    >
                      <i className="icon-Delete font-24" />
                      {isMobileOnly ? '' : ' Delete'}
                    </button>

                    <button
                      className="btn btn-primary col-3 col-md-4 mx-2 px-1 bg-lazuli-outline-light lazuli-grey-text table-tab-font d-flex flex-row align-items-center justify-content-center font-14"
                      onClick={handleClick}
                    >
                      <i className="icon-Upload font-24" />
                      {isMobileOnly ? '' : ' Upload'}
                    </button>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: 'none' }}
                    />
                  </>
                </div>
              </div>
            </div>
            <hr className="breakline" />
            <div className="row">
              {documents.map((document) => (
                <div key={document.subcontractor_id}>
                  <div className="row py-2">
                    <div
                      className={`col-lg-6 col-8 d-flex justify-content-start pe-0 ${
                        isMobileOnly ? 'center' : 'start'
                      } align-items-center`}
                    >
                      {' '}
                      <input
                        className="form-check-input lazuli-checkbox me-3"
                        type="checkbox"
                        id="worker-comp-check"
                        onChange={(e) => e}
                        checked={document.selected}
                        style={{ marginTop: 0, marginBottom: '5px' }}
                        onClick={() => handleDocumentSelection(document)}
                      />
                      <a
                        className="document-url text-truncate"
                        href={document.presignedUrl}
                        target="_blank"
                        rel="noreferrer"
                        style={{ maxWidth: '75%' }}
                      >
                        {document.name}
                      </a>
                    </div>
                    <div className="col-4 col-lg-6 d-flex justify-content-start align-items-center">
                      {getFormattedDate(document.created_at)}
                    </div>
                  </div>
                  <hr className="breakline-thin mt-3" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <ActionConfirmationModal
        isOpen={openModal}
        closeModal={handleCloseModal}
        headerText={getHeaderText()}
        secondaryText={getSecondaryText()}
        primaryAction={handleCloseModal}
        secondaryAction={() => {
          if (modalAction.includes('Document') && subcontractorData) {
            handleDeleteDocument();
          } else {
            modalAction === 'Delete'
              ? handleDeleteSubcontractor()
              : handleUpdateActiveStatus();
          }
        }}
      />
      <UploadingModal
        isOpen={openUploadModal}
        closeModal={handleCloseUploadModal}
        headerText={'Uploading Document'}
        bodyText={''}
      />
    </>
  );
};
