import React from 'react';

interface Props {
  title: string;
  renderActionButtons?: () => JSX.Element;
}

export const HeaderTab = ({ title, renderActionButtons }: Props) => {
  return (
    <nav className="navbar navbar-expand bg-white-and-shadowed p-0 table-tabs">
      <div className="container-fluid ps-0">
        <div className="collapse navbar-collapse d-flex justify-content-between">
          <ul className="navbar-nav me-auto">
            <span
              className={`nav-item btn active-table-tab unclickable vertical-align-center`}
            >
              <p className="table-tab-font font-weight-bold lazuli-dark-blue mb-0 pt-3 pt-md-2 px-1 px-md-3">
                {title}
              </p>
            </span>
          </ul>
          {renderActionButtons && renderActionButtons()}
        </div>
      </div>
    </nav>
  );
};
