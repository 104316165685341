import React from 'react';

import ArrowDefault from '../../../styles/svgs/sort-arrows-default.svg';
import ArrowDesc from '../../../styles/svgs/sort-arrows-desc.svg';
import ArrowAsc from '../../../styles/svgs/sort-arrows-asc.svg';
import ArrowInv from '../../../styles/svgs/sort-arrows-inv.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  title: string;
  handleClick: (category: string) => void;
  toggled: boolean;
  sortType: 'ASC' | 'DESC';
  sortProperty: string;
  tooltip?: string;
  centerHeader?: boolean;
}

export const SortDataButton = ({
  title,
  handleClick,
  toggled,
  sortType,
  sortProperty,
  tooltip,
  centerHeader,
}: Props) => {
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={(props) =>
          tooltip ? (
            <Tooltip
              id={title}
              className="table-tooltip"
              aria-multiline={false}
              {...props}
            >
              {tooltip}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <button
          className={`sort-button ${
            sortProperty === 'not_sortable' ? 'not-sortable' : ''
          } d-flex align-items-center px-0 ${
            centerHeader ? 'w-100 justify-content-center' : ''
          }`}
          onClick={() => {
            if (sortProperty !== 'not_sortable') {
              handleClick(sortProperty);
            }
          }}
        >
          <span
            className="font-weight-bolder mt-1 add-user-button-font"
            style={{ color: '#394A64' }}
          >
            {title}
          </span>
          {sortProperty !== 'not_sortable' && (
            <>
              {toggled ? (
                <>
                  {sortType === 'ASC' ? (
                    <img src={ArrowAsc} alt="asc-arrow" />
                  ) : (
                    <img src={ArrowDesc} alt="desc-arrow" />
                  )}
                </>
              ) : (
                <img
                  src={`${
                    sortProperty === 'not_sortable' ? ArrowInv : ArrowDefault
                  }`}
                  alt="default-arrow"
                />
              )}
            </>
          )}
        </button>
      </OverlayTrigger>
    </>
  );
};
