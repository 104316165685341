import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { toast } from 'react-toastify';

export const handleEndpointResult = (
  toastData: {
    toastMsg: string;
    toastErrorMsg: string;
    toastId: string;
    toastErrorId: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any,
  handleAction?: () => void,
) => {
  if (result && result.isSuccess) {
    toast.success(toastData.toastMsg, {
      toastId: toastData.toastId,
    });
    handleAction && handleAction();
  } else if (result && result.isError) {
    const errorResult = result.error as FetchBaseQueryError;
    const data = errorResult.data as unknown as { error: string };
    toast.error(data && data.error ? data.error : toastData.toastErrorMsg, {
      toastId: toastData.toastErrorId,
    });
  }
};
