import {
  Customer,
  CustomerRequest,
  EditCustomerDto,
  getCustomerCountResponse,
  getCustomerForTablePayload,
} from '../../types/Customer';
import { renolinkApi } from './renolink.api';

const customerEndpoints = renolinkApi.injectEndpoints({
  endpoints: (build) => ({
    getAllCustomers: build.query<Customer[], void>({
      query: () => ({
        url: `/customer`,
      }),
      providesTags: ['CustomersRaw'],
    }),
    getCustomersForTable: build.query<Customer[], getCustomerForTablePayload>({
      query: (payload) => ({
        url: `/customer/forTable?pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&tab=${payload.tab}&search=${payload.searchQuery}`,
      }),
      providesTags: ['Customer'],
    }),
    getCustomersCount: build.query<
      getCustomerCountResponse,
      { search: string }
    >({
      query: (payload) => `/customer/count?search=${payload.search}`,
      providesTags: ['Customer'],
    }),
    updateCustomerActiveStatus: build.mutation<{ message: string }, number>({
      query: (id) => ({
        url: `/customer/update-active-status/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['CustomersRaw', 'Customer'],
    }),
    deleteCustomer: build.mutation<boolean, number>({
      query: (id) => ({
        url: `/customer/soft-delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Customer', 'CustomersRaw', 'CustomerCSV'],
    }),
    createCustomer: build.mutation<Customer, CustomerRequest>({
      query: (data) => ({
        url: `/customer`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Customer', 'CustomersRaw', 'CustomerCSV'],
    }),
    getCustomerById: build.query<Customer, string>({
      query: (id) => `/customer/byId/${id}`,
      providesTags: ['CustomerData'],
    }),
    editCustomer: build.mutation<boolean, EditCustomerDto>({
      query: (data) => ({
        url: `/customer`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Customer', 'CustomerData', 'CustomerCSV'],
    }),
    getCustomersForCSV: build.query<Customer[], void>({
      query: () =>
        `/customer/forTable?sortBy=customer.company_name&sortType=ASC&tab=all&search=`,
      providesTags: ['CustomerCSV'],
    }),
  }),
});

export const {
  useGetAllCustomersQuery,
  useGetCustomersForTableQuery,
  useGetCustomersCountQuery,
  useGetCustomerByIdQuery,
  useUpdateCustomerActiveStatusMutation,
  useDeleteCustomerMutation,
  useCreateCustomerMutation,
  useEditCustomerMutation,
  useGetCustomersForCSVQuery,
} = customerEndpoints;
