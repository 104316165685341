import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HeaderTab } from '../../components/HeaderTab/HeaderTab';
import EditMarketForm from '../../components/MarketForms/EditMarketForm';
import {
  useEditMarketMutation,
  useGetMarketByIdQuery,
} from '../../redux/api/market.endpoints';
import { EditMarketDto } from '../../types/Markets';

const EditMarket = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [duplicateNameError, setDuplicateNameError] = useState(false);

  const [editMarket, editResult] = useEditMarketMutation();

  const { marketData } = useGetMarketByIdQuery(searchParams.get('id') || '', {
    selectFromResult: ({ data }) => ({ marketData: data ? data : undefined }),
  });

  useEffect(() => {
    if (editResult && editResult.isSuccess) {
      setDuplicateNameError(false);
      toast.success('Market edited successfully!', {
        toastId: 'edit-market-success',
      });
      navigate('/admin/markets');
    } else if (editResult && editResult.isError) {
      const errorResult = editResult.error as FetchBaseQueryError;
      const data = errorResult.data as unknown as { error: string };
      toast.error(
        data && data.error
          ? data.error
          : 'An error occurred while editing the market.',
        { toastId: 'edit-market-error' },
      );
      if (data && data.error && data.error.includes('Market Already Exists')) {
        setDuplicateNameError(true);
      }
    }
  }, [editResult]);

  const handleEditMarket = (editMarketDto: EditMarketDto) => {
    editMarket(editMarketDto);
  };

  return (
    <>
      <Helmet>
        <title>Renolink - Edit Market</title>
      </Helmet>
      <HeaderTab title="Edit Market" />
      <div className="mt-4 rounded bg-white-and-shadowed py-2 px-md-5 flex-grow-1 d-flex justify-content-center">
        <div className="container px-2 mt-3 no-margin w-100">
          <main>
            {
              <EditMarketForm
                marketData={marketData}
                handleEditMarket={handleEditMarket}
                isLoading={editResult && editResult.isLoading}
                duplicateNameError={duplicateNameError}
                setDuplicateNameError={setDuplicateNameError}
              />
            }
          </main>
        </div>
      </div>
    </>
  );
};

export default EditMarket;
