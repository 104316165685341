import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { projectStatuses } from '../../utils/enums';

interface Props {
  fields: {
    label: string;
    value: string;
    secondaryTexts?: string[];
    fullWidth?: boolean;
  }[];
  isPMOrSPM?: boolean;
  progress?: string;
  handleOpenModal?: (progress: string) => void;
  fieldsPerRow?: number;
}

export const DetailsTextFields = ({
  fields,
  isPMOrSPM,
  progress,
  handleOpenModal,
  fieldsPerRow,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const displayCMB = (field: any) =>
    isPMOrSPM && field.label === 'Project Status';
  const getProgressSelectValues = () => {
    let key: keyof typeof projectStatuses;
    const progressValues = [];
    for (key in projectStatuses) {
      progressValues.push(projectStatuses[key]);
    }
    return progressValues;
  };

  const columnsPerField = `col-md-${fieldsPerRow ? 12 / fieldsPerRow : 3}`;

  return (
    <div className="d-flex row">
      {fields.map((field) => (
        <div
          key={field.label}
          className={`${
            field.fullWidth ? 'col-auto' : 'col-6'
          } ${columnsPerField} mb-1 ${
            field.secondaryTexts ? 'mb-md-2' : 'mb-md-4'
          }`}
        >
          {field.label !== 'none' && !displayCMB(field) && (
            <>
              <p className="font-semibold lazuli-grey mb-2">{field.label}</p>
              <p
                aria-label="detail-text-value"
                className={`lazuli-grey-text text-truncate ${
                  field.secondaryTexts ? 'mb-0' : ''
                }`}
              >
                {field.value}
              </p>
              {field.secondaryTexts && (
                <>
                  {field.secondaryTexts.map((text, index) => (
                    <p
                      key={`${field.label}-${index}`}
                      className="font-9 mb-1 lazuli-light-grey-text text-truncate"
                    >
                      {text}
                    </p>
                  ))}
                  {isMobileOnly && <div className="mb-2" />}
                </>
              )}
            </>
          )}
          {field.label !== 'none' && displayCMB(field) && (
            <>
              <p className="font-semibold lazuli-grey mb-2">{field.label}</p>
              <select
                className="form-select lazuli-select"
                value={progress}
                disabled={isPMOrSPM && field.value === projectStatuses.INVOICED}
                onChange={(e) => {
                  const progressValue = e.target.value;
                  handleOpenModal && handleOpenModal(progressValue);
                }}
              >
                <option value={''} disabled={true} hidden={true}>
                  Select Project Progress:
                </option>
                {getProgressSelectValues().map((progress) => (
                  <option
                    disabled={
                      isPMOrSPM
                        ? progress === projectStatuses.INVOICED
                        : field.value !== projectStatuses.COMPLETE &&
                          progress === projectStatuses.INVOICED
                    }
                    value={progress}
                    key={progress}
                  >
                    {progress}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
